import { Stack } from "@fluentui/react";
import React from "react";
import Typography from "../Text/Typography";
import { useTheme } from "@mui/styles";

import { Dismiss20Filled } from "@fluentui/react-icons";

const Layout = ({ title, classes, reset }) => {
  const theme = useTheme();
  return (
    <Stack className={classes.parentTitle}>
      <Typography variant="heading" style={{ color: "black", fontWeight: 600 }}>
        {title}
      </Typography>
      <Dismiss20Filled onClick={reset} />
    </Stack>
  );
};

export default Layout;
