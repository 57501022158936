import React, { useEffect, useMemo, useState } from "react";

const today = new Date(); // Get the current date
const twoMonthsAgo = new Date(today);
twoMonthsAgo.setMonth(today.getMonth() - 2);
const dashboard = {
  requestType: "revenue",
  dateType: "day",
  keyword: "",
  totalRevenue: "",
  startDate: Math.floor(twoMonthsAgo.getTime() / 1000), // Unix timestamp for today - 2 months
  endDate: Math.floor(today.getTime() / 1000), // Unix timestamp for today
  // ---------------------------------
  requestTypeBooking: "count",
  dateTypeBooking: "day",
  keywordBooking: "",
  totalBooking: "",
  startDateBooking: Math.floor(twoMonthsAgo.getTime() / 1000), // Unix timestamp for today - 2 months
  endDateBooking: Math.floor(today.getTime() / 1000), // Unix timestamp for today
};
const pieChart = {
  startDate: Math.floor(twoMonthsAgo.getTime() / 1000), // Unix timestamp for today - 2 months
  endDate: Math.floor(today.getTime() / 1000), // Unix timestamp for today
  keyword: "",
};

const useAevForm = ({ openForm, services }) => {
  const [dashboardFilter, setDashboardFilter] = useState(dashboard);
  const [pieChartFilter, setPieChartFilter] = useState(pieChart);
  const [totalBooking, setTotalBooking] = useState("");

  return {
    dashboardFilter,
    setDashboardFilter,
    pieChartFilter,
    setPieChartFilter,
    setTotalBooking,
    totalBooking,
  };
};

export default useAevForm;
