import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";

const DialogComponent = ({
  children,
  isOpen,
  setIsopen,
  parent,
  width,
  actionButtons: ActionButtons,
  style,
}) => {
  const dialogRef = React.useRef(null);

  const handleDialogKeyDown = (e) => {
    // Prevent focus from leaving the dialog using Tab key
    if (e.key === "Tab") {
      e.preventDefault();
    }
  };

  React.useEffect(() => {
    // Set focus to the dialog when it opens
    if (isOpen) {
      dialogRef.current.focus();
    }
  }, [isOpen]);
  return (
    <>
      <Dialog
        trapFocus
        initialFocusRef={dialogRef}
        open={isOpen}
        onOpenChange={setIsopen}
      >
        <DialogSurface
          ref={dialogRef}
          style={{
            padding: 0,
            overflow: "hidden",
            width: width && width,
            ...style,
          }}
        >
          <DialogTitle>{parent}</DialogTitle>
          <DialogBody style={{ padding: 0 }}>
            <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
            <DialogActions>{ActionButtons && ActionButtons}</DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default DialogComponent;
