import React from "react";
import { People24Regular } from "@fluentui/react-icons";
import { PeopleTeamToolbox24Regular } from "@fluentui/react-icons";
import { Settings24Regular } from "@fluentui/react-icons";
import { PeopleCheckmark24Regular } from "@fluentui/react-icons";
import couponNormal from "../../../../assets/images/couponNormal.png";
import couponFilled from "../../../../assets/images/couponFilled.png";

import {
  Board24Regular,
  Board24Filled,
  People24Filled,
  BoxToolbox24Filled,
  PeopleTeamToolbox24Filled,
  DocumentToolbox24Filled,
  DocumentToolbox24Regular,
  TicketHorizontal24Regular,
  TicketHorizontal24Filled,
} from "@fluentui/react-icons";

import {
  BoxToolbox24Regular,
  PeopleCheckmark24Filled,
  Settings24Filled,
} from "@fluentui/react-icons";

class SideBarMenu {
  static menuForAdmin = [
    {
      display: "sideNavMenu.dashboard",
      link: "/admin/dashboard",
      linkPage: false,
      onclick: null,
      urlEndWith: "dashboard",
      className: "",
      permissionLevel: "Dashboard",
      filledIcon: <Board24Filled style={{}} />,
      regularIcon: <Board24Regular />,
      eventKey: "dashboard",
      subMenu: [],
    },
    {
      display: "sideNavMenu.user",
      link: "/admin/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "Users",
      filledIcon: <People24Filled style={{}} />,
      regularIcon: <People24Regular />,
      eventKey: "users",
      subMenu: [],
    },

    {
      display: "sideNavMenu.booking",
      link: "/admin/bookings",
      linkPage: false,
      onclick: null,
      urlEndWith: "bookings",
      className: "",
      permissionLevel: "Bookings",
      regularIcon: <PeopleTeamToolbox24Regular />,
      filledIcon: <PeopleTeamToolbox24Filled style={{}} />,
      eventKey: "Bookings",
      subMenu: [],
    },

    {
      display: "sideNavMenu.tarif",
      link: "/admin/tariff",
      linkPage: false,
      onclick: null,
      urlEndWith: "",
      className: "",
      permissionLevel: "Tariff",
      regularIcon: <DocumentToolbox24Regular />,
      filledIcon: <DocumentToolbox24Filled style={{}} />,
      eventKey: "",
      subMenu: [],
    },
    {
      display: "sideNavMenu.plan",
      link: "/admin/plan",
      linkPage: false,
      onclick: null,
      urlEndWith: "",
      className: "",
      permissionLevel: "Plan",
      regularIcon: <BoxToolbox24Regular />,
      filledIcon: <BoxToolbox24Filled style={{}} />,
      eventKey: "",
      subMenu: [],
    },

    {
      display: "sideNavMenu.Coupons",
      link: "/admin/coupons",
      linkPage: false,
      onclick: null,
      urlEndWith: "",
      className: "",
      permissionLevel: "Coupons",
      regularIcon: <TicketHorizontal24Regular />,
      filledIcon: <TicketHorizontal24Filled />,
      eventKey: "",
      subMenu: [],
    },
    {
      display: "sideNavMenu.Role",
      link: "/admin/security/role",
      linkPage: false,
      onclick: null,
      urlEndWith: "role",
      className: "",
      permissionLevel: "Role",
      regularIcon: <PeopleCheckmark24Regular />,
      filledIcon: <PeopleCheckmark24Filled style={{}} />,
      eventKey: "role",
    },
  ];
}
export default SideBarMenu;
