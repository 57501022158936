import { makeStyles } from "@fluentui/react-components";

export const useStyles = makeStyles({
  root: {
    flexDirection: "row",
    columnGap: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconWrap: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#E7FFFE",
    },
  },
  iconWraper: {
    flexDirection: "row",
    alignItems: "center",
    // columnGap: "10px",
  },
  actionButtons: {
    borderRadius: "12px",
    padding: "16px",
    backgroundColor: "#FAFAFA",
    overflow: "hidden",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "150px",
    marginRight: "24px",
    cursor: "pointer",
  },
});
