import React from "react";
import Typography from "../../../../../../components/Text/Typography";
import { DataContext } from "../AEVForm";
import { Stack } from "@mui/material";
import { Persona, PersonaInitialsColor, PersonaSize } from "@fluentui/react";

const DetailsPage = () => {
  const { userForm } = React.useContext(DataContext);

  function formatDate(epoch) {
    const date = new Date(epoch * 1000);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(",", "");
  }

  return (
    <Stack>
      <Stack
        style={{
          borderRadius: "15px",
          marginTop: "40px",
          backgroundColor: "#fff",
          marginLeft: "14px",
        }}
      >
        <Stack>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignContent={"center"}
            style={{
              backgroundColor: "#F1F1F1",
              padding: "16px",
              borderRadius: "15px 15px 0px 0px",
              alignContent: "center",
            }}
          >
            <div>
              <Typography variant={"heading"}>
                Booking ID :{" "}
                <span style={{ fontWeight: "400" }}>{userForm?.bookingId}</span>
              </Typography>
            </div>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <div
                style={{
                  width: "14px",
                  height: "14px",
                  borderRadius: "50%",
                  backgroundColor:
                    userForm?.status === "confirmed"
                      ? "#FF8D00"
                      : userForm?.status === "completed"
                      ? "#16A135"
                      : userForm?.status === "cancelled"
                      ? "#FF0000"
                      : userForm?.status === "enquiry"
                      ? "#868686"
                      : userForm?.status === "started"
                      ? "#8C14EA"
                      : userForm?.status === "outForPickup"
                      ? "#FF8D00"
                      : userForm?.status === "pending"
                      ? "orange"
                      : "",
                  marginTop: "4px",
                  textTransform: "capitalize",
                }}
              ></div>
              <Typography
                variant={"heading"}
                style={{
                  color:
                    userForm?.status === "confirmed"
                      ? "#FF8D00"
                      : userForm?.status === "completed"
                      ? "#16A135"
                      : userForm?.status === "cancelled"
                      ? "#FF0000"
                      : userForm?.status === "enquiry"
                      ? "#868686"
                      : userForm?.status === "started"
                      ? "#8C14EA"
                      : userForm?.status === "outForPickup"
                      ? "#FF8D00"
                      : userForm?.status === "pending"
                      ? "orange"
                      : "",
                  textTransform: "capitalize",
                  marginLeft: "6px",
                }}
              >
                {userForm?.status === "pending" ? "Ongoing" : userForm?.status}
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={"row"}>
            <Stack
              flexDirection={"row"}
              style={{
                width: "50%",
                borderRight: "1px solid #EEEFF2",
                padding: "16px",
              }}
            >
              <div
                style={{
                  width: "124px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  height: "100px",
                }}
              >
                {userForm?.userId?.profileImage.length === 0 ? (
                  <Persona
                    size={PersonaSize.size100}
                    initialsColor={PersonaInitialsColor.coolGray}
                  />
                ) : (
                  <img
                    src={userForm?.userId?.profileImage}
                    width="100px"
                    height="100px"
                    alt=""
                  />
                )}
              </div>
              <Stack
                justifyContent={"space-between"}
                flexDirection={"row"}
                style={{ marginLeft: "16px", width: "100%" }}
              >
                <Stack justifyContent={"center"}>
                  <Typography variant={"heading"} style={{ marginTop: "8px" }}>
                    {userForm?.userId?.fname} {userForm?.userId?.lname}
                  </Typography>
                  <Typography variant={"heading"} style={{ marginTop: "8px" }}>
                    {userForm?.userId?.email}
                  </Typography>
                  <Typography variant={"heading"} style={{ marginTop: "8px" }}>
                    {userForm?.userId?.mobileNo}
                  </Typography>
                </Stack>
                <Stack justifyContent={"center"}>
                  <Typography
                    variant={"heading"}
                    style={{ color: "#0362E3", fontWeight: 600 }}
                  >
                    CUSTOMER
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {/* ---------------------------------------- */}
            <Stack
              flexDirection={"row"}
              style={{ padding: "16px", width: "50%" }}
            >
              <div
                style={{
                  width: "124px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  height: "100px",
                }}
              >
                {userForm?.driverId?.profileImage.length === undefined ? (
                  <Persona
                    size={PersonaSize.size100}
                    initialsColor={PersonaInitialsColor.coolGray}
                  />
                ) : (
                  <img
                    src={userForm?.driverId?.profileImage}
                    width="100px"
                    height="100px"
                    alt=""
                  />
                )}
              </div>
              <Stack
                justifyContent={"space-between"}
                flexDirection={"row"}
                style={{ marginLeft: "16px", width: "100%" }}
              >
                <Stack justifyContent={"center"}>
                  <Typography variant={"heading"} style={{ marginTop: "8px" }}>
                    {userForm?.driverId?.fname} {userForm?.driverId?.lname}
                  </Typography>
                  <Typography variant={"heading"} style={{ marginTop: "8px" }}>
                    {userForm?.driverId?.email}
                  </Typography>
                  <Typography variant={"heading"} style={{ marginTop: "8px" }}>
                    {userForm?.driverId?.mobileNo}
                  </Typography>
                </Stack>
                <Stack justifyContent={"center"}>
                  <Typography
                    variant={"heading"}
                    style={{ color: "#0362E3", fontWeight: 600 }}
                  >
                    DRIVER
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* 2nd section */}
        <Stack>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            style={{ backgroundColor: "#F1F1F1", padding: "16px" }}
          >
            <div>
              <Typography variant={"heading"}>Booking Details</Typography>
            </div>
          </Stack>
          {/* --------------------------------------------- */}
          <Stack style={{ padding: "16px" }} flexDirection={"row"}>
            <Stack style={{ width: "35%" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Pickup Location
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.pickupLocation?.fragmentedAddress}
              </Typography>
            </Stack>
            <Stack style={{ width: "35%", marginLeft: "30px" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Drop off Location
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.destinationLocation?.fragmentedAddress}
              </Typography>
            </Stack>
            <Stack style={{ width: "30%", marginLeft: "30px" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Category
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.typeOfBooking}
              </Typography>
            </Stack>
            <Stack style={{ width: "30%", marginLeft: "30px" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Trip Type
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.tripType}
              </Typography>
            </Stack>
          </Stack>

          <Stack style={{ padding: "16px" }} flexDirection={"row"}>
            <Stack style={{ width: "36%" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Car Type
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.vehicleType} | {userForm?.vehicleTransmissionType}
              </Typography>
            </Stack>

            <Stack style={{ width: "37%", marginLeft: "30px" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Pickup Date & Time
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.pickupDateTime === 0
                  ? "Immediate"
                  : formatDate(userForm?.pickupDateTime)}
              </Typography>
            </Stack>
            <Stack style={{ width: "30%", marginLeft: "22px" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Duration
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.tariffDetails?.timeInHours} Hours
              </Typography>
            </Stack>
            <Stack style={{ width: "30%", marginLeft: "30px" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Amount
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                ₹{userForm?.totalAmount ? userForm.totalAmount.toFixed(2) : 0.0}
              </Typography>
            </Stack>
          </Stack>

          {/* below we are adding start otp and end trip otp fields */}

          <Stack style={{ padding: "16px" }} flexDirection={"row"}>
            <Stack style={{ width: "28%" }}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                Start Trip OTP
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.tripDetails?.[0]?.startOtp || "N/A"}
              </Typography>
            </Stack>

            <Stack style={{ width: "36%"}}>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#868686", marginBottom: "8px" }}
              >
                End Trip OTP
              </Typography>
              <Typography
                variant={"SubHeading"}
                style={{ color: "#323130", fontWeight: 600 }}
              >
                {userForm?.tripDetails?.[0]?.endOtp || "N/A"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DetailsPage;
