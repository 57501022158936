import * as React from "react";
import { Breadcrumb, IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { useHistory } from "react-router-dom";

const Breadcrumbs: React.FunctionComponent = ({ style }) => {
  const history = useHistory();
  const { location } = useHistory();
  const pathNames = location.pathname.split("/").filter((value) => value);

  function onBreadcrumbItemClicked(item: string): void {
    // route the clicked path
    history.push(`/${item}`);
  }

  const items = pathNames.map((value, ind) => {
    const pathUrl: string = pathNames.slice(0, ind + 1).join("/");
    return {
      text: value,
      key: value,
      style: {
        textTransform: "capitalize",
        fontWeight: "400",
        fontSize: "14px",
      },
      onClick: () => onBreadcrumbItemClicked(pathUrl),
    };
  });

  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        // left: "-8px",
        ...style,
      }}
    >
      <Breadcrumb
        items={items}
        // Returning undefined to OnReduceData tells the breadcrumb not to shrink
        onReduceData={_returnUndefined}
        maxDisplayedItems={3}
        ariaLabel="Breadcrumb with static width"
        overflowAriaLabel="More items"
      />
    </div>
  );
};

const _returnUndefined = () => undefined;

export default Breadcrumbs;
