import React, { useEffect, useState } from "react";
import {
  Button,
  Combobox,
  Field,
  Input,
  Option,
  Textarea,
} from "@fluentui/react-components";
import { Grid, Stack } from "@mui/material";
import { DataContext } from "../AEVForm";
import Typography from "../../../../../../components/Text/Typography";
import DialogV8 from "../../../../../../components/dialog/DialogV8";
import Layout from "../../../../../../components/dialog/Layout";
import AddPlan from "./AddPlan";

const Subscription = () => {
  const {
    classes,
    theme,
    subscriptionHistory,
    allRechargablePlan,
    services,
    openForm,
    setSubscriptionHistory
  } = React.useContext(DataContext);
  const [dialog, setIsDialog] = useState(false);
  const resetSheet = () => {
    setIsDialog(false);
  };
  function formatUnixTimestampToDate(unixTimestamp) {
    // Convert the Unix timestamp to a Date object
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds

    // Format the date as "01 Sep 2023"
    const formattedDate = date?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });

    return formattedDate;
  }

  return (
    <Stack>
      <Stack>
        <Stack
          flexDirection={"row"}
          // justifyContent={"space-between"}
          style={{
            backgroundColor: "#F1F1F1",
            padding: "16px",
            borderRadius: "15px 15px 0px 0px",
          }}
        >
          <Stack style={{ width: "50%" }}>
            <Typography
              variant={"heading"}
              style={{ marginLeft: "10px", fontWeight: 700 }}
            >
              Current Plan
            </Typography>
          </Stack>
          <Stack style={{ width: "50%" }}>
            <Typography
              variant={"heading"}
              style={{ marginLeft: "10px", fontWeight: 700 }}
            >
              Plans History
            </Typography>
          </Stack>
        </Stack>
        <Stack flexDirection={"row"}>
          <Stack
            flexDirection={"column"}
            style={{
              width: "50%",
              borderRight: "1px solid #EEEFF2",
              padding: "16px",
            }}
          >
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              style={{
                background: "#268581",
                boxShadow: "0px 7px 17px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: "10px",
                width: "100%",
                padding: "15px",
              }}
            >
              <Stack>
                <Typography
                  variant={"heading"}
                  style={{ fontWeight: 700, color: "#fff" }}
                >
                  {/* Daily Plan */}
                  Plan
                </Typography>
                <Typography
                  style={{ fontWeight: 700, color: "#fff", marginTop: "8px" }}
                >
                  <span style={{ fontWeight: 400, color: "#E5E5E5" }}>
                    Expired on :
                  </span>{" "}
                  {formatUnixTimestampToDate(
                    subscriptionHistory[0]?.subscriptionExpiryDate
                  )=== "Invalid Date" ? "N/A" : formatUnixTimestampToDate(
                    subscriptionHistory[0]?.subscriptionExpiryDate
                  )}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant={"heading"}
                  style={{ fontWeight: 700, color: "#fff" }}
                >
                  ₹{subscriptionHistory[0]?.planAmount}
                   {/* /{" "}
                  {subscriptionHistory[0]?.planDuration} day */}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              style={{
                // background: "linear-gradient(95deg, #0362E3 0%, #01BBF9 100%)",
                // boxShadow: "0px 7px 17px 0px rgba(0, 0, 0, 0.10)",
                // borderRadius: "10px",
                width: "100%",
                paddingTop: "15px",
              }}
            >
              <Stack>
                <Typography
                  variant={"heading"}
                  style={{ fontWeight: 700, color: "#000" }}
                >
                  Renew plan
                </Typography>
                <Typography
                  variant={"content"}
                  style={{
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.60)",
                    marginTop: "8px",
                  }}
                >
                  Click Renew button to add plan
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant={"heading"}
                  style={{
                    fontWeight: 700,
                    color: "#fff",
                    backgroundColor: "#268581",
                    padding: "8px 29px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsDialog(true)}
                >
                  Renew
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {/* ---------------------------------------- */}
          <Stack
            flexDirection={"column"}
            style={{ padding: "16px", width: "50%" }}
          >
            {subscriptionHistory?.map((data) => {
              return (
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  style={{
                    border: "1px solid #268581",
                    borderRadius: "10px",
                    width: "100%",
                    padding: "15px",
                    marginTop: "15px",
                  }}
                >
                  <Stack>
                    <Typography
                      variant={"heading"}
                      style={{ fontWeight: 700, color: "#323130" }}
                    >
                      {data?.planName}
                    </Typography>
                    <Typography
                      variant={"subHeading"}
                      style={{
                        fontWeight: 400,
                        color: "#323130",
                        marginTop: "8px",
                      }}
                    >
                      <span style={{ fontWeight: 700 }}>
                        ₹{(data?.planAmount)?.toFixed(2)}
                      </span>{" "}
                      {/* Here we are displaying the plan duration */}
                      {/* / {data?.planDuration} Days */}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      style={{
                        fontWeight: 700,
                        color: "#1A1A1A",
                        marginTop: "8px",
                      }}
                    >
                      <span style={{ fontWeight: 400, color: "#868686" }}>
                        Validity Amount :
                      </span>{" "}
                      {/* {formatUnixTimestampToDate(data?.subscriptionExpiryDate)} */}
                      ₹{(data?.validityAmount)?.toFixed(2)}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
      {dialog && (
        <DialogV8
          parent={
            <Layout title={"Add Plan"} classes={classes} reset={resetSheet} />
          }
          width="513px"
          isOpen={!dialog}
          reset={resetSheet}
        >
          <AddPlan
            classes={classes}
            allRechargablePlan={allRechargablePlan}
            services={services}
            openForm={openForm}
            setIsDialog={setIsDialog}
            setSubscriptionHistory={setSubscriptionHistory}
          />
        </DialogV8>
      )}
    </Stack>
  );
};

export default Subscription;
