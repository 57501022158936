import { Stack } from "@mui/material";
import React from "react";
import Breadcrumbs from "../breadcrumbs/Index";
import ResponsiveTypography from "../Typography/Text";

const Header = ({ text, children, classes }) => {
  return (
    <div className={classes.spaceBetween}>
      <Stack
        style={{ justifyContent: "space-between", flexDirection: "row" }}
        mb={1}
        alignItems="center"
      >
        <Breadcrumbs />
      </Stack>
      <div>
        <ResponsiveTypography
          fs="xLarge"
          style={{
            fontWeight: 700,
            marginLeft: ".4rem",
            marginTop: ".8rem",
            fontSize: "28px",
          }}
        >
          {text}
        </ResponsiveTypography>
      </div>
      {children}
    </div>
  );
};

export default Header;
