import React, { useState } from "react";
import { useEffect } from "react";
import LocalStorage from "../../../../../config/LocalStorage";
import APIRequestDataTableQuery from "../../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import apiFetchRequest from "../../../../../utils/ApiFetchRequest";
import useAlert from "../../../../../hooks/useAlert";
import utilController from "../../../../../utils/Utilcontroller";
import { de } from "date-fns/locale";

// this is api calls happen
const useServices = (props) => {
  const { openForm, setOpenForm } = props;
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  const [loading, setLoading] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { publishNotification } = useAlert();

  useEffect(() => {
    if (!props.query) return;
    tableQuery(props?.query);
    // publishNotification("Something went wrong ", "error");
  }, [props.query, LocalStorage.adminButtonPermission]);
  // table query body value handler
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "query" });
      if (
        LocalStorage.adminButtonPermission.find(function (element) {
          return element.button === "query" ? true : false;
        })
      ) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.fetchUsers,
          JSON.stringify(query)
        ).then((tableData) => {
          setTableData(tableData?.data);
          setLoading({ ...loading, isOpen: false, type: "" });
        });
      } else {
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");

      console.log(err);
    } finally {
    }
  };

  // send to server
  const sendToServer = async (userForm, isEdit) => {
    const formCopy = { ...userForm };
    if (formCopy.userType === "admin") {
      delete formCopy?.licenseDetails;
      delete formCopy?.adharNumber;
      delete formCopy?.conductCertificate;
      delete formCopy?.bloodGroup;
      delete formCopy?.adharImage;
      delete formCopy?.experience;
      delete formCopy?.currentLocationDetails;
      delete formCopy?.permanentAddress;
      delete formCopy?.licenseDetails;
      delete formCopy?.emergencyNo;
      delete formCopy?.alternativeNo;
      delete formCopy?.rejectionReason;
    }
    if (formCopy.userType === "user") {
      delete formCopy?.currentLocationDetails;
      delete formCopy?.adharImage;
      delete formCopy?.licenseDetails;
      delete formCopy?.conductCertificate;
      delete formCopy?.bloodGroup;
      delete formCopy?.rejectionReason;
    }

    const fieldValidation = [];

    if (formCopy?.userType === "admin") {
      fieldValidation.push(
        "fname",
        "lname",
        "mobileNo",
        "email",
        "gender",
        "userType"
      );
    } else if (formCopy?.userType === "driver") {
      fieldValidation.push(
        "fname",
        "lname",
        "mobileNo",
        "userType",
        "gender",
        "email"
      );
    }
    if (formCopy.userType === "driver") {
      const drivingLicenseNumber =
        formCopy.licenseDetails?.drivingLicenseNumber;
      if (
        !formCopy?.adharNumber ||
        formCopy?.adharNumber?.toString().length !== 12
      ) {
        publishNotification("Adhar Number must be 12 digits", "error");
        return;
      }
console.log(drivingLicenseNumber.length,"from license")
      if (
        !drivingLicenseNumber ||
        drivingLicenseNumber?.toString()?.length !== 18 
        // !/^[A-Z0-9]{18}$/.test(drivingLicenseNumber)
      ) {
        console.log(drivingLicenseNumber.length + " characters");
        publishNotification(
          "Driving License Number must be 15 characters and contain only uppercase letters and numbers",
          "error"
        );
        return;
      }
    }

    const hasEmptyField = fieldValidation.some((field) => {
      const value = formCopy[field];
      if (typeof value === "string") {
        return value.trim() === "";
      } else {
        return !value;
      }
    });

    // Check if mobileNo has at least 10 digits
    if (formCopy.mobileNo && formCopy.mobileNo.toString().length < 10) {
      publishNotification("Please enter 10 digit mobile number!", "error");
      return;
    }

    const url = isEdit ? ConfigAPIURL.userUpdate : ConfigAPIURL.createUser;
    try {
      if (!hasEmptyField) {
        APIRequest.request(
          isEdit ? "PUT" : "POST",
          url,
          JSON.stringify(formCopy)
        ).then((response) => {
          if (response?.data?.responseCode === 109) {
            setOpenForm({ ...openForm, isOpen: false });
            props.resetRecords();
            publishNotification(
              `Successfully ${isEdit ? "Updated" : "created"} Item`,
              "success"
            );

            tableQuery(props.query);
            props?.resetForm();
            props?.resetRecords();
          } else if (response?.data?.responseCode === 114) {
            publishNotification(`Mobile No. already exists`, "error");
          } else {
            publishNotification(
              `Something went wrong while ${isEdit ? "Updating" : "Creating"} `,
              "error"
            );
          }
        });
      } else {
        publishNotification("Please fill all the required fields", "error");
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");
    }
  };

  const getEditTable = async (recordId, setUserForm) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "edit" });

      APIRequest.request(
        "POST",
        ConfigAPIURL.userDetail,
        JSON.stringify({ userId: recordId })
      ).then((response) => {
        if (response?.data?.responseCode === 109) {
          APIRequest.request(
            "POST",
            ConfigAPIURL.userDetail,
            JSON.stringify({ userId: recordId })
          ).then((response) => {
            if (response?.data?.responseCode === 109) {
              const details = response?.data?.user;
              setUserForm({
                active: details?.active,
                profileImage: details?.profileImage,
                userType: details?.userType,
                fname: details?.fname,
                lname: details?.lname,
                experience: details?.experience,
                gender: details?.gender,
                email: details?.email,
                dob: details?.dob,
                mobileNo: details?.mobileNo,
                emergencyNo: details?.emergencyNo,
                passwordAttempt: details?.passwordAttempt,
                currentLocationDetails: {
                  fragmentedAddress:
                    details?.currentLocationDetails?.fragmentedAddress,
                  location: {
                    type: details?.currentLocationDetails?.location?.type,
                    coordinates:
                      details?.currentLocationDetails?.location?.coordinates,
                  },
                },
                permanentAddress: {
                  fragmentedAddress:
                    details?.permanentAddress?.fragmentedAddress,
                  location: {
                    type: details?.permanentAddress?.location?.type,
                    coordinates:
                      details?.permanentAddress?.location?.coordinates,
                  },
                },
                licenseDetails: {
                  drivingLicenseNumber:
                    details?.licenseDetails?.drivingLicenseNumber,
                  drivingLicenses: details?.licenseDetails?.drivingLicenses,
                  expiryDate: details?.licenseDetails?.expiryDate,
                },
                adharNumber: details?.adharNumber,

                conductCertificate: details?.conductCertificate,
                bloodGroup: details?.bloodGroup,
                adharImage: details?.adharImage,
                password: details?.password,
                permission: details?.permission,
                status: details?.status,
                alternativeNo: details?.alternativeNo,
                isSuperAdmin: details?.isSuperAdmin,
                rejectionReason: details?.rejectionReason,
              });
            } else {
              // publishNotification("Something went wrong ", "error");
            }
          });
        }
      });
    } catch (err) {
      publishNotification(
        // "Something went wrong while recieving data ",
        err,
        "error"
      );
    } finally {
      setLoading({ ...loading, isOpen: false, type: "" });
    }
  };

  const getSubscriptionHistory = async (recordId, setSubscriptionHistory) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "edit" });

      const response = await apiFetchRequest(
        "POST",
        ConfigAPIURL.getSubscriptionHistory,
        JSON.stringify({ userId: recordId })
        // JSON.stringify({ userId: "64fee054582f017af111cd77" })
      );
      if (response) {
        setSubscriptionHistory(response);
      } else {
        publishNotification("Something went wrong ", "error");
      }
    } catch (err) {
      publishNotification(
        // "Something went wrong while recieving data ",
        err,
        "error"
      );
    } finally {
      setLoading({ ...loading, isOpen: false, type: "" });
    }
  };

  const getAllPlans = async (recordId, setAllRechargablePlan) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "edit" });

      const response = await apiFetchRequest(
        "POST",
        ConfigAPIURL.getAllPlans,
        // JSON.stringify({ userId: recordId })
        JSON.stringify({ userId: "64fee054582f017af111cd77" })
      );
      if (response) {
        setAllRechargablePlan(response);
      } else {
        publishNotification("Something went wrong ", "error");
      }
    } catch (err) {
      publishNotification(
        "Something went wrong while recieving data ",
        err,
        "error"
      );
    } finally {
      setLoading({ ...loading, isOpen: false, type: "" });
    }
  };

  const subscribePlan = async (
    rechargeData,
    setIsDialog,
    setSubscriptionHistory
  ) => {
    if (!rechargeData?.subscriptionPlanId || !rechargeData?.userId) {
      publishNotification("Select Recharge plan", "error");
      return;
    }
    if (!rechargeData?.modeOfPayment) {
      publishNotification("Please select Payment Type", "error");
      return;
    }

    try {
      setLoading({ ...loading, isOpen: true, type: "edit" });

      const response = await apiFetchRequest(
        "POST",
        ConfigAPIURL.subscribePlan,
        JSON.stringify({ ...rechargeData })
      );
      if (response) {
        setIsDialog(false);
        publishNotification("Plan Subscribed successfully", "success");
        // setAllRechargablePlan(response);
      } else {
        publishNotification("Something went wrong ", "error");
      }
    } catch (err) {
      publishNotification(
        // "Something went wrong while recieving data ",
        err,
        "error"
      );
    } finally {
      setLoading({ ...loading, isOpen: false, type: "" });
      getSubscriptionHistory(openForm?.rowDetails, setSubscriptionHistory);
    }
  };

  const handleDeleteRecord = async (recordId) => {
    for (let i = 0; i < recordId.length; i++) {
      try {
        const payload = {
          userId: recordId[i]?._id,
          active: false,
        };

        const response = await APIRequest.request(
          "PUT",
          ConfigAPIURL.userUpdate,
          JSON.stringify(payload)
        );
        if (response?.data?.responseCode === 109) {
          // successfully deleted the record
          publishNotification("Successfully deleted the Item", "success");
          tableQuery(props?.query);
          props?.resetForm();
          props?.resetRecords();
        }
      } catch (err) {
        publishNotification("Something went wrong", "error");
      }
    }
  };

  const resetPassAttempts = (recordId) => {
    APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdate,
      JSON.stringify({ userId: recordId[0]?._id, passwordAttempt: 0 })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        tableQuery(props?.query);
        publishNotification("Password attempts reset successfully", "success");
        setOpenForm({
          ...openForm,
          isOpen: false,
        });
        props?.resetRecords();
      } else {
        publishNotification("Something went wrong", "error");
      }
    });
  };

  return {
    tableData,
    setTableData,
    loading,
    sendToServer,
    getEditTable,
    handleDeleteRecord,
    getSubscriptionHistory,
    getAllPlans,
    subscribePlan,
    resetPassAttempts,
  };
};

export default useServices;
