import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  AreaChart,
  Area,
} from "recharts";
import Typography from "../../../../../components/Text/Typography";

const CustomTooltip = ({ active, payload, label, dashboardFilter }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          // width: "159px",
          height: "100%",
          boxShadow: "0px 8px 22px 0px rgba(0, 0, 0, 0.07)",
          //   padding: "15px",
          background: "#fff",
          borderRadius: "10px",
          padding: "5px 14px",
        }}
      >
        <div>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 600,
            }}
          >
            Revenue : ₹{payload[0].value}
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};
function LineCharts(props) {
  const { services, dashboardFilter } = props;
  // console.log("LineGraphData", services?.barGraphData);

  return (
    <div>
      <LineChart
        width={600}
        height={370}
        data={services?.barGraphData}
        syncId="anyId"
        margin={{
          top: 10,
          right: 50,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        {/* <Tooltip /> */}
        <Tooltip
          content={<CustomTooltip dashboardFilter={dashboardFilter} />}
        />
        <Line
          type="linear"
          dataKey="totalCount"
          stroke="#046CE8"
          fill="#046CE8"
          strokeWidth={"2px"}
        />
      </LineChart>
    </div>
  );
}
export default LineCharts;
