const APIRequestDataTableQuery = {
  request: function (method, url, body) {
    let config = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authToken: localStorage.getItem("token"),

        "Accept-Language":
          JSON.parse(localStorage.getItem("lng")) !== undefined &&
          JSON.parse(localStorage.getItem("lng")) !== null
            ? JSON.parse(localStorage.getItem("lng")).code
            : "",
      },
      credentials: "include",
    };
    if (body !== "") {
      config = { ...config, body };
    }
    return fetch(url, config)
      .then((response) => {
        return response.json();
      })
      .then(this.returnResponse)
      .catch((response) => {
        return {
          rows: [],
          pages: 0,
          filterRecords: 0,
        };
      });
  },
  returnResponse: async function (response) {
    if (response.status !== undefined && response.status !== null) {
      return {
        rows: [],
        pages: 0,
        filterRecords: 0,
      };
    } else {
      let result = {
        rows: [],
        pages: 0,
        filterRecords: 0,
      };
      if (response.code === 100) {
        if (response.data !== undefined && response.data !== null) {
          if (response.data.responseCode === 109) {
            let { rows, pages, filterRecords } = response.data;

            result = { rows, pages, filterRecords };
          }
        }
      }
      return Promise.resolve(result);
    }
  },
};
export default APIRequestDataTableQuery;
