/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Stack } from "@mui/material";
import AssignRole from "./components/AssignRole";
import LocalStorage from "../../../../config/LocalStorage";
import { connect } from "react-redux";
import TableComponent from "../../../../components/Table/Table";
import { items } from "../../../../components/Table/items";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import useTable from "./hooks/useTable";
import Toolbar from "../../../../components/EnhancedToolbar/Toolbar";
import useTableFunctions from "./hooks/useTableFunctions";
import EditRole from "./components/EditRole";
import { useStyles } from "./styles/style";

import { PanelConfirmation } from "../../../../components/confirmationpanel/Index";
import { tableButtons } from "../../../../components/tableButtons/TableButtons";
import AddRole from "./components/AddRole";
import OnRenderFooterContent from "../../../../components/panelFooter/Footer";
import Filter from "./components/Filter";
import ViewColumn from "../../../../components/viewcolumn/Index";
import useTableHeader, { columns } from "./TableHeader";
import Pagination from "../../../../components/Table/Pagination";
import PrimaryBtn from "../../../../components/Button/PrimaryBtn";
import { useTheme } from "@mui/styles";
import DeleteComponent from "../../../../components/dialog/DeleteComponent";
import useServices from "./hooks/useServices";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import useAlert from "../../../../hooks/useAlert";
import useDownloadCSV from "../../../../hooks/useDownloadCSV";
import Header from "../../../../components/HeaderUi/Index";
import { Separator } from "@fluentui/react";

const queryBody = {
  userType: "all",
  keyword: "",
  active: true,
  page: 0,
  pageSize: 10,
  startDate: null,
  endDate: new Date() / 1000,
};

const form = {
  isOpen: false,
  isDialogOpen: false,
  title: "",
  divType: "", // like add,edit
  functionName: "",
  rowDetails: null,
  width: 1285,
  hasCloseButton: true,
  isSaveForm: false,
  discription: "",
};

const addFormDetails = {
  name: "",
  permission: [],
  active: false,
};

function Roles(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [query, setQuery] = useState(queryBody);
  const [openForm, setOpenForm] = useState(form); // this is where the form for add,edit
  const [recordId, setRecordId] = React.useState([]);
  const resetForm = () => {
    setOpenForm({ ...form });
  };
  const resetRecords = () => {
    setSelectedRows([]);
    setRecordId([]);
  };

  const [assigned, setAssigned] = useState([]);
  const [unAssigned, setUnAssigned] = useState([]);
  const [addForm, setAddForm] = useState(addFormDetails);

  const { publishNotification } = useAlert();

  const [selectedRows, setSelectedRows] = React.useState([]);

  const {
    addRoleObject,
    setAddRoleObject,
    resetRoleObject,
    addRole,
    handleOpen,
    handleClose,
    open,
    setOpen,
    tableData,
    loading,
    setLoading,
    tableQuery,
    handleDeleteRecord,
  } = useServices({
    openForm,
    setOpenForm,
    query,
    resetForm,
    resetRecords,
    setRecordId,
    setSelectedRows,
  });

  const columns = useTableHeader(setOpenForm, openForm);

  const {
    viewColumn,
    handlerowAction,
    clickRecordAction,
    clearSelectedRows,
    updateColumn,
    resetColumns,
    handleBulkSelection,
  } = useTable(
    columns,
    setOpenForm,
    resetForm,
    selectedRows,
    setSelectedRows,
    recordId,
    setRecordId
  );
  const { downloadCsv } = useDownloadCSV(columns, tableData?.result); // download excel sheet hook

  const tableFunctions = useTableFunctions({
    // downloadCsv,
    viewColumn,
    setOpenForm,
    selectedRows: recordId,
    handleOpen,
    handleClose,
  });
  useEffect(() => {
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);

  const [buttonList, setButtonDetails] = useState([]);

  const sendToServer = () => {
    APIRequest.request(
      "PUT",
      ConfigAPIURL.roleUpdate,
      JSON.stringify({
        roleId: recordId[0]?._id,
        name: addForm.name,
        active: addForm.active,
        permission: addForm.permission,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setSelectedRows([]);
          setRecordId([]);
          publishNotification("Role Updated Successfully", "success");
          tableQuery(query);

          setAddForm(addFormDetails);
        }
        setOpenForm({ ...openForm, isOpen: false });
        tableQuery(query);
        if (response.code === 100 && response.data.responseCode === 114) {
          publishNotification("Duplicate Role", "error");
        }
      }
    });
  };
  const sendToServerAssign = () => {
    APIRequest.request(
      "PUT",
      ConfigAPIURL.roleGrant,
      JSON.stringify({ roleId: recordId[0]?._id, assign: assigned })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setSelectedRows([]);
          publishNotification("Role Assigned Successfully", "success");
          tableQuery(query);
          setRecordId([]);
          setAssigned([]);
        }
        setOpenForm({ ...openForm, isOpen: false });
        tableQuery(query);
        if (response.code === 100 && response.data.responseCode === 114) {
          publishNotification("Duplicate Role", "error");
        }
      }
    });
  };

  const buttonListFiltered = (data) => {
    return data
      ?.filter((value, i) => !value?.disable)
      ?.map((value) => value?.button);
  };

  const resetQuery = () => {
    setQuery(queryBody);
    resetForm();
  };

  return (
    <>
      <div
        style={{
          marginTop: "96px",
          // margin: "40px",
          marginTop: "40px",
          marginBottom: "44px",
          marginLeft: "16px",
          marginRight: "16px",
          // backgroundColor: "#fff",
          borderRadius: "15px",
          paddingTop: "10px",
        }}
      >
        <DeleteComponent
          isOpen={
            openForm?.isDialogOpen && openForm?.divType === "deleteDialog"
          }
          resetDialog={resetForm}
          handleDelete={() => handleDeleteRecord(openForm?.rowDetails)}
        />

        <Header classes={classes} text="Roles" />

        <Separator className="seperator" style={{ margin: 0, padding: 0 }} />

        <div className={classes.spaceBetween}>
          <Toolbar
            handleSearch={(searchValue) =>
              setQuery({ ...query, keyword: searchValue })
            }
            classes={classes}
            tableButtons={tableButtons} // this is table btn at left side
            buttonList={buttonListFiltered(buttonList)}
            buttonFunctions={{ ...tableFunctions }}
            themeColor={theme?.palette?.primary?.main}
            setQuery={setQuery}
            query={query}
            downloadCsv={downloadCsv}
            viewColumn={viewColumn}
          >
            {/* pass the children */}
          </Toolbar>

          <FluentProvider theme={teamsLightTheme}>
            <Stack
              style={{
                marginTop: "2rem",
                boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: "5px",
                // overflow: "auto",
              }}
              className="tablegrid"
            >
              <TableComponent
                items={tableData?.rows || []}
                columns={columns}
                multiselect={true} // default will be single whereas developer can move to multiple selections
                viewColumn={viewColumn}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                updateRecord={clickRecordAction}
                rowAction={handlerowAction}
                loading={loading}
                handleBulkSelection={handleBulkSelection}
              />
            </Stack>
            <Pagination
              query={query}
              setQuery={setQuery}
              tableData={tableData}
            />
            {openForm?.isOpen && (
              <PanelConfirmation
                isOpen={openForm?.isOpen}
                width={openForm?.width}
                hasCloseButton={openForm?.hasCloseButton}
                dismissPanel={resetForm}
                onRenderFooterContent={() => (
                  <OnRenderFooterContent
                    field1={{
                      text:
                        openForm?.divType === "column"
                          ? "Reset"
                          : openForm?.divType === "add"
                          ? "Save"
                          : "Save",
                      handle: () =>
                        setOpenForm({
                          ...openForm,
                          isSaveForm:
                            openForm?.divType === "add"
                              ? addRole()
                              : openForm?.divType === "edit"
                              ? sendToServer()
                              : openForm?.divType === "column"
                              ? true
                              : openForm?.divType === "filter"
                              ? true
                              : sendToServerAssign(),
                        }),
                    }}
                    field2={{
                      text: openForm?.divType === "filter" ? "Reset" : "Cancel",
                      handle:
                        openForm?.divType === "filter" ? resetQuery : resetForm,
                    }}
                  />
                )}
              >
                {/* {openForm?.isOpen && openForm?.divType === "add" && ( */}

                {/* )} */}
                {openForm?.isOpen && openForm?.divType === "edit" && (
                  <EditRole
                    openForm={openForm}
                    setOpenForm={setOpenForm}
                    classes={classes}
                    recordId={recordId[0]?._id}
                    setAddForm={setAddForm}
                    addForm={addForm}
                    setAssigned={setAssigned}
                    setUnAssigned={setUnAssigned}
                    assigned={assigned}
                    unAssigned={unAssigned}
                    addFormDetails={addFormDetails}
                    resetRecords={resetRecords}
                    setRecordId={setRecordId}
                    setSelectedRows={setSelectedRows}
                  />
                )}

                {openForm?.divType === "column" && (
                  <ViewColumn
                    filteredColumn={viewColumn}
                    UpdateFilter={updateColumn}
                    openForm={openForm}
                    resetColumns={resetColumns}
                  />
                )}
                {openForm?.isOpen && openForm?.divType === "assignRole" && (
                  <AssignRole
                    formDialog={openForm}
                    rowDetails={recordId[0]}
                    tableQuery={tableQuery}
                    isAssign={openForm?.isOpen}
                    recordId={recordId[0]?._id}
                    setAddForm={setAddForm}
                    addForm={addForm}
                    setAssigned={setAssigned}
                    setUnAssigned={setUnAssigned}
                    assigned={assigned}
                    unAssigned={unAssigned}
                    resetRecords={resetRecords}
                  />
                )}
                {openForm?.divType === "filter" && (
                  <Filter
                    query={query}
                    setQuery={setQuery}
                    openForm={openForm}
                    resetForm={resetForm}
                  />
                )}
              </PanelConfirmation>
            )}

            <AddRole
              openForm={openForm}
              setOpenForm={setOpenForm}
              classes={classes}
              addRoleObject={addRoleObject}
              setAddRoleObject={setAddRoleObject}
              resetRoleObject={resetRoleObject}
              addRole={addRole}
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              resetRecords={resetRecords}
            />
          </FluentProvider>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Roles)
);
