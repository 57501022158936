import React, { useEffect, useState } from "react";
import useAlert from "../../../../../hooks/useAlert";

const form = {
  tariffType: "variable",
  extraTime: {
    pricePerMinute: "",
  },
  foodAllowance: "",
  locationDetails: {
    location: {
      type: "Point",
      coordinates: [0, 0],
    },
    fragmentedAddress: "",
    radius: "",
  },
  nightFare: {
    price: "",
  },
  outStation: {
    minRadius: 0,
    oneWayNotes: "",
    roundTripNotes: "",
    timeInHours: 0,
    onewayExtraTime: 0,
    roundTripExtraTime: 0,
    oneWay: [{}],
    roundTrip: [{}],
  },
  cityLimit: {
    maxRadius: 0,
    minRadius: 0,
    oneWayNotes: "",
    roundTripNotes: "",
    timeInHours: 0,
    onewayExtraTime: 0,
    roundTripExtraTime: 0,
    oneWay: [{}],
    roundTrip: [{}],
  },
  outSkirt: {
    maxRadius: 0,
    minRadius: 0,
    oneWayNotes: "",
    roundTripNotes: "",
    timeInHours: 0,
    onewayExtraTime: 0,
    roundTripExtraTime: 0,
    oneWay: [{}],
    roundTrip: [{}],
  },
};

const useAevForm = ({ openForm, services }) => {
  const [userForm, setUserForm] = useState(form);

  useEffect(() => {
    if (openForm?.isOpen && openForm?.divType === "add") {
      setUserForm(JSON.parse(JSON.stringify(form))); // Deep clone the form
    }
  }, [openForm?.isOpen]);

  useEffect(() => {
    if (
      openForm?.rowDetails &&
      (openForm?.divType === "edit" || openForm?.divType === "view")
    ) {
      services?.getEditTable(openForm?.rowDetails, setUserForm);

      services?.getEditTable(openForm?.rowDetails, setUserForm);
    }
  }, []);

  React.useEffect(() => {
    if (openForm?.isSaveForm) {
      let isEdit = false;
      if (openForm?.divType === "edit") {
        userForm["recordId"] = openForm?.rowDetails;
        isEdit = true;
      }

      services?.sendToServer(userForm, isEdit, services?.recordId);
    }
  }, [openForm]);

  return {
    userForm,
    setUserForm,
  };
};

export default useAevForm;
