import React, { useState } from "react";
import { useEffect } from "react";
import LocalStorage from "../../../../../config/LocalStorage";
import APIRequestDataTableQuery from "../../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import useAlert from "../../../../../hooks/useAlert";

// this is api calls happen
const useServices = (props) => {
  const {
    dashboardFilter,
    setDashboardFilter,
    pieChartFilter,
    setPieChartFilter,
    setTotalBooking,
  } = props;
  const [dashboardCounts, setDashboardCounts] = useState([]);
  const [barGraphData, setBarGraphData] = useState([]);
  const [bookingGraphData, setBookingGraphData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const { publishNotification } = useAlert();

  useEffect(() => {
    dashboardCount();
  }, []);
  useEffect(() => {
    graphStats();
  }, [
    dashboardFilter.requestType,
    dashboardFilter.dateType,
    dashboardFilter.startDate,
    dashboardFilter.endDate,
    dashboardFilter.keyword,
  ]);
  useEffect(() => {
    graphStatsBooking();
  }, [
    dashboardFilter.requestTypeBooking,
    dashboardFilter.dateTypeBooking,
    dashboardFilter.startDateBooking,
    dashboardFilter.endDateBooking,
    dashboardFilter.keywordBooking,
  ]);
  useEffect(() => {
    pieChartStats();
  }, [
    pieChartFilter.startDate,
    pieChartFilter.endDate,
    pieChartFilter.keyword,
  ]);
  const calendar = {
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sept",
      "Oct",
      "November",
      "December",
    ],
  };

  const dashboardCount = () => {
    APIRequest.request("GET", ConfigAPIURL.dashboardCardStats).then(
      (response) => {
        if (response?.data?.responseCode === 109) {
          setDashboardCounts(response?.data?.result);
        }
      }
    );
  };
  const pieChartStats = async () => {
    const url = `${ConfigAPIURL.pieChartStats}?startDate=${pieChartFilter?.startDate}&endDate=${pieChartFilter.endDate}&keyword=${pieChartFilter.keyword}`;

    try {
      const response = await APIRequest.request("GET", url);

      if (response?.data?.responseCode === 109) {
        const data = response.data.result;
        const allTypes = ["user", "driver", "admin"]; // List of all possible types
        const result = allTypes.map((type) => {
          const existingData = data.find((item) => item._id === type);
          return {
            name: type,
            value: existingData ? existingData.userCount : 0,
          };
        });

        setPieChartData(result);
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  const graphStats = () => {
    const url = `${ConfigAPIURL.revenueBarGraph}?dateType=${dashboardFilter?.dateType}&startDate=${dashboardFilter?.startDate}&endDate=${dashboardFilter?.endDate}&requestType=${dashboardFilter?.requestType}&keyword=${dashboardFilter?.keyword}`;

    APIRequest.request("GET", url).then((response) => {
      if (response?.data?.responseCode === 109) {
        const result = response?.data?.result;
        let groupedData = {};
        let totalCounter = 0;

        if (dashboardFilter?.dateType === "day") {
          result.forEach((item) => {
            const day = item?._id?.day;
            const month = item?._id?.month;
            const year = item?._id?.year;
            const status = item.status;

            const formattedDay = `${day < 10 ? "0" : ""}${day} ${
              calendar.monthNames[month - 1]
            }`;

            if (!groupedData[formattedDay]) {
              groupedData[formattedDay] = {
                day: formattedDay,
                totalCount: 0,
                data: {},
              };
            }

            groupedData[formattedDay].totalCount += item.count;
            groupedData[formattedDay].data[status] =
              (groupedData[formattedDay].data[status] || 0) + item.count;
            totalCounter += item.count; // Accumulate the total count.
          });

          // Convert the grouped data to an array of objects.
          const outputData = Object.values(groupedData).map((obj) => ({
            day: obj.day,
            totalCount: obj.totalCount,
            data: obj.data,
          }));
          setDashboardFilter({
            ...dashboardFilter,
            totalRevenue: totalCounter,
          });
          // console.log("logtotalCounter", totalCounter);
          setBarGraphData(outputData);
        }
      }
    });
  };
  const graphStatsBooking = () => {
    const url = `${ConfigAPIURL.bookingsGraph}?dateType=${dashboardFilter?.dateTypeBooking}&startDate=${dashboardFilter?.startDateBooking}&endDate=${dashboardFilter?.endDateBooking}&requestType=${dashboardFilter?.requestTypeBooking}&keyword=${dashboardFilter?.keywordBooking}`;

    APIRequest.request("GET", url).then((response) => {
      if (response?.data?.responseCode === 109) {
        const result = response?.data?.result;
        let groupedData = {};
        let totalCounter = 0;

        if (dashboardFilter?.dateTypeBooking === "day") {
          result.forEach((item) => {
            const day = item?._id?.day;
            const month = item?._id?.month;
            const year = item?._id?.year;
            const status = item?._id?.status;

            const formattedDay = `${day < 10 ? "0" : ""}${day} ${
              calendar.monthNames[month - 1]
            }`;

            if (!groupedData[formattedDay]) {
              groupedData[formattedDay] = {
                day: formattedDay,
                totalCount: 0,
                data: {},
              };
            }

            groupedData[formattedDay].totalCount += item.count;
            groupedData[formattedDay].data[status] =
              (groupedData[formattedDay].data[status] || 0) + item.count;
            totalCounter += item.count; // Accumulate the total count.
          });

          // Convert the grouped data to an array of objects.
          const outputData = Object.values(groupedData).map((obj) => ({
            day: obj.day,
            totalCount: obj.totalCount,
            data: obj.data,
          }));
          setTotalBooking(totalCounter);
          setBookingGraphData(outputData);
        }
      }
    });
  };

  // const graphStats = () => {
  //   const url = `${ConfigAPIURL.revenueBarGraph}?dateType=${dashboardFilter?.dateType}&startDate=${dashboardFilter?.startDate}&endDate=${dashboardFilter?.endDate}&requestType=${dashboardFilter?.requestType}`;
  //   APIRequest.request("GET", url).then((response) => {
  //     if (response?.data?.responseCode === 109) {
  //       const result = response?.data?.result;
  //       let groupedData = {};
  //       if (dashboardFilter?.dateType === "month") {
  //         result.forEach((item) => {
  //           const month = item?._id?.month;
  //           const year = item?._id?.year;
  //           const status = item.status;
  //           console.log("inside month", item);

  //           if (!groupedData[month]) {
  //             groupedData[month] = {
  //               month: month,
  //               year,
  //               totalCount: 0,
  //               data: {},
  //             };
  //           }

  //           groupedData[month].totalCount += item.totalAmount;
  //           groupedData[month].data[status] =
  //             (groupedData[month].data[status] || 0) + item.count;
  //         });
  //       }
  //       if (dashboardFilter?.dateType === "week") {
  //         console.log("inside week");
  //         result.forEach((item) => {
  //           const week = item.week;
  //           const month = item.month;
  //           const year = item.year;
  //           const status = item.status;

  //           if (!groupedData[week]) {
  //             groupedData[week] = {
  //               week,
  //               month,
  //               year,
  //               totalCount: 0,
  //               data: {},
  //             };
  //           }

  //           groupedData[week].totalCount += item.count;
  //           groupedData[week].data[status] =
  //             (groupedData[week].data[status] || 0) + item.count;
  //         });
  //       }
  //       if (dashboardFilter?.dateType === "year") {
  //         result.forEach((item) => {
  //           const year = item?._id?.year;
  //           console.log("inside year", item);

  //           if (!groupedData[year]) {
  //             groupedData[year] = {
  //               year,
  //               totalCount: 0,
  //               data: {},
  //             };
  //           }

  //           groupedData[year].totalCount += item.totalAmount;
  //           groupedData[year].data[item.status] =
  //             (groupedData[year].data[item.status] || 0) + item.count;
  //         });
  //       }
  //       if (dashboardFilter?.dateType === "day") {
  //         result.forEach((item) => {
  //           const day = item?._id?.day;

  //           console.log("inside day", item);
  //           if (!groupedData[day]) {
  //             groupedData[day] = {
  //               day,
  //               totalCount: 0,
  //               data: {},
  //             };
  //           }

  //           groupedData[day].totalCount += item.count;
  //           groupedData[day].data[item.status] =
  //             (groupedData[day].data[item.status] || 0) + item.count;
  //         });
  //       }

  //       // Remove the number from the key of each object.
  //       groupedData = Object.entries(groupedData).reduce(
  //         (acc, [key, value]) => {
  //           acc[key] = value;
  //           return acc;
  //         },
  //         {}
  //       );

  //       // Remove the "9": and "10": strings.
  //       groupedData = Object.entries(groupedData).reduce(
  //         (acc, [key, value]) => {
  //           const newKey = key.replace(/:/g, "");
  //           acc[newKey] = value;
  //           return acc;
  //         },
  //         {}
  //       );

  //       if (dashboardFilter?.dateType === "month") {
  //         // Convert the grouped data to an array of objects.
  //         const outputData = Object.values(groupedData).map((obj) => ({
  //           month: obj.month,
  //           year: obj.year,
  //           totalCount: obj.totalCount,
  //           data: obj.data,
  //         }));

  //         // Convert the month numbers to month names.
  //         outputData.forEach((obj) => {
  //           obj.month = calendar.monthNames[obj.month - 1];
  //         });
  //         setBarGraphData(outputData);
  //       }

  //       if (dashboardFilter?.dateType === "week") {
  //         // Convert the grouped data to an array of objects.
  //         const outputData = Object.values(groupedData).map((obj) => ({
  //           week: obj.week,
  //           month: obj.month,
  //           year: obj.year,
  //           totalCount: obj.totalCount,
  //           data: obj.data,
  //         }));
  //         setBarGraphData(outputData);
  //       }

  //       if (dashboardFilter?.dateType === "year") {
  //         // Convert the grouped data to an array of objects.
  //         const outputData = Object.values(groupedData).map((obj) => ({
  //           year: obj.year,
  //           totalCount: obj.totalCount,
  //           data: obj.data,
  //         }));

  //         setBarGraphData(outputData);
  //       }
  //       if (dashboardFilter?.dateType === "day") {
  //         // Convert the grouped data to an array of objects.
  //         const outputData = Object.values(groupedData).map((obj) => ({
  //           day: obj.day,
  //           totalCount: obj.totalCount,
  //           data: obj.data,
  //         }));

  //         setBarGraphData(outputData);
  //       }

  //       // setBarGraphData(outputData);
  //     }
  //   });
  // };

  return {
    dashboardCounts,
    barGraphData,
    pieChartData,
    bookingGraphData,
  };
};

export default useServices;
