import React from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      position: "fixed  !important",
      zIndex: "1196 !important",
      left: "251px !important",
    },
  },
  appBar: {
    position: "fixed  !important",
    zIndex: "1196 !important",
    [theme.breakpoints.up("md")]: {
      left: "251px !important",
      paddingRight: "251px",
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FormDialog(props) {
  const { openForm } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog
        className={!props.sideDrawerData ? classes.root : ""}
        fullScreen
        open={props.formDialog}
        TransitionComponent={Transition}
      >
        <AppBar className={!props.sideDrawerData ? classes.appBar : ""}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleFormDialog}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.formDialog.title}
            </Typography>
            {props.label !== undefined && props.label !== null ? (
              <Typography variant="h6">{props.label}</Typography>
            ) : (
              <></>
            )}
            {props.formDialog.divType === "new" ? (
              <>
                <Button
                  autoFocus
                  color="inherit"
                  style={{ marginRight: "10px" }}
                  onClick={props.resetForm}
                >
                  Reset
                </Button>
              </>
            ) : (
              <></>
            )}
            {props.formDialog.divType === "new" ? (
              <Button autoFocus color="inherit" onClick={() => props.submit()}>
                {props.t("buttons.save")}
              </Button>
            ) : props.formDialog.divType === "edit" ||
              props.formDialog.divType === "assign" ? (
              <Button autoFocus color="inherit" onClick={() => props.submit()}>
                {props.t("buttons.update")}
              </Button>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>

        <div
          style={{
            marginTop: "15px",
            padding: "24px",
            background: "#f8f8f8",
            height: "100%",
          }}
        >
          {props.formComponent}
        </div>
      </Dialog>
    </div>
  );
}
export default withTranslation("translations")(FormDialog);
