import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Hidden,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";
import TranslateIcon from "@mui/icons-material/Translate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageConfig from "../../../../config/LanguageConfig";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { useStyles } from "./topbarStyles";
import { Icon } from "@fluentui/react/lib/Icon";
import Logo from "../../../../assets/images/falainalogo.png";
import images from "../../../../images";
import LocalStorage from "../../../../config/LocalStorage";
import avatarIcon from "../../../../assets/images/avatar_default.jpg";
// import LogoutModal from "./LogoutModal"
import { AlertBadge24Regular, Alert24Regular } from "@fluentui/react-icons";
// import LogoutComponent from "./LogoutComponent"
import mainLogo from "../../../../assets/mainLogo.png";

function TopBar(props) {
  const classes = useStyles();
  const [languageList, setLanguageList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    LanguageConfig.languageList().then((lngList) => {
      setLanguageList(lngList);
    });
    props.i18n.changeLanguage(props.languageData.code);
  }, []);
  const notificationTest = () => {
    SnackbarUtils.sucess("HI", "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  const languageChange = (data) => {
    props.i18n.changeLanguage(data.code);
    props.languageChange(data);
    localStorage.setItem("lng", JSON.stringify(data));
    handleLanguageMenuClose();
  };
  const [languageDropdown, setLanguageDropdown] = React.useState(null);
  const handleLanguageMenuClick = (event) => {
    setLanguageDropdown(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setLanguageDropdown(null);
  };

  const [showLogoutModal, setShowLogoutModal] = React.useState(false);

  const openLogoutModal = () => {
    setShowLogoutModal(true);
  };
  const closeModal = () => {
    setShowLogoutModal(false);
  };

  return (
    <>
    <div>
      <AppBar
        position="fixed"
        className={
          props.mobileOpen ? classes.appBarDrawerClose : classes.appBar
        }
      >
        <Toolbar className={classes.Toolbar}>
          <Tooltip ml={1} title={props.t("topNavBar.menu")}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              className={classes.menuButton}
            >
              <Icon
                iconName="Waffle"
                style={{
                  color: "white",
                  // marginTop: "20px",
                  height: "29px",
                  width: "29px",
                }}
              />
            </IconButton>
          </Tooltip>

          <Stack className={classes.title} alignItems="start">
              <img
                className={classes.logo}
                src={mainLogo}
                alt="logo"
              />
            </Stack>

          <Hidden only={["", "", "", ""]}>
            <Tooltip ml={2} title={"Profile"}></Tooltip>
            <Tooltip ml={2} title={"Profile"}></Tooltip>

            <Tooltip ml={2} title={props.t("topNavBar.logout")}>
            <IconButton onClick={openLogoutModal}>
                  <ExitToAppIcon className={classes.logoutIcon} />
                </IconButton>
            </Tooltip>
          </Hidden>
          {/* <Hidden only={["sm", "xs", ""]}>
            <Tooltip ml={2} title={props.t("topNavBar.moreMenu")}>
              <IconButton
                onClick={() => {
                  props.handleBottomDrawerOpen();
                }}
              >
                <MoreVertIcon
                  onClick={() => {
                    props.handleBottomDrawerOpen();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Hidden> */}
        </Toolbar>
      </AppBar>
      {/* <LogoutComponent isOpen={open}>
      <LogoutModal close={()=>setOpen(false)} logout={props.logout} />
      </LogoutComponent> */}
    </div>
    <Dialog open={showLogoutModal}>
        <DialogSurface>
          <DialogBody>
            <DialogContent style={{ fontFamily: "Segoe UI" }}>
              Are you sure you want to logout?
            </DialogContent>
            <DialogActions>
              <Button
                appearance="secondary"
                style={{
                  background: "white",
                  color: "black",
                  borderRadius: "4px",
                  border: "1px solid black",
                  height:"30px",
                  fontFamily:"Segoe UI"
                }}
                onClick={closeModal}
              >
                Close
              </Button>

              <Button
                appearance="primary"
                style={{
                  background: "#268581",
                  color: "white",
                  borderRadius: "4px",
                  height:"30px",
                  fontFamily:"Segoe UI"
                }}
                onClick={() => props.logout()}
              >
                Logout
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
}

TopBar.propTypes = {
  window: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    languageData: state.languageData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    languageChange: (languageData) =>
      dispatch({ type: "LANGUAGE", value: languageData }),
    logout: () => dispatch({ type: "LOGOUT", value: "" }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(TopBar)
);
