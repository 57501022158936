import React, { useState } from "react";
import { useEffect } from "react";
import LocalStorage from "../../../../../config/LocalStorage";
import APIRequestDataTableQuery from "../../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import apiFetchRequest from "../../../../../utils/ApiFetchRequest";
import useAlert from "../../../../../hooks/useAlert";
import utilController from "../../../../../utils/Utilcontroller";

// this is api calls happen
const useServices = (props) => {
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  const [loading, setLoading] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { publishNotification } = useAlert();

  useEffect(() => {
    if (!props.query) return;
    tableQuery(props?.query);
    // publishNotification("Something went wrong ", "error");
  }, [props.query, LocalStorage.adminButtonPermission]);
  // table query body value handler
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "query" });
      if (
        LocalStorage.adminButtonPermission.find(function (element) {
          return element.button === "query" ? true : false;
        })
      ) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.fetchBookings,
          JSON.stringify(query)
        ).then((tableData) => {
          setTableData(tableData?.data);
          setLoading({ ...loading, isOpen: false, type: "" });
        });
      } else {
        // notification(props.t('toaster.tableRecordPermission'), 'error');
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");

      console.log(err);
    } finally {
    }
  };
  const sendToServer = async (userForm, isEdit) => {
    const url = isEdit
      ? ConfigAPIURL.updatePackage
      : ConfigAPIURL.createPackage;
    try {
      const response = await apiFetchRequest(
        "POST",
        url,
        JSON.stringify(userForm)
      );
      if (response) {
        // show the popup successfully created lead
        publishNotification(
          `Successfully ${isEdit ? "Updated" : "created"} Item`,
          "success"
        );
        // close the panel and make request to fetch the results
        props.resetForm();
        tableQuery(props?.query);
      } else {
        publishNotification(
          `Something went wrong while ${isEdit ? "Updating" : "Creating"} `,
          "error"
        );

        //show the popup something went wrong
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");
    }
  };
  const getEditTable = async (recordId, setUserForm) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "edit" });

      const response = await apiFetchRequest(
        "POST",
        // ConfigAPIURL.bookingsDetail + "?userId=" + recordId,
        ConfigAPIURL.bookingsDetail,
        JSON.stringify({ bookingId: recordId })
        // recordId
      );
      if (response) {
        setUserForm(response);
      } else {
        publishNotification("Something went wrong ", "error");

        //show the popup something went wrong
      }
    } catch (err) {
      publishNotification(
        "Something went wrong while recieving data ",
        err,
        "error"
      );
    } finally {
      setLoading({ ...loading, isOpen: false, type: "" });
    }
  };

  const handleDeleteRecord = async (recordId) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.gggg,
        JSON.stringify({ recordId: recordId[0]?._id, active: false })
      );
      if (response?.data?.responseCode === 109) {
        // successfully deleted the record
        publishNotification("Successfully deleted the Item", "success");
        tableQuery(props?.query);
        props?.resetForm();
        props?.resetRecords();
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");
    }
  };

  return {
    tableData,
    setTableData,
    loading,
    sendToServer,
    getEditTable,
    handleDeleteRecord,
  };
};

export default useServices;
