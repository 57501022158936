import React from "react";
import TablePagination from "@mui/material/TablePagination";
import { Stack } from "office-ui-fabric-react";

const Pagination = ({ query, setQuery, tableData }) => {
  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setQuery((p) => {
      return {
        ...p,
        pageSize,
      };
    });
  };
  const handleChangePage = (e, page) => {
    setQuery((p) => {
      return {
        ...p,
        page,
      };
    });
  };
  return (
  
    <TablePagination
      component="div"
      count={tableData?.filterRecords}
      page={query?.page}
      onPageChange={handleChangePage}
      rowsPerPage={query?.pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
 
  
  );
};

export default Pagination;
