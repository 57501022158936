import React from "react";
import DialogComponent from "./Index";
import { Button, DialogTrigger } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";

const DialogAction = ({ handleDelete, resetDialog }) => (
  <Stack style={{ marginTop: "1rem", gap: ".5rem" }} horizontal>
    <DialogTrigger disableButtonEnhancement>
      <Button appearance="secondary" onClick={resetDialog}>
        Cancel
      </Button>
    </DialogTrigger>
    <Button
      // appearance="primary"
      style={{ background: "#268581", color: "white" }}
      onClick={handleDelete}
    >
      Delete
    </Button>
  </Stack>
);

const DeleteComponent = ({ resetDialog, isOpen, handleDelete, message }) => {
  return (
    <div>
      <DialogComponent
        setIsopen={resetDialog}
        isOpen={isOpen}
        actionButtons={
          <DialogAction handleDelete={handleDelete} resetDialog={resetDialog} />
        }
        style={{ padding: "24px" }}
      >
        {message || "Are you sure you want to delete this record?"}
      </DialogComponent>
    </div>
  );
};

export default DeleteComponent;
