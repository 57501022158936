import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { DataContext } from "./AEVForm";
import Details from "./Tabs/Details";
import Subscription from "./Tabs/Subscription";
import Reviews from "./Tabs/Reviews";

const UserDetails = () => {
  const { classes, theme, userForm, openForm } = React.useContext(DataContext);

  const [activeTab, setActiveTab] = useState("Details");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <Grid
        container
        spacing={theme.spacingArea.xLarge}
        marginTop={"10px"}
        style={{ background: "#fff", padding: "12px" }}
      >
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {userForm?.userType === "driver" && openForm?.divType === "edit" && (
            <div className={classes.tabContainer}>
              <div
                className={`${classes.tab} ${
                  activeTab === "Details"
                    ? classes.activeTab
                    : classes.inactiveTab
                }`}
                onClick={() => handleTabClick("Details")}
              >
                <p style={{ fontSize: "16px", fontWeight: "600" }}>Details</p>
              </div>

              <div
                className={`${classes.tab2} ${
                  activeTab === "subscription"
                    ? classes.activeTab
                    : classes.inactiveTab
                }`}
                onClick={() => handleTabClick("subscription")}
              >
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  Subscription
                </p>
              </div>
              <div
                className={`${classes.tab3} ${
                  activeTab === "reviews"
                    ? classes.activeTab
                    : classes.inactiveTab
                }`}
                onClick={() => handleTabClick("reviews")}
              >
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  Reviews
                </p>
              </div>
            </div>
          )}
        </Grid>
        <Grid
          className={`${classes.content} ${
            activeTab === "Details" && classes.activeContent
          }`}
        >
          <Details />
        </Grid>

        <Grid
          className={`${classes.content} ${
            activeTab === "subscription" && classes.activeContent
          }`}
        >
          <Subscription />
        </Grid>
        <Grid
          className={`${classes.content} ${
            activeTab === "reviews" && classes.activeContent
          }`}
        >
          <Reviews />
        </Grid>
      </Grid>
    </>
  );
};

export default UserDetails;
