import APIRequest from "./APIRequest";

const apiFetchRequest = async (method, url, body) => {
  try {
    const response = await APIRequest.request(method, url, body);
    if (response?.data?.responseCode === 109) {
      return response?.data?.result;
    } else return null;
  } catch (err) {
    console.log(err);
  }
};

export default apiFetchRequest;
