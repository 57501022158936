/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Stack } from "@mui/material";

import LocalStorage from "../../../../config/LocalStorage";
import { connect } from "react-redux";
import TableComponent from "../../../../components/Table/Table";
import { items } from "../../../../components/Table/items";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import useTable from "./hooks/useTable";
import Toolbar from "../../../../components/EnhancedToolbar/Toolbar";
import useTableFunctions from "./hooks/useTableFunctions";
import useDownloadCSV from "../../../../hooks/useDownloadCSV";
import Header from "../../../../components/HeaderUi/Index";
import { useStyles } from "./styles/style";
import useServices from "./hooks/useServices";
import { Separator } from "@fluentui/react";
import { PanelConfirmation } from "../../../../components/confirmationpanel/Index";
import { tableButtons } from "../../../../components/tableButtons/TableButtons";
import AEVForm from "./components/AEVForm";
import onRenderFooterContent from "../../../../components/panelFooter/Footer";
import OnRenderFooterContent from "../../../../components/panelFooter/Footer";
import Filter from "./components/Filter";
import ViewColumn from "../../../../components/viewcolumn/Index";
import useTableHeader, { columns } from "./TableHeader";
import Pagination from "../../../../components/Table/Pagination";
import Alert from "../../../../components/Alert/Index";
import PrimaryBtn from "../../../../components/Button/PrimaryBtn";
import { useTheme } from "@mui/styles";
import DeleteComponent from "../../../../components/dialog/DeleteComponent";
import AlertComponent from "../../../../components/Alert/Index";
import TopBar from "../Drawer_Appbar/TopBar";

const queryBody = {
  userType: "all",
  keyword: "",
  active: true,
  page: 0,
  pageSize: 10,
  startDate: null,
  endDate: new Date() / 1000,
};

const form = {
  isOpen: false,
  isDialogOpen: false,
  title: "",
  divType: "", // like add,edit
  functionName: "",
  rowDetails: null,
  width: 1285,
  hasCloseButton: true,
  isSaveForm: false,
  discription: "",
};

function Index(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [openForm, setOpenForm] = useState(form); // this is where the form for add,edit
  const resetForm = () => {
    setOpenForm({ ...form });
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [recordId, setRecordId] = React.useState([]);

  const resetRecords = () => {
    setSelectedRows([]);
    setRecordId([]);
  };

  const [query, setQuery] = useState(queryBody);

  const services = useServices({
    query,
    resetForm,
    resetRecords,
    recordId,
    openForm,
  });

  const columns = useTableHeader(setOpenForm, openForm, services);

  const {
    viewColumn,
    handlerowAction,
    clickRecordAction,
    clearSelectedRows,
    updateColumn,
    resetColumns,
    handleBulkSelection,
  } = useTable(
    columns,
    setOpenForm,
    resetForm,
    selectedRows,
    setSelectedRows,
    recordId,
    setRecordId
  ); // table hook
  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/

  const { downloadCsv } = useDownloadCSV(columns, services?.tableData?.result); // download excel sheet hook
  const tableFunctions = useTableFunctions({
    downloadCsv,
    viewColumn,
    setOpenForm,
    selectedRows: recordId,
  }); // table top functions like add, edit, delete
  // buttons list for data table
  // useful of on page load
  useEffect(() => {
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);

  const [buttonList, setButtonDetails] = useState([]);

  //keyboard shortcut

  const buttonListFiltered = (data) => {
    return data
      ?.filter((value, i) => !value?.disable)
      ?.map((value) => value?.button);
  };

  const resetQuery = () => {
    setQuery(queryBody);
    resetForm();
  }


  return (
    <div
      style={{
        marginTop: "96px",
        // margin: "40px",
        marginTop: "40px",
        marginBottom: "44px",
        marginLeft: "16px",
        marginRight: "16px",
        // backgroundColor: "#fff",
        borderRadius: "15px",
        paddingTop: "10px",
      }}
    >
      <Header classes={classes} text="Coupons" />

      <Separator className="seperator" style={{ margin: 0, padding: 0 }} />
      <div className={classes.spaceBetween}>
        <Toolbar
          handleSearch={(searchValue) =>
            setQuery({ ...query, keyword: searchValue })
          }
          classes={classes}
          tableButtons={tableButtons} // this is table btn at left side
          buttonList={buttonListFiltered(buttonList)}
          buttonFunctions={{ ...tableFunctions }}
          themeColor={theme?.palette?.primary?.main}
          setQuery={setQuery}
          query={query}
          downloadCsv={downloadCsv}
          viewColumn={viewColumn}
        >
          {/* pass the children */}
        </Toolbar>
        <FluentProvider theme={teamsLightTheme}>
          <Stack
            style={{
              marginTop: "2rem",
              boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "5px",
              // overflow: "auto",
            }}
            className="tablegrid"
          >
            <TableComponent
              items={services.tableData?.result || []}
              columns={columns}
              multiselect={true} // default will be single whereas developer can move to multiple selections
              viewColumn={viewColumn}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              updateRecord={clickRecordAction}
              rowAction={handlerowAction}
              loading={services?.loading && services?.type === "query"}
              handleBulkSelection={handleBulkSelection}
            />
          </Stack>
          <Pagination
            query={query}
            setQuery={setQuery}
            tableData={services?.tableData}
          />
          <DeleteComponent
            isOpen={
              openForm?.isDialogOpen && openForm?.divType === "deleteDialog"
            }
            resetDialog={resetForm}
            handleDelete={() =>
              services?.handleDeleteRecord(openForm?.rowDetails)
            }
          />
          {openForm?.isOpen && (
            <PanelConfirmation
              isOpen={openForm?.isOpen}
              width={openForm?.width}
              hasCloseButton={openForm?.hasCloseButton}
              dismissPanel={resetForm}
              onRenderFooterContent={() => (
                <OnRenderFooterContent
                  field1={{
                    text: openForm?.divType === "column" ? "Reset" : "Save",
                    handle: () =>
                      setOpenForm({
                        ...openForm,
                        isSaveForm: true,
                      }),
                  }}
                  field2={{
                    text: openForm?.divType === "filter" ? "Reset" : "Cancel",
                    handle: openForm?.divType === "filter" ? resetQuery : resetForm,
                  }}
                />
              )}
            >
              {openForm?.isOpen &&
                (openForm?.divType === "add" ||
                  openForm?.divType === "edit" ||
                  openForm?.divType === "view") && (
                  <AEVForm
                    services={services}
                    openForm={openForm}
                    setOpenForm={setOpenForm}
                    classes={classes}
                    recordId={recordId}
                    resetRecords={resetRecords}
                  />
                )}
              {openForm?.divType === "filter" && (
                <Filter
                  query={query}
                  setQuery={setQuery}
                  openForm={openForm}
                  resetForm={resetForm}
                />
              )}
              {openForm?.divType === "column" && (
                <ViewColumn
                  filteredColumn={viewColumn}
                  UpdateFilter={updateColumn}
                  openForm={openForm}
                  resetColumns={resetColumns}
                />
              )}
            </PanelConfirmation>
          )}
        </FluentProvider>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Index)
);
