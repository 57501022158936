import { Stack, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React, { useState } from "react";
import avatarIcon from "../../../../assets/images/avatar_default.jpg";
import { Separator } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import LocalStorage from "../../../../config/LocalStorage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "4rem",
    color: "rgb(33, 43, 54)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "none",
    backgroundImage: "none",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: "1 0 auto",
    position: "relative",
    // zIndex: "1200",
    top: "0px",
    outline: "0px",
    left: "0px",
    borderRight: "1px dashed rgba(145, 158, 171, 0.24)",
    // width: "280px",
    backgroundColor: "rgb(249, 250, 251)",
    height: "100%",
  },
  profile: {
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    userSelect: "none",
    objectFit: "cover",
  },
  profileText: {
    margin: "0px",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    fontFamily: '"Public Sans", sans-serif',
    color: "rgb(33, 43, 54)",
  },
  profileView: {
    display: "flex",
    flexDirection: "row",
    WebkitBoxAlign: "center",
    alignItems: "center",
    padding: "16px 20px",
    borderRadius: "9px",
    backgroundColor: "rgba(145, 158, 171, 0.12)",
    gap: "1rem",
  },
  profilewrap: {
    margin: "20px",
    marginBottom: 0,
  },
  menuwrap: {
    margin: "1rem 8px",
    gap: ".4rem",
  },
  menuRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
    WebkitTapHighlightColor: "transparent",
    backgroundColor: "transparent",
    outline: "0px",
    border: "0px",
    // margin: "0px",
    padding: "8px 12px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    display: "flex",
    flexDirection: "row",
    WebkitBoxFlex: "1",
    // flexGrow: "1",
    // WebkitBoxPack: "start",
    justifyContent: "flex-start",
    // WebkitBoxAlign: "center",
    alignItems: "center",
    textDecoration: "none",
    // minWidth: "0px",
    boxSizing: "border-box",
    textAlign: "left",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    fontFamily: '"Public Sans", sans-serif',
    fontWeight: "400",
    height: "40px",
    position: "relative",
    textTransform: "capitalize",
    color: "rgb(99, 115, 129)",
    borderRadius: "4px",
    color: "#268581",
    fontWeight: "700",
    position: "relative",
  },
  menuLeft: {
    position: "absolute",
    left: 0,
    width: "2.88px",
    height: "24px",
    backgroundColor: "#DEEEF5",
    borderRadius: "3px",
  },
  menuicon: {
    // minWidth: "56px",
    flexShrink: "0",
    width: "22px",
    height: "22px",
    color: "inherit",
    display: "flex",
    WebkitBoxAlign: "center",
    alignItems: "center",
    WebkitBoxPack: "center",
    justifyContent: "center",
  },
}));

const LeftDrawer = ({
  isMobile,
  menus,
  translate,
  expanded,
  setExpanded,
  handleExpand,
  allowedMenus,
}) => {
  const history = useHistory();
  const path = history.location.pathname;
  const classes = useStyles();
  const theme = useTheme();

  // const [selected, setSelected] = useState(1);

  const [expandedMenus, setExpandedMenus] = React.useState([]);
  const [expandedMasterData, setExpandedMasterData] = useState(false);

  const handleClick = (index) => {
    // setSelected(index);
    setExpandedMasterData(false);
    if (expandedMenus.includes(index)) {
      setExpandedMenus((prevExpandedMenus) =>
        prevExpandedMenus.filter((item) => item !== index)
      );
    } else {
      setExpandedMenus((prevExpandedMenus) => [...prevExpandedMenus, index]);
    }
    if (menus[index]?.display === "sideNavMenu.masterData") {
      setExpandedMasterData((prevState) => !prevState);
    }
  };

  const isMasterDataMenu = path.includes("sideNavMenu.masterData");

  return (
    <Stack className={`${classes.root} scroll__remove`}>
      <Stack>
        <Stack className={classes.profilewrap}>
          <Stack
            className={classes.profileView}
            style={{
              background: isMobile && "transparent",
              justifyContent: isMobile && "center",
            }}
          >
            <img
              src={LocalStorage?.userDetails?.profileImage || avatarIcon}
              className={classes.profile}
              alt="profileImage"
            />
            <Typography
              style={{ display: isMobile && "none", fontFamily: "Segoe UI" }}
              variant="subtitle1"
              className={classes.profileText}
            >
              {LocalStorage?.userDetails?.userName}
            </Typography>
          </Stack>
        </Stack>
        <Separator className="seperator" style={{ margin: 0, padding: 0 }} />
      </Stack>

      <Stack
        className={classes.menuwrap}
        style={{ alignItems: isMobile && "center" }}
      >
        {menus?.map((value, key) => {
          if (!allowedMenus?.includes(value?.permissionLevel)) return;
          const isMasterData = value?.display === "sideNavMenu.masterData";
          const isMasterDataSubMenu =
            isMasterDataMenu && path.includes(value.link);
          return (
            <>
              <React.Fragment key={key}>
                <Stack
                  onClick={() => {
                    handleClick(key); // Pass the correct index (key) here
                    history?.push(value?.link);
                    handleExpand();
                    handleClick(key);
                  }}
                  className={classes.menuRoot}
                  key={key}
                  style={{
                    backgroundColor: value?.link === path && "#268581",
                    color: value?.link === path ? "#fff" : "#000",
                    alignItems: isMobile && "center",
                  }}
                >
                  <Stack
                    className={classes.menuLeft}
                    style={{ visibility: value?.link !== path && "hidden" }}
                  />
                  {/* {value?.link === path? value?.filledIcon : value?.regularIcon} */}
                  {isMasterData &&
                    (expandedMasterData
                      ? value?.filledIcon
                      : value?.regularIcon)}
                  {!isMasterData &&
                    (value?.link === path
                      ? value?.filledIcon
                      : value?.regularIcon)}

                  <Typography
                    variant="subtitle1"
                    className={classes.profileText}
                    style={{
                      fontWeight: value?.link === path && 600,
                      color: value?.link === path ? "#fff" : "#000",
                      display: isMobile && "none",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={() => {
                      history?.push(value?.link);
                      handleClick();
                      handleExpand();
                    }}
                  >
                    {translate(value?.display)}
                  </Typography>
                  {value?.display === "sideNavMenu.masterData" && (
                    <>
                      {expanded ? (
                        <ExpandLessIcon
                          style={{
                            marginLeft: "auto",
                            display: isMobile ? "none" : "",
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          style={{
                            marginLeft: "auto",
                            display: isMobile ? "none" : "",
                          }}
                        />
                      )}
                    </>
                  )}

                  {value?.display === "sideNavMenu.security" && (
                    <>
                      {expanded ? (
                        <ExpandLessIcon
                          style={{
                            marginLeft: "auto",
                            display: isMobile ? "none" : "",
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          style={{
                            marginLeft: "auto",
                            display: isMobile ? "none" : "",
                          }}
                        />
                      )}
                    </>
                  )}
                </Stack>

                {value?.subMenu?.map((itemValue, subIndex) => {
                  if (!allowedMenus?.includes(itemValue?.permissionLevel))
                    return;

                  return (
                    <>
                      <React.Fragment key={subIndex}>
                        {expandedMenus.includes(key) && (
                          <Stack
                            onClick={() => {
                              setExpandedMasterData(true);
                              history?.push(itemValue?.link);
                            }}
                            className={classes.menuRoot}
                            key={key}
                            style={{
                              backgroundColor:
                                itemValue?.link === path && "#F1F1F1",
                              alignItems: isMobile && "center",
                              marginLeft: isMobile ? "" : "1rem",
                              // display: click === false ? "none" : "",
                            }}
                          >
                            {itemValue?.link === path
                              ? itemValue?.filledIcon
                              : itemValue?.regularIcon}
                            <Typography
                              variant="subtitle1"
                              className={classes.profileText}
                              style={{
                                fontWeight: itemValue?.link === path && 600,
                                color: itemValue?.link === path && "#DB2D2E",
                                display: isMobile && "none",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              {translate(itemValue?.display)}
                            </Typography>
                          </Stack>
                        )}
                      </React.Fragment>
                    </>
                  );
                })}
              </React.Fragment>
            </>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default LeftDrawer;
