import React from "react";
import useAevForm from "../hooks/useAevForm";
import PlanDetails from "./PlanDetails";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import { useTheme } from "@mui/styles";
import { Stack } from "@fluentui/react";
import Typography from "../../../../../components/Text/Typography";
export const DataContext = React.createContext();

const AEVForm = ({ openForm, setOpenForm, classes, services,recordId,resetRecords}) => {
  const AevFormData = useAevForm({
    openForm,
    services,
    resetRecords
  });

  const theme = useTheme();

  return (
    <DataContext.Provider
      value={{
        theme,
        classes,
        ...AevFormData,
        openForm,
        recordId
      }}
    >
      <FluentProvider theme={teamsLightTheme}  >
      <Stack style={{ paddingTop: "25px", }}>
            <Typography
              variant="title"
              style={{
                color: "#323130",
                fontFamily: "Segoe UI",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              {openForm?.divType === "add" ? "Create Plan" : "Edit Plan"}
            </Typography>
          </Stack>
        <PlanDetails
          openForm={openForm}
          setOpenForm={setOpenForm}
          services={services}
          recordId={recordId}
        />
      </FluentProvider>
    </DataContext.Provider>
  );
};

export default AEVForm;
