import React, { useEffect, useMemo, useState } from "react";
import apiFetchRequest from "../../../../../utils/ApiFetchRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import images from "../../../../../images";
import useAlert from "../../../../../hooks/useAlert";
import utilController from "../../../../../utils/Utilcontroller";
import APIRequest from "../../../../../utils/APIRequest";

const form = {
  planName: "",
  validityAmount:Number,
  planAmount: Number,
  discountInPercent:Number
};



const useAevForm = ({ openForm, services }) => {
  const { publishNotification } = useAlert();
  const [userForm, setUserForm] = useState(form);


  // checks the total days amnd fixes for the vendor to not add more than that days



  React.useEffect(() => {
    // this is the send to server function

    if (openForm?.isSaveForm) {
      const userFormCopy = { ...userForm };
     
      const data = {
        ...userForm,
      
      };
      let isEdit = false;
      // if the row details is found then update it
      if (openForm?.divType === "edit") {
        data["recordId"] = openForm?.rowDetails;
        isEdit = true;
      }
      // send to server  api
      services?.sendToServer(data, isEdit);
    }
  }, [openForm]);

  const handleUpdateStatus = (userForm) => {
    const data = {
      ...userForm,
    
    };
    data["recordId"] = openForm?.rowDetails;
    let isEdit = true;

    services?.sendToServer(data, isEdit);
  };

  useEffect(() => {
    // gettting details of row
    // fetch the details if row details found
    if (
      openForm?.rowDetails &&
      (openForm?.divType === "edit" || openForm?.divType === "view")
    ) {
      services?.getEditTable(
        openForm?.rowDetails,
        setUserForm,
       
      );
    }
  }, []);



 



  return {
    userForm,
    setUserForm,
  handleUpdateStatus,
  services
  };
};

export default useAevForm;
