import {
  Avatar,
  TableCellLayout,
  createTableColumn,
} from "@fluentui/react-components";
import * as React from "react";

import { MoreVertical24Regular } from "@fluentui/react-icons";
import { Popover } from "../../../../components/popover/Index";

import {
  Accessibility32Regular,
  ChevronDown24Filled,
  Edit24Regular,
  Delete32Regular,
  ConvertRange24Regular,
  Edit32Regular,
} from "@fluentui/react-icons";
import utilController from "../../../../utils/Utilcontroller";

const useTableHeader = (setOpenForm, openForm, services) => {
  const columns = [
    {
      columnId: "tarifId", // this is the unique id for a column
      fieldName: "Tariff ID", // field name visible on header
      minWidth: 200,
      primaryKey: true, // this is the primary one which cant be disabled in column option
      renderCell: (item) => (
        // what should be rendered on the cell
        <TableCellLayout
          style={{ textTransform: "capitalize", color: "#323130" }}
          truncate
        >
          {item?.tariffId}
        </TableCellLayout>
      ),
    },
    {
      columnId: "location", // this is the unique id for a column
      fieldName: "Location",
      minWidth: 310,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {item?.locationDetails?.fragmentedAddress}
        </TableCellLayout>
      ),
    },

    // {
    //   columnId: "userName", // this is the unique id for a column
    //   fieldName: "User Name",
    //   minWidth: 200,
    //   renderCell: (item) => (
    //     <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
    //       {item?.fname} {item?.lname}
    //     </TableCellLayout>
    //   ),
    // },

    // {
    //   columnId: "cityCenter", // this is the unique id for a column
    //   fieldName: "City Center",
    //   minWidth: 220,
    //   renderCell: (item) => (
    //     <TableCellLayout
    //       truncate
    //       style={{ textTransform: "capitalize", textAlign: "end" }}
    //     >
    //       {item?.cityCenter}
    //     </TableCellLayout>
    //   ),
    // },
    // {
    //   columnId: "driverName", // this is the unique id for a column
    //   fieldName: "Driver Name",
    //   minWidth: 200,
    //   renderCell: (item) => (
    //     <TableCellLayout truncate>{item?.email}</TableCellLayout>
    //   ),
    // },
    // {
    //   columnId: "driverNo", // this is the unique id for a column
    //   fieldName: "Driver Number",
    //   minWidth: 200,
    //   renderCell: (item) => (
    //     <TableCellLayout truncate>{item?.email}</TableCellLayout>
    //   ),
    // },
    // {
    //   columnId: "emailId", // this is the unique id for a column
    //   fieldName: "Booking Status",
    //   minWidth: 200,
    //   renderCell: (item) => (
    //     <TableCellLayout
    //       truncate
    //       style={{
    //         color:
    //           item?.status === "pending"
    //             ? "#FF8D00"
    //             : item?.status === "accepted"
    //             ? "#27CA4B"
    //             : item?.status === "rejected"
    //             ? "#F32828"
    //             : "", // You can set a default color if none of the conditions match
    //         textTransform: "capitalize",
    //       }}
    //     >
    //       {item?.status}
    //     </TableCellLayout>
    //   ),
    // },

    {
      columnId: "createdAt", // this is the unique id for a column
      fieldName: "Created On",
      minWidth: 220,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.createdAt)}
        </TableCellLayout>
      ),
    },
    {
      columnId: "updatedat", // this is the unique id for a column
      fieldName: "Updated On",
      minWidth: 220,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.updatedAt)}
        </TableCellLayout>
      ),
    },
  ];
  return columns;
};

export default useTableHeader;
