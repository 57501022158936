import { TableCellLayout } from "@fluentui/react-components";
import * as React from "react";
import utilController from "../../../../utils/Utilcontroller";

const useTableHeader = (setOpenForm, openForm, services) => {
  const columns = [
    {
      columnId: "bookingId", // this is the unique id for a column
      fieldName: "Booking ID", // field name visible on header
      minWidth: 170,
      primaryKey: true, // this is the primary one which cant be disabled in column option
      renderCell: (item) => (
        // what should be rendered on the cell
        <TableCellLayout
          style={{ textTransform: "capitalize", color: "#323130" }}
          truncate
        >
          {item?.bookingId}
        </TableCellLayout>
      ),
    },
    {
      columnId: "date&time", // this is the unique id for a column
      fieldName: "Date & Time", // field name visible on header
      minWidth: 200,
      primaryKey: true, // this is the primary one which cant be disabled in column option
      renderCell: (item) => (
        // what should be rendered on the cell
        <TableCellLayout
          style={{ textTransform: "capitalize", color: "#323130" }}
          truncate
        >
          {item?.pickupDateTime === 0
            ? utilController.getFormattedDateAndTimeFromEpoch(item?.createdAt)
            : utilController.getFormattedDateAndTimeFromEpoch(
                item?.pickupDateTime
              )}
        </TableCellLayout>
      ),
    },
    {
      columnId: "userName", // this is the unique id for a column
      fieldName: "Customer Name",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {/* {item?.userId?.fname}
          {item?.userId?.lname} */}
          {`${item?.userId?.fname + `  ` + item?.userId?.lname}`}
        </TableCellLayout>
      ),
    },

    {
      columnId: "mobileNo", // this is the unique id for a column
      fieldName: "Mobile Number",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout
          truncate
          style={{ textTransform: "capitalize", textAlign: "end" }}
        >
          {item?.userId?.mobileNo}
        </TableCellLayout>
      ),
    },

    {
      columnId: "bookingStatus", // this is the unique id for a column
      fieldName: "Booking Status",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout
          truncate
          style={{
            color:
              item?.status === "accepted"
                ? "#FF8D00"
                : item?.status === "completed"
                ? "#16A135"
                : item?.status === "cancelled"
                ? "#FF0000"
                : item?.status === "pending"
                ? "orange"
                : item?.status === "started"
                ? "#8C14EA"
                : item?.status === "outForPickup"
                ? "#FF8D00"
                : item?.status === "pending"
                ? "orange"
                : item?.status === "enquiry"
                ? "#868686"
                : "",
            textTransform: "capitalize",
          }}
        >
          {item?.status === "pending" ? "Ongoing" : item?.status}
        </TableCellLayout>
      ),
    },
    {
      columnId: "driverName",
      fieldName: "Driver Name",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout truncate>
          {`${item?.driverId?.fname ?? ""}` +
            " " +
            `${item?.driverId?.lname ?? ""}`}
          {/* {item?.driverId?.fname} {item?.driverId?.lname} */}
        </TableCellLayout>
      ),
    },
    {
      columnId: "driverNo",
      fieldName: "Driver Number",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout truncate>{item?.driverId?.mobileNo}</TableCellLayout>
      ),
    },
  ];
  return columns;
};

export default useTableHeader;
