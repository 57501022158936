import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnSizingOptions,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
  PresenceBadgeStatus,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useArrowNavigationGroup,
  TableColumnId,
  useFocusFinders,
  TableSelectionCell,
  useTableSelection,
  useTableSort,
} from "@fluentui/react-components";
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import {
  DocumentPdfRegular,
  DocumentRegular,
  EditRegular,
  FolderRegular,
  OpenRegular,
  PeopleRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import useTable from "./useTable";
import { Stack } from "@fluentui/react";

const TableComponent = (props) => {
  const {
    items,
    columns: columnsDef,
    multiselect,
    selectedRows,
    setSelectedRows,
    updateRecord,
    rowAction,
    viewColumn,
    downloadCsv,
    loading,
    handleBulkSelection,
  } = props;

  const [allRowsSelected, setAllRowsSelected] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [hover, setHover] = React.useState(false);
  const { convertColumn, columnSizingOptions } = useTable(
    columnsDef,
    setColumns,
    viewColumn
  );

  const { columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns,
      items,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })]
  );

  const {
    selection: {
      // allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSelection({
        selectionMode: multiselect ? "multiselect" : "single",
        defaultSelectedItems: new Set([]),
        selectedItems: selectedRows,
        onSelectionChange: (e, data) => setSelectedRows(data.selectedItems),
      }),
    ]
  );

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: "file", sortDirection: "ascending" },
      }),
    ]
  );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const rows = sort(
    getRows((row) => {
      const selected = isRowSelected(row.rowId);
      return {
        ...row,
        onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === " ") {
            e.preventDefault();
            toggleRow(e, row.rowId);
          }
        },
        selected,
        appearance: selected ? ("brand" as const) : ("none" as const),
      };
    })
  );

  const toggleAllKeydown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === " ") {
        toggleAllRows(e);
        e.preventDefault();
      }
    },
    [toggleAllRows]
  );
  const keyboardNavAttr = useArrowNavigationGroup({
    axis: "grid",
  });

  const { findFirstFocusable } = useFocusFinders();

  const refMap = React.useRef<Record<string, HTMLElement | null>>({});

  // This will focus on the correct table header cell when the keyboard mode is turned off
  const onKeyboardModeChange = React.useCallback(
    (columnId: TableColumnId, isKeyboardMode: boolean) => {
      const element = refMap.current[columnId];
      if (!isKeyboardMode && element) {
        findFirstFocusable(element)?.focus();
      }
    },
    [findFirstFocusable]
  );

  // downloadCsv(columns, items);
  return (
    <Table
      sortable
      aria-label="Table with sort"
      ref={tableRef}
      {...keyboardNavAttr}
      style={{ overflow: "auto" }}
    >
      <TableHeader
        style={{
          borderLeft: "4px solid #f7f9fc",
          outline: "none",
          margin: ".5rem 0",
          height: "40px",
        }}
      >
        <TableRow
          style={{
            borderBottom: "1px solid #f7f9fc",
            backgroundColor: "#268581",
            
          }}
          >
          {multiselect && (
            <TableSelectionCell
              checked={
                allRowsSelected ? true : someRowsSelected ? "mixed" : false
              }
              style={{
                color: allRowsSelected ? "red" : "yellow",
                borderColor: "#fff",
              }}
              onClick={(e) => {
                toggleAllRows(e);
                setAllRowsSelected(!allRowsSelected);
                const response = getRows((rows) => rows?.item);

                handleBulkSelection(response, !allRowsSelected);
              }}
              // onKeyDown={toggleAllKeydown}
              checkboxIndicator={{
                "aria-label": "Select all rows ",
                style: { color: allRowsSelected ? "red" : "yellow" },
              }}
            />
          )}
          {/* columns are coming from usetable hook which converts everything */}
          {columns.map((column) => (
            <Menu openOnContext key={column.columnId}>
              <MenuTrigger>
                <TableHeaderCell
                  style={{ textTransform: "capitalize", fontWeight: 600 }}
                  key={column.columnId}
                  ref={(el) => (refMap.current[column.columnId] = el)}
                  {...columnSizing_unstable.getTableHeaderCellProps(
                    column.columnId
                  )}
                  {...headerSortProps(column.columnId)}
                >
                  {column.renderHeaderCell()}
                </TableHeaderCell>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    onClick={columnSizing_unstable.enableKeyboardMode(
                      column.columnId,
                      onKeyboardModeChange
                    )}
                  >
                    Keyboard Column Resizing
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          ))}
        </TableRow>
      </TableHeader>
      {/* {true ? (
        <Stack style={{ width: "80vw", textAlign: "center" }}>
          no records found
        </Stack>
      ) : ( */}
      <TableBody style={{ position: "relative" }}>
        {loading?.isOpen ? (
          <Stack
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              top: 60,
            }}
          >
            <CircularProgress />
          </Stack>
        ) : rows?.length === 0 ? (
          <Stack
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              top: 60,
            }}
          >
            No Data Found
          </Stack>
        ) : (
          rows?.map(({ item, selected, onClick }, ind) => {
            return (
              <TableRow
              key={ind}
              aria-selected={selected}
              onDoubleClick={() => !multiselect && rowAction && rowAction(item)}
              onClick={() => {
                updateRecord(item, multiselect);
                return onClick();
              }}
              appearance={selected && "brand"}
              style={{
                borderLeft: selected ? "4px solid #268581" : "4px solid transparent",
                borderBottom: items.length - 1 === ind && "none",
                backgroundColor: ind % 2 === 0 ? "rgba(247, 247, 247, 0.97)" : "#EDEBE9",
              }}
              >
                <TableSelectionCell
                  checked={selected}
                  className={selected ? "red" : ""}
                  checkboxIndicator={{
                    "aria-label": "Select row",
                    style: { color: selected ? "#268581" : "yellow" },
                  }}
                />

                {columns?.map((value, ind) => {
                  return (
                    <TableCell
                      key={ind}
                      {...columnSizing_unstable.getTableCellProps(
                        value?.columnId
                      )}
                      style={{fontWeight: 600}}
                    >
                      {value?.renderCell(item)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        )}
      </TableBody>
      {/* )} */}
    </Table>
  );
};

export default TableComponent;
