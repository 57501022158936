import { Stack } from "@fluentui/react";
import {
  Combobox,
  Dropdown,
  Field,
  FluentProvider,
  Option,
  Persona,
  teamsLightTheme,
} from "@fluentui/react-components";
import useAlert from "../../../../../hooks/useAlert";
import APIRequest from "../../../../../utils/APIRequest.js";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import React, { useEffect, useState } from "react";
import Typography from "../../../../../components/Text/Typography";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { use } from "i18next";

const status = ["pending", "rejected", "approved"];

const Filter = ({ query, setQuery, openForm, resetForm }) => {
  const [stateList, setStateList] = useState([]);
  const [datesetting, setDateSetting] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const { publishNotification } = useAlert();


  const getStateList = async (keyword) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.fetchStates,
      JSON.stringify({ state: keyword, page: 0, pageSize: 100 })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        const data = response?.data?.result;
        data &&
          data?.map((value, i) => {
            setStateList([value?.state_name_english]);
          });
      }
    });
  };

  const [filter, setFilter] = useState({
    active: query?.active,
    startDate: query?.startDate,
    endDate: query?.endDate,
    state: query?.state,
  });

  useEffect(() => {
    if (openForm?.isSaveForm) {
      setQuery({ ...query, ...filter });
      resetForm();
    }
  }, [openForm]);

  useEffect(() => {
    if (filter?.state === "") return;
    getStateList(filter?.state || "");
  }, [filter?.state]);

  return (
    <FluentProvider theme={teamsLightTheme}>
      <Stack style={{ gap: "24px", paddingTop: "24px" }}>
        <Stack style={{}}>
          <Typography
            variant="title"
            style={{
              color: "#323130",
              fontFamily: "Segoe UI",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Filter
          </Typography>
        </Stack>
        <Field label="Active">
          <Dropdown
            className={` input__Style`}
            value={filter?.active ? "Yes" : "No"}
            size="large"
            style={{ textTransform: "capitalize" }}
            onOptionSelect={(e, data) => {
              setFilter({
                ...filter,
                active: data?.optionValue === "yes" ? true : false,
              });
            }}
          >
            <Option
              style={{ textTransform: "capitalize" }}
              text={true}
              value={"yes"}
            >
              Yes
            </Option>
            <Option
              style={{ textTransform: "capitalize" }}
              text={false}
              value={"no"}
            >
              No
            </Option>
          </Dropdown>
        </Field>
        <Field label="State">
          <Combobox
            className={` input__Style`}
            freeform={true}
            onInput={(e) => {
              setFilter({
                ...filter,
                state: e.target.value,
              });
            }}
            onOptionSelect={(e, data) => {
              setFilter({
                ...filter,
                state: data?.optionText,
              });
            }}
            size="large"
            value={filter?.state}
          >
            {stateList &&
              stateList?.map((value, i) => (
                <Option
                  style={{ textTransform: "capitalize" }}
                  key={i}
                  value={value}
                >
                  {value}
                </Option>
              ))}
          </Combobox>
        </Field>
        <Field label="Start Date">
        <DatePicker
                maxDate={new Date(filter?.endDate * 1000)}
                className={` input__Style`}
                size="large"
                value={filter?.startDate && new Date(filter?.startDate * 1000)}
                onSelectDate={(e) =>
                  setFilter({
                    ...filter,
                    startDate: new Date(e).setHours(12, 0, 0, 0) / 1000,
                  })
                }
                placeholder="Select Start Date..."
              />
        </Field>
        <Field label="End Date">
              <DatePicker
                minDate={new Date(filter?.startDate * 1000)}
                maxDate={new Date()}
                className={` input__Style`}
                size="large"
                value={new Date(filter?.endDate * 1000)}
                onSelectDate={(e) =>
                  setFilter({
                    ...filter,
                    endDate: new Date(e).setHours(23, 59, 0, 0) / 1000,
                  })
                }
                placeholder="Select End Date..."
              />
            </Field>
      </Stack>
    </FluentProvider>
  );
};

export default Filter;
