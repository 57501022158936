/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Stack, Typography } from "@mui/material";
import LocalStorage from "../../../../config/LocalStorage";
import { connect } from "react-redux";
import Toolbar from "../../../../components/EnhancedToolbar/Toolbar";
import useDownloadCSV from "../../../../hooks/useDownloadCSV";
import Header from "../../../../components/HeaderUi/Index";
import { useStyles } from "./styles/style";
import useServices from "./hooks/useServices";
import useTableHeader, { columns } from "./TableHeader";
import DashboardCards from "./components/DashboardCards";
import RevnueSection from "./components/RevnueSection";

import { useTheme } from "@mui/styles";
import { Separator } from "@fluentui/react";
import BarGraph from "./components/BarGraph";
import GraphFilters from "./components/GraphFilters";
import useAevForm from "./hooks/useAevForm";
import LineCharts from "./components/LineChart";
import GraphFilterBooking from "./components/GraphFilterBooking";
import LineChartBooking from "./components/LineChartBooking";

const queryBody = {
  active: true,
  userType: "all",
  page: 0,
  pageSize: 10,
  keyword: "",
  sortOrder: "false",
  sortField: "",
};

const form = {
  isOpen: false,
  isDialogOpen: false,
  title: "",
  divType: "", // like add,edit
  functionName: "",
  rowDetails: null,
  width: 800,
  hasCloseButton: true,
  isSaveForm: false,
  discription: "",
};

function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [openForm, setOpenForm] = useState(form); // this is where the form for add,edit
  const resetForm = () => {
    setOpenForm({ ...form });
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [recordId, setRecordId] = React.useState([]);

  const resetRecords = () => {
    setSelectedRows([]);
    setRecordId([]);
  };

  const Name = LocalStorage.userDetails.userName;

  const [query, setQuery] = useState(queryBody);
  const {
    dashboardFilter,
    setDashboardFilter,
    pieChartFilter,
    setPieChartFilter,
    setTotalBooking,
    totalBooking,
  } = useAevForm({});

  const services = useServices({
    query,
    resetForm,
    resetRecords,
    dashboardFilter,
    setDashboardFilter,
    pieChartFilter,
    setPieChartFilter,
    setTotalBooking,
  });

  return (
    <div className={classes.root}>
      <Header classes={classes} />
      <Grid container className={classes.spaceBetween}>
        <Grid item container xs={9} alignItems="center">
          <Typography className={classes.dashBoardTitle}>
            Hi <Typography className={classes.nameStyle}>{Name}</Typography>,
            <span style={{ marginLeft: "6px" }}>Welcome back</span>
          </Typography>
        </Grid>
        <Grid style={{ width: "100%" }}>
          <Typography className={classes.dashBoardSubtitle}>
            Let's take a look at some comprehensive statistics about the
            application.
          </Typography>
        </Grid>
        <Grid className={classes.gridTopCards}>
          <DashboardCards services={services?.dashboardCounts} />
        </Grid>
        <Grid container className={classes.graphGridLeft}>
          <div style={{ width: "100%" }}>
            <GraphFilters
              dashboardFilter={dashboardFilter}
              setDashboardFilter={setDashboardFilter}
            />
          </div>
          <LineCharts services={services} dashboardFilter={dashboardFilter} />
        </Grid>
        <Grid container className={classes.graphGridRight}>
          <RevnueSection
            theme={theme}
            pieChartFilter={pieChartFilter}
            setPieChartFilter={setPieChartFilter}
            services={services}
          />
        </Grid>
      </Grid>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          marginBottom: "100px",
        }}
      >
        <Grid container className={classes.graphBooking}>
          <div style={{ width: "100%", margin: "10px" }}>
            <GraphFilterBooking
              dashboardFilter={dashboardFilter}
              setDashboardFilter={setDashboardFilter}
              totalBooking={totalBooking}
            />
          </div>
          <LineChartBooking
            services={services}
            dashboardFilter={dashboardFilter}
          />
        </Grid>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Dashboard)
);
