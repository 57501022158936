import {
  DefaultButton,
  PrimaryButton,
  Separator,
  Stack,
} from "@fluentui/react";
import React from "react";
const OnRenderFooterContent = ({ field1, field2 }) => {
  const buttonStyles = {
    root: { marginRight: 8, border: "none", fontWeight: 600 },
    button: {
      background: "#268581",
      width: "81px",
      border: "none",
      color: "white",
      marginRight: 8,
      fontWeight: 600,
    },
  };

  return (
    <>
      {/* <Separator /> */}
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{
          padding: "0 24px",
          // background: "red",
        }}
      >
        {field2?.text && (
          <DefaultButton
            checked={true}
            // disabled={fieldValid === false ? true : false}
            styles={buttonStyles}
            onClick={field2?.handle}
          >
            {field2?.text}{" "}
          </DefaultButton>
        )}
        {field1?.text && (
          <PrimaryButton
            // disabled={fieldValid === false ? true : false}
            // styles={buttonStyles.button}
            style={{
              background: "#268581",
              width: "81px",
              border: "none",
              color: "white",
              fontWeight: 600,
              marginBottom:"8px"
            }}
            onClick={field1?.handle}
          >
            {field1?.text}
          </PrimaryButton>
        )}
      </Stack>
    </>
  );
};
export default OnRenderFooterContent;
