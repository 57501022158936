import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "../styles/style";
import Typography from "../../../../../components/Text/Typography";
import images from "../../../../../images";

const DashboardCards = (props) => {
  const { services } = props;
  const classes = useStyles();

  return (
    <>
      <Grid
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "96%",
         justifyContent: "space-between",
        }}
      >
        <Grid className={classes.card}>
          <div
            style={{
              color: "#000",
              width: "48px",
              height: "48px",
              backgroundColor: "#E4E4E4",
              borderRadius: "17px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img src={images.threeUser} alt="" srcset="" />
          </div>
          <Typography
            variant={"subHeading"}
            style={{
              color: "var(--Secondary, #64748B)",
              fontWeight: 400,
              marginTop: "16px",
            }}
          >
            Total users
          </Typography>
          <Typography
            variant={"mainTitle"}
            style={{
              color: "#000",

              fontWeight: 700,
            }}
          >
            {services?.totalCustomers}
          </Typography>
        </Grid>
        <Grid className={classes.card}>
          <div
            style={{
              color: "#000",
              width: "48px",
              height: "48px",
              backgroundColor: "#E4E4E4",
              borderRadius: "17px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img src={images.Bag} alt="" srcset="" />
          </div>
          <Typography
            variant={"subHeading"}
            style={{
              color: "var(--Secondary, #64748B)",
              fontWeight: 400,
              marginTop: "16px",
            }}
          >
            Total Bookings
          </Typography>
          <Typography
            variant={"mainTitle"}
            style={{
              color: "#000",

              fontWeight: 700,
            }}
          >
            {services?.totalBookings}
          </Typography>
        </Grid>
        <Grid className={classes.card}>
          <div
            style={{
              color: "#000",
              width: "48px",
              height: "48px",
              backgroundColor: "#E4E4E4",
              borderRadius: "17px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img src={images.Paper} alt="" srcset="" />
          </div>
          <Typography
            variant={"subHeading"}
            style={{
              color: "var(--Secondary, #64748B)",
              fontWeight: 400,
              marginTop: "16px",
            }}
          >
            Total Subscription
          </Typography>
          <Typography
            variant={"mainTitle"}
            style={{
              color: "#000",

              fontWeight: 700,
            }}
          >
            {services?.totalSubscription}
          </Typography>
        </Grid>
        <Grid className={classes.card}>
          <div
            style={{
              color: "#000",
              width: "48px",
              height: "48px",
              backgroundColor: "#E4E4E4",
              borderRadius: "17px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img src={images.Activity} alt="" srcset="" />
          </div>
          <Typography
            variant={"subHeading"}
            style={{
              color: "var(--Secondary, #64748B)",
              fontWeight: 400,
              marginTop: "16px",
            }}
          >
            Total Revenue
          </Typography>
          <Typography
            variant={"mainTitle"}
            style={{
              color: "#000",

              fontWeight: 700,
            }}
          >
            ₹{services?.totalSubscriptionAmount}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardCards;
