import { Text } from "@fluentui/react";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";

export const useStylesFromThemeFunction = makeStyles((theme) => ({
  root: {},
  mainTitle: {
    ...theme.mainTitle,
  },
  title: {
    ...theme.title,
    color: "#323130",
  },
  heading: {
    ...theme.heading,
    fontFamily: "Segoe UI",
  },
  subHeading: {
    ...theme.subHeading,
  },
  content: {
    // ...theme.content,
    fontSize: "12px",
  },
}));

const Typography = (props) => {
  const classes = useStylesFromThemeFunction();
  //accepts no wrap aregumnt if wrap is true then nowrap happens defaultly it is wrap
  //and also children which is to render
  const { nowrap, children, variant, style, className, onClick, cap } = props;
  return (
    <Text
      onClick={onClick}
      className={classes?.[variant]}
      nowrap={nowrap && true}
      style={{ ...style, textTransform: cap ? cap : "capitalize" }}
    >
      {children}
    </Text>
  );
};

Typography.propTypes = {
  style: PropTypes.object,
  nowrap: PropTypes.bool,
  children: PropTypes.element.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  cap: PropTypes.bool,
};

export default Typography;
