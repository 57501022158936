import React, { useState } from "react";
import useAevForm from "../hooks/useAevForm";
import { Stack } from "@fluentui/react";
import Typography from "../../../../../components/Text/Typography";
import { Grid } from "@mui/material";
import {
  Dropdown,
  Field,
  FluentProvider,
  Input,
  Option,
  Spinner,
  Textarea,
  teamsLightTheme,
} from "@fluentui/react-components";
import { useTheme } from "@mui/styles";
import PrimaryBtn from "../../../../../components/Button/PrimaryBtn";
import DetailsPage from "./DetailsPage/Index";
export const DataContext = React.createContext();

const AEVForm = ({ openForm, setOpenForm, classes, services }) => {
  //ui part of aevform
  const AevFormData = useAevForm({
    openForm,
    services,
  }); //logic part of aevform
  // const [isPreview, setIspreview] = useState(false);

  const theme = useTheme();

  return (
    <DataContext.Provider
      value={{
        theme,
        classes,
        openForm,
        setOpenForm,
        ...AevFormData,
      }}
    >
      <FluentProvider theme={teamsLightTheme}>
      <Stack style={{ paddingTop: "25px",paddingLeft:"12px" }}>
            <Typography
              variant="title"
              style={{
                color: "#323130",
                fontFamily: "Segoe UI",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              {openForm?.divType === "add" ? "Create Tariff" : "Edit Tariff"}
            </Typography>
          </Stack>
        {services?.loading && services?.loading?.type === "edit" ? (
          <Spinner />
        ) : (
          <DetailsPage />
        )}
      </FluentProvider>
    </DataContext.Provider>
  );
};

export default AEVForm;
