import { Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
export const useStyles = makeStyles((theme) => ({
  primary: {
    background: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "7px 24px",
    transition: "all 0.15s linear",
    border: "none",
    outline: "none",
    color: "white",
    fontWeight: 600,
    lineHeight: "20px",
    cursor: "pointer",
    fontSize: theme.fonts.subHeading,
    fontFamily: theme.fontFamily,
  },
}));

const PrimaryBtn = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { onClick, children, style, disabled, variant = "primary" } = props;
  return (
    <button
      disabled={disabled}
      type="button"
      style={{ ...style }}
      className={classes?.[variant]}
      onClick={() => onClick && onClick()}
    >
      {children}
    </button>
  );
};

export default PrimaryBtn;
