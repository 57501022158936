import {
  Combobox,
  Field,
  FluentProvider,
  Input,
  Option,
  teamsLightTheme,
} from "@fluentui/react-components";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import React, { useEffect } from "react";
import { useStyles } from "../styles/style";
import { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import Typography from "../../../../../components/Text/Typography";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import images from "../../../../../images";
import { DateRangePicker } from "materialui-daterange-picker";
import { Stack } from "@fluentui/react";

const COLORSOne = ["#667EFE", "#fff", "#fff"];
const COLORSTwo = ["#fff", "#FF8CEE", "#fff"];
const COLORSThree = ["#fff", "#fff", "#A3CB31"];
const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const COLORS = ["#667EFE", "#FF8CEE", "#A3CB31"];

const RevnueSection = (props) => {
  const { pieChartFilter, setPieChartFilter, theme, services } = props;

  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);
  const currentDate = new Date();
  const threeMonthsAgo = new Date(currentDate);
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
  const [dateRange, setDateRange] = React.useState({
    startDate: threeMonthsAgo,
    endDate: currentDate,
  });
  function dateToUnixTimestamp(date) {
    return Math.floor(date?.getTime() / 1000);
  }
  const startTimestamp = dateToUnixTimestamp(dateRange.startDate);
  const endTimestamp = dateToUnixTimestamp(dateRange.endDate);

  useEffect(() => {
    setPieChartFilter({
      ...pieChartFilter,
      startDate: startTimestamp,
      endDate: endTimestamp,
    });
  }, [startTimestamp, endTimestamp]);

  function formatDateRangePicker(date) {
    return date?.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  }

  function formatDate(unixTimestamp) {
    // Convert the Unix timestamp to a JavaScript Date object
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds by multiplying by 1000

    // Format the date as a string in your desired format
    const formattedDate = date; // You can customize the format here

    return formattedDate;
  }
  const handleStartDateChange = (date) => {
    if (date) {
      // Convert the selected date to Unix timestamp format (in seconds)
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setPieChartFilter({
        ...pieChartFilter,
        startDate: unixTimestamp,
      });
    } else {
      // Handle the case when no date is selected (optional)
      setPieChartFilter({
        ...pieChartFilter,
        startDate: "",
      });
    }
  };
  const handleEndDateChange = (date) => {
    if (date) {
      // Convert the selected date to Unix timestamp format (in seconds)
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setPieChartFilter({
        ...pieChartFilter,
        endDate: unixTimestamp,
      });
    } else {
      // Handle the case when no date is selected (optional)
      setPieChartFilter({
        ...pieChartFilter,
        endDate: "",
      });
    }
  };
  const classes = useStyles();
  const circleStyle = {
    filter:
      "drop-shadow(0px 8.817204475402832px 17.634408950805664px rgba(216, 218, 229, 0.80))",
  };
  const [places, setPlaces] = React.useState([]);
  const [placeId, setPlaceId] = React.useState("");

  const handleAddressChange = async (keyword) => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?keyword=${keyword}`
    ).then((res) => {
      setPlaces(res.data.result.predictions);
    });
  };

  const today = new Date(); // Get the current date
  const maxSelectableDate = new Date(today);
  maxSelectableDate.setDate(today.getDate() + 31);
  const onFormatDate = (date) => {
    return !date
      ? ""
      : `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  const value1 = parseFloat(services?.pieChartData[0]?.value) || 0;
  const value2 = parseFloat(services?.pieChartData[1]?.value) || 0;
  const value3 = parseFloat(services?.pieChartData[2]?.value) || 0;

  const total = value1 + value2 + value3;

  console.log("pieChartFilter----pieChartFilter", services?.pieChartData);
  return (
    <>
      <Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "5px",
            borderBottom: "1px solid #E6E8F0",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <Typography
              variant={"title"}
              style={{
                color: "#000",
                fontWeight: "700",
              }}
            >
              Users
            </Typography>
            <Typography
              variant={"content"}
              style={{
                color: "var(--dark-color-shade-1, #8F95B2)",
                fontWeight: "400",
              }}
            >
              Users that joined into portal
            </Typography>
          </div>
          <div style={{ width: "54%" }}>
            {/* <FluentProvider theme={teamsLightTheme}> */}
            <div
              style={{
                width: "200px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack horizontal verticalAlign="center">
                <FluentProvider theme={teamsLightTheme}>
                  <Combobox
                    style={{
                      background: "#FFF",
                      // border: "1px solid #E5E5E5",
                      border: "none",
                      height: "39px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "100px",
                      marginRight: "16px",
                    }}
                    onInput={(e) => handleAddressChange(e.target.value)}
                    className={`${classes.input} input__Style`}
                    onOptionSelect={(e, data) => {
                      const keyword = data?.optionValue?.toLowerCase();
                      setPieChartFilter({
                        ...pieChartFilter,
                        keyword: keyword === "all" ? "" : keyword,
                      });
                    }}
                    placeholder="Select Location"
                    // value={
                    //   (dashboardFilter?.keyword).charAt(0).toUpperCase() +
                    //   (dashboardFilter?.keyword).slice(1)
                    // }
                  >
                    <Option onClick={() => setPlaceId("")}>{"All"}</Option>
                    {places?.map((option) => (
                      <Option
                        key={option}
                        onClick={() => setPlaceId(option?.place_id)}
                      >
                        {option?.structured_formatting?.main_text}
                      </Option>
                    ))}
                  </Combobox>
                </FluentProvider>
                <div
                  style={{
                    width: "160px",
                    height: "42px",
                    // borderBottom: "1px solid #E6E8F0",
                    paddingBottom: "10px",
                    backgroundColor: "#F1F1F1",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <Grid item xs={4} style={{ position: "relative" }}>
                    <Stack
                      className={classes.actionButtons}
                      style={{
                        width: "160px",
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px",
                      }}
                      onClick={toggle}
                    >
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      >
                        <img src={images.calendar} width="24px" height="24px" />
                      </div>
                      <Typography
                        variant={"subHeading"}
                        style={{
                          color: "var(--greyscale-600, #718096)",
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        {formatDateRangePicker(dateRange.startDate)} -{" "}
                        {formatDateRangePicker(dateRange.endDate)}
                      </Typography>
                    </Stack>
                    <div style={{ position: "absolute", top: "0", right: "0" }}>
                      <DateRangePicker
                        open={open}
                        toggle={toggle}
                        onChange={(range) => setDateRange(range)}
                        initialDateRange={dateRange}
                      />
                    </div>
                  </Grid>
                </div>
              </Stack>
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // width: "100%",
                // alignItems: "baseline",
                justifyContent: "space-between",
                // borderBottom: "1px solid #E6E8F0",
                paddingBottom: "10px",
              }}
            > */}
            {/* <Grid item xs={6} style={{ marginRight: "10px" }}>
                  <DatePicker
                    firstWeekOfYear={1}
                    formatDate={onFormatDate}
                    showMonthPickerAsOverlay={true}
                    style={{ width: "100%", marginTop: "8px" }}
                    className={`${classes.input} input__Style`}
                    showWeekNumbers={false}
                    value={formatDate(pieChartFilter?.startDate)}
                    onSelectDate={handleStartDateChange}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginRight: "10px" }}>
                  <DatePicker
                    firstWeekOfYear={1}
                    formatDate={onFormatDate}
                    showMonthPickerAsOverlay={true}
                    style={{ width: "100%", marginTop: "8px" }}
                    className={`${classes.input} input__Style`}
                    value={formatDate(pieChartFilter?.endDate)}
                    minDate={formatDate(pieChartFilter?.startDate)} // Set minDate to the selected start date
                    onSelectDate={handleEndDateChange}
                  />
                </Grid> */}
            {/* </div> */}
            {/* </FluentProvider> */}
          </div>
        </div>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            width: "300px",
          }}
        >
          <div
            style={{
              width: "300px",
            }}
          >
            <PieChart
              width={300}
              height={300}
              // onMouseEnter={this.onPieEnter}
            >
              <Pie
                data={services?.pieChartData}
                cx={120}
                cy={200}
                innerRadius={45}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={10}
                dataKey="value"
              >
                {services?.pieChartData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <g transform={`translate(${120},${200})`} style={circleStyle}>
                <circle
                  cx={5}
                  cy={5}
                  r={36}
                  // stroke="#8884d8"
                  // strokeWidth="2"
                  fill="#FAFBFF"
                />
                <text
                  x={3}
                  y={6}
                  textAnchor="middle"
                  fontSize="18"
                  fill="#0049C6"
                  style={{ fontWeight: 800 }}
                >
                  {/* {services?.pieChartData[0]?.value +
                    services?.pieChartData[1]?.value +
                    services?.pieChartData[2]?.value} */}
                  {isNaN(total) ? "N/A" : total}
                </text>
                <text
                  x={5}
                  y={24}
                  textAnchor="middle"
                  fontSize="12"
                  fill="#0049C6"
                >
                  Total
                </text>
              </g>
            </PieChart>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "24px",
                marginTop: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: "#667EFE",
                  }}
                ></div>
                <Typography
                  variant={"content"}
                  style={{
                    fontWeight: 600,
                    color: "var(--Dark-color, #081735)",
                    marginLeft: "4px",
                  }}
                >
                  Customers
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: "#FF8CEE",
                  }}
                ></div>
                <Typography
                  variant={"content"}
                  style={{
                    fontWeight: 600,
                    color: "var(--Dark-color, #081735)",
                    marginLeft: "4px",
                  }}
                >
                  Drivers
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: "#A3CB31",
                  }}
                ></div>
                <Typography
                  variant={"content"}
                  style={{
                    fontWeight: 600,
                    color: "var(--Dark-color, #081735)",
                    marginLeft: "4px",
                  }}
                >
                  Admins
                </Typography>
              </div>
            </div>
          </div>
          {/* {console.log("services?.pieChartData", services?.pieChartData)} */}
          <Grid>
            <Grid
              item
              xs={6}
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PieChart width={100} height={100}>
                <Pie
                  data={services?.pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  innerRadius={15}
                  outerRadius={30}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {services?.pieChartData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSOne[index % COLORSOne.length]}
                      style={{
                        stroke: "#667EFE",
                        strokeWidth: 1,
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant={"subHeading"} style={{ fontWeight: 700 }}>
                  {/* {services?.pieChartData[0]?.value} */}
                  {services?.pieChartData?.map((data) => {
                    // console.log("services?.data", data);
                    if (data?.name === "user") {
                      return ` ${data?.value}`;
                    }
                  })}
                </Typography>
                <Typography variant={"content"} style={{ color: "#868686" }}>
                  Total Customers
                </Typography>
              </div>
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PieChart width={100} height={100}>
                <Pie
                  data={services?.pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  innerRadius={15}
                  outerRadius={30}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {services?.pieChartData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSTwo[index % COLORSTwo.length]}
                      style={{
                        stroke: "#FF8CEE",
                        strokeWidth: 1,
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant={"subHeading"} style={{ fontWeight: 700 }}>
                  {/* {services?.pieChartData[1]?.value} */}
                  {services?.pieChartData?.map((data) => {
                    // console.log("services?.data", data);
                    if (data?.name === "driver") {
                      return ` ${data?.value}`;
                    }
                  })}
                </Typography>
                <Typography variant={"content"} style={{ color: "#868686" }}>
                  Total Drivers
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                // marginTop: "70px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PieChart width={100} height={100}>
                <Pie
                  data={services?.pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  innerRadius={15}
                  outerRadius={30}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {services?.pieChartData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSThree[index % COLORSThree.length]}
                      style={{
                        stroke: "#A3CB31",
                        strokeWidth: 1,
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant={"subHeading"} style={{ fontWeight: 700 }}>
                  {/* {services?.pieChartData[2]?.value} */}
                  {services?.pieChartData?.map((data) => {
                    // console.log("services?.data", data);
                    if (data?.name === "admin") {
                      return ` ${data?.value}`;
                    }
                  })}
                </Typography>
                <Typography variant={"content"} style={{ color: "#868686" }}>
                  Total Admins
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RevnueSection;
