import { Separator } from "@fluentui/react";
import React, { useState, memo } from "react";
import { Filter24Regular, ColumnTriple24Regular } from "@fluentui/react-icons";
import { makeStyles } from "@fluentui/react-components";
import { Stack } from "@mui/material";
import { PeopleAdd24Regular } from "@fluentui/react-icons";
import ResponsiveTypography from "../Typography/Text";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { useStyles } from "../tableButtons/styles";

const iconStyleProps: FluentIconsProps = {
  primaryFill: "#268581",
  className: "iconClass",
  font: "14px",
};

function IconsRender({ classes, button, width, functions, color }) {
  const RegularButton = button.icons.regular;
  const FilledButton = button.icons.filled;
  const [filled, setFilled] = useState(true);

  iconStyleProps["primaryFill"] = color;

  return (
    <Stack
      onClick={() => button?.handler(functions)}
      onMouseOver={() => setFilled(false)}
      onMouseLeave={() => setFilled(true)}
      className={classes.iconWrap}
      style={{
        borderBottom: !filled
          ? "2.3px solid #268581"
          : "2.3px solid transparent",
        padding: "6px 8px",
        minWidth: width ? width : "90px",
        borderRadius: "2px",
        transition: "backgroundColor 5s",
      }}
    >
      {filled ? (
        <RegularButton {...iconStyleProps} />
      ) : (
        <FilledButton {...iconStyleProps} />
      )}
      <ResponsiveTypography
        fs="medium"
        style={{ fontWeight: filled ? 400 : 600, color: "black" }}
      >
        {button.label}
      </ResponsiveTypography>
    </Stack>
  );
}

const Toolbar = ({
  tableButtons,
  buttonList,
  buttonFunctions,
  children,
  themeColor,
  handleSearch,
}) => {
  const classes = useStyles();
  iconStyleProps["primaryFill"] = "#268581";

  return (
    <div>
      <Stack>
        <Stack className={classes.root}>
          <Stack className={classes.iconWraper}>
            {tableButtons?.map((button, index) => {
              if (!buttonList?.includes(button?.id)) return;
              return (
                <React.Fragment key={index}>
                  <IconsRender
                    classes={classes}
                    button={button}
                    functions={buttonFunctions}
                    color={"#268581"}
                  />
                </React.Fragment>
              );
            })}
          </Stack>
          <Stack
            className=""
            flexDirection="row"
            alignItems="center"
            gap=".8rem"
          >
            <Stack
              onClick={buttonFunctions?.viewFilter}
              flexDirection="row"
              alignItems="center"
              gap=".4rem"
              style={{ cursor: "pointer" }}
            >
              <Filter24Regular {...iconStyleProps} />
              <ResponsiveTypography
                fs="medium"
                style={{ fontWeight: 400, color: "black" }}
              >
                {"Filter"}
              </ResponsiveTypography>
            </Stack>
            <Stack width="200px">
              <SearchBox
                className="search-box-icon-red"
                placeholder="Search here"
                onChange={(e, newValue) => handleSearch(newValue)}
                iconProps={{ style: { color: "#268581" } }}
              />
            </Stack>
            <ColumnTriple24Regular
              onClick={() =>
                buttonFunctions?.editColumn && buttonFunctions?.editColumn()
              }
              style={{ cursor: "pointer" }}
              {...iconStyleProps}
            />
            {children}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default memo(Toolbar);
