import React, { useState } from "react";
import { useEffect } from "react";
import LocalStorage from "../../../../../config/LocalStorage";
import APIRequestDataTableQuery from "../../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import apiFetchRequest from "../../../../../utils/ApiFetchRequest";
import useAlert from "../../../../../hooks/useAlert";
import utilController from "../../../../../utils/Utilcontroller";

// this is api calls happen
const useServices = (props) => {
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  const [loading, setLoading] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { publishNotification } = useAlert();

  useEffect(() => {
    if (!props.query) return;
    tableQuery(props?.query);
    // publishNotification("Something went wrong ", "error");
  }, [props.query, LocalStorage.adminButtonPermission]);
  // table query body value handler
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "query" });
      if (
        LocalStorage.adminButtonPermission.find(function (element) {
          return element.button === "query" ? true : false;
        })
      ) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.fetchAllTariff,
          JSON.stringify(query)
        ).then((tableData) => {
          setTableData(tableData?.data);
          setLoading({ ...loading, isOpen: false, type: "" });
        });
      } else {
        // notification(props.t('toaster.tableRecordPermission'), 'error');
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");

      console.log(err);
    } finally {
    }
  };
  const sendToServer = async (userForm, isEdit) => {
    const fieldValidation = [];
    const formCopy = { ...userForm };
    const locationDetailsValidation = [];

    fieldValidation.push("foodAllowance");
    if (formCopy?.tariffType === "default") {
      locationDetailsValidation.push(
        "locationDetails.fragmentedAddress",

        "nightFare.price",
      );
    } else{
    locationDetailsValidation.push(
      "locationDetails.fragmentedAddress",
      "locationDetails.radius",
      "nightFare.price",
    );
    }

    fieldValidation.push(...locationDetailsValidation);

    const gettingNestedValue = (object, path) => {
      const properties = path.split(".");
      return properties.reduce((obj, prop) => {
        return obj && obj[prop];
      }, object);
    };

    const hasEmptyField = fieldValidation.some((field) => {
      const value = gettingNestedValue(formCopy, field);
      console.log("value", value);
      if (typeof value === "string") {
        return value.trim() === "";
      } else {
        return !value;
      }
    });

    console.log("hasEmptyField", hasEmptyField);

    const url = isEdit ? ConfigAPIURL.updateTariff : ConfigAPIURL.createTariff;
    try {
      if (hasEmptyField) {
        return publishNotification("Please fill all the fields", "error");
      }

      APIRequest.request("POST", url, JSON.stringify(userForm)).then(
        (response) => {
          if (response?.data?.responseCode === 109) {
            props.removeSelectedChecknox();
            publishNotification(
              `Successfully ${isEdit ? "Updated" : "created"} Item`,
              "success"
            );
            props.resetForm();
            tableQuery(props?.query);
          } else {
            props.removeSelectedChecknox();
            publishNotification(
              `Something went wrong while ${isEdit ? "Updating" : "Creating"} `,
              "error"
            );
          }
        }
      );
    } catch (err) {
      publishNotification("Something went wrong ", "error");
    }
  };
  const getEditTable = async (recordId, setUserForm) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "edit" });

      const response = await apiFetchRequest(
        "POST",
        ConfigAPIURL.tariffDetail,
        JSON.stringify({ recordId: recordId })
      );
      if (response) {
        setUserForm({
          tariffType: response?.tariffType,
          extraTime: {
            pricePerMinute: response?.extraTime?.pricePerMinute,
          },
          foodAllowance: response?.foodAllowance,
          locationDetails: {
            fragmentedAddress: response?.locationDetails?.fragmentedAddress,
            radius: response?.locationDetails?.radius,
            location: response?.locationDetails?.location,
          },
          nightFare: {
            price: response?.nightFare?.price,
          },
          outStation: {
            minRadius: response?.outStation?.minRadius,
            // notes: response?.outStation?.notes,
            oneWayNotes: response?.outStation?.oneWayNotes,
            roundTripNotes:response?.outStation?.roundTripNotes,
            timeInHours: response?.outStation?.timeInHours,
            oneWay: response?.outStation?.oneWay,
            roundTrip: response?.outStation?.roundTrip,
            onewayExtraTime: response?.outStation?.onewayExtraTime,
            roundTripExtraTime: response?.outStation?.roundTripExtraTime,
          },
          cityLimit: {
            maxRadius: response?.cityLimit?.maxRadius,
            minRadius: response?.cityLimit?.minRadius,
            // notes: response?.cityLimit?.notes,
            oneWayNotes: response?.cityLimit?.oneWayNotes,
            roundTripNotes:response?.cityLimit?.roundTripNotes,
            timeInHours: response?.cityLimit?.timeInHours,
            oneWay: response?.cityLimit?.oneWay,
            roundTrip: response?.cityLimit?.roundTrip,
            onewayExtraTime: response?.cityLimit?.onewayExtraTime,
            roundTripExtraTime: response?.cityLimit?.roundTripExtraTime,
          },
          outSkirt: {
            maxRadius: response?.outSkirt?.maxRadius,
            minRadius: response?.outSkirt?.minRadius,
            // notes: response?.outSkirt?.notes,
            oneWayNotes: response?.outSkirt?.oneWayNotes,
            roundTripNotes:response?.outSkirt?.roundTripNotes,
            timeInHours: response?.outSkirt?.timeInHours,
            oneWay: response?.outSkirt?.oneWay,
            roundTrip: response?.outSkirt?.roundTrip,
            onewayExtraTime: response?.outSkirt?.onewayExtraTime,
            roundTripExtraTime: response?.outSkirt?.roundTripExtraTime,
          },
        });
      } else {
        publishNotification("Something went wrong ", "error");

        //show the popup something went wrong
      }
    } catch (err) {
      publishNotification(
        "Something went wrong while recieving data ",
        err,
        "error"
      );
    } finally {
      setLoading({ ...loading, isOpen: false, type: "" });
    }
  };

  const handleDeleteRecord = async (recordIds) => {
    try {
      const deletionPromises = recordIds.map(async (record) => {
        const payload = {
          recordId: record?._id,
          active: false,
        };
  
        const response = await APIRequest.request(
          "POST",
          ConfigAPIURL.updateTariff,
          JSON.stringify(payload)
        );
  
        return response?.data?.responseCode;
      });
  
      const responseCodes = await Promise.all(deletionPromises);
  
      if (responseCodes.every((code) => code === 109)) {
        publishNotification("Successfully deleted the Items", "success");
        tableQuery(props?.query);
        props?.resetForm();
        props?.resetRecords();
      } else {
        publishNotification("Some records could not be deleted", "warning");
      }
    } catch (err) {
      publishNotification("Something went wrong", "error");
    }
  };
  
  return {
    tableData,
    setTableData,
    loading,
    sendToServer,
    getEditTable,
    handleDeleteRecord,
  };
};

export default useServices;
