import React, { useState, useMemo } from "react";
import { DialogType, DialogFooter, Dialog } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { hiddenContentStyle, mergeStyles } from "@fluentui/react/lib/Styling";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { ContextualMenu } from "@fluentui/react/lib/ContextualMenu";
import { useId, useBoolean } from "@fluentui/react-hooks";
import PropTypes from "prop-types";

const dialogStyles = {
  main: {
    background: "white",
    borderRadius: "10px",
    padding: 0,
    margin: 0,
    boxShadow:
      "0px 6.400000095367432px 14.399999618530273px 0px rgba(0, 0, 0, 0.13), 0px 1.2000000476837158px 3.5999999046325684px 0px rgba(0, 0, 0, 0.10)",
  },
};
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);
const dialogContentProps = {
  type: DialogType.normal,
  title: "Missing Subject",
  closeButtonAriaLabel: "Close",
  subText: "Do you want to send this message without a subject?",
};

const DialogV8 = ({ isOpen, reset, children, width, parent }) => {
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");

  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId]
  );

  return (
    <>
      <Dialog
        styles={dialogStyles}
        minWidth={width}
        hidden={isOpen}
        onDismiss={reset}
        // dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        {parent}
        {children}
        {/* <DialogFooter></DialogFooter> */}
      </Dialog>
    </>
  );
};

DialogV8.propTypes = {
  isOpen: PropTypes.bool,
  reset: PropTypes.func,
  children: PropTypes.element.isRequired,
  width: PropTypes.string,
};

export default DialogV8;
