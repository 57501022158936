import React, { useState, useEffect } from "react";
import LoginPage from "./LoginPage";
import APIRequest from "../../../utils/APIRequest";
import Toaster from "../../../utils/Toaster";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import LocalStorage from "../../../config/LocalStorage";
import useAlert from "../../../hooks/useAlert";

function Login(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [disableOtpBtn, setDisableOtpBtn] = useState(false);
  const [slideInDialog, setSlideInDialog] = useState(false);
  const [resetTimer, setResetTimer] = useState(false); // [1
  const [otp, setOTP] = useState("");
  const [twoFactAut, setTowFactAuth] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFields, setPasswordFields] = useState(false);
  const [blockEntry, setBlockEntry] = useState(false); //
  const [timer, setTimer] = useState(60);
  const [forgotToken, setForgotToken] = useState("");
  // const [token, setToken] = useState(0);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const { publishNotification } = useAlert();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  useEffect(() => {
    isLogin();
  }, []);

  const handlePasswordChange = () => {
    if (userName === "") {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please enter your mobile number",
      });
      return;
    }
    setSlideInDialog(true);
  };

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
  };

  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "GET",
        ConfigAPIURL.forgotPassword + "?username=" + userName,
        ""
      ).then((response) => {
        setSlideInDialog(false);
        if (response.code === 100 && response?.data?.responseCode === 101) {
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "New password is generated and sent over the email",
          });
        } else if (
          response?.code === 100 &&
          response?.data?.responseCode === 116
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Exceeded password attempt",
          });
        } else if (
          response?.code === 100 &&
          response?.data?.responseCode === 115
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User login email is not verified",
          });
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User email id not found / wrong email id /deactivated",
          });
        }
      });
    } else {
      setSlideInDialog(false);
    }
  };

  const submitMobileLogin = () => {
    if (userName === "") {
      return publishNotification("Enter your Mobile Number", "error");
    }
    if (password === "") {
      return publishNotification("Enter your Password", "error");
    }
    APIRequest.request(
      "POST",
      ConfigAPIURL.accountLogin,
      JSON.stringify({
        email: userName,
        password: password,
      })
    ).then((response) => {
      //redirect based on user type
      if (
        response?.data?.responseCode === 108 ||
        response?.data?.responseCode === 103
      ) {
        publishNotification("Wrong Credentials", "error");
      } else if (response?.data?.responseCode === 116) {
        publishNotification("Exceeded Password attempt", "error");
      } else if (response?.data?.responseCode === 122) {
        const newToken = response.data.token;
        localStorage.setItem("token", newToken);

        publishNotification("OTP sent to your mobile number", "success");
        setTowFactAuth(true);
        // setToken(response.data.token);
      } else if (response.data.responseCode === 109) {
      } else if (response.data.responseCode === 104) {
        publishNotification(
          "Password is not matching, please check your password",
          "error"
        );
      }
    });
  };

  const verifyOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.verifyOtp,
      JSON.stringify({
        otpVal: otp,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response?.data?.responseCode !== undefined &&
          response?.data?.responseCode !== null &&
          response?.data?.responseCode === 109
        ) {
          const newToken = response.data.token;
          localStorage.setItem("token", newToken);
          setPasswordFields(false);
          isLogin();
        } else if (response?.data?.responseCode === 118) {
          publishNotification(
            "OOPS! You entered the wrong OTP, Please click on resend to get new OTP",
            "error",
            "error"
          );
          setBlockEntry(true);
        } else if (response?.data?.responseCode === 112) {
          publishNotification("You don't have permission to login", "error");
        }
      } else {
        publishNotification("Something went wrong", "error");
      }
    });
  };

  const isLogin = () => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response?.data !== undefined && response?.data !== null) {
            if (
              response?.data?.responseCode !== undefined &&
              response?.data?.responseCode !== null
            ) {
              if (
                response?.data?.responseCode === 108 ||
                response?.data?.responseCode === 103
              ) {
              } else if (response.data.responseCode === 109) {
                let userDetails = {};
                if (
                  response?.data?.user !== null &&
                  response?.data?.user?.permission !== undefined
                ) {
                  userDetails = {
                    email: response?.data?.user?.email,
                    mobileNo: response?.data?.user?.mobileNo,
                    name: response?.data?.user?.name,
                    permission: response?.data?.user?.permission,
                    profileImage: response?.data?.user?.profileImage,
                    userName: response?.data?.user?.userName,
                    userType: response?.data?.user?.userType,
                    isSuperAdmin: response?.data?.user?.isSuperAdmin,
                    token: response?.data?.user?.token,
                  };
                  LocalStorage.userDetails = userDetails;
                } else {
                  setSnakbarValues({
                    status: true,
                    severity: "error",
                    message: "User does not have permission to login",
                  });

                  logoutFunction();
                }

                if (
                  userDetails.userType !== undefined &&
                  (userDetails.userType === "admin" ||
                    userDetails.userType === "vendor")
                ) {
                  if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "Packages"
                    )
                  ) {
                    props.history.replace("/admin");
                  } else if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "Packages"
                    )
                  ) {
                    props.history.replace("/admin/dashboard");
                  }
                }
                if (userDetails.userType === "vendor") {
                  if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "Packages"
                    )
                  ) {
                    props.history.replace("/admin");
                  } else if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "Packages"
                    )
                  ) {
                    props.history.replace("/admin/dashboard");
                  }
                }
                if (
                  userDetails.userType === "admin" ||
                  userDetails.userType === "vendor"
                ) {
                  if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "Packages"
                    )
                  ) {
                    props.history.replace("/admin");
                  } else if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "Packages"
                    )
                  ) {
                    props.history.replace("/admin/dashboard");
                  }
                } else {
                  props.history.replace("/login");
                }
              }
              //  else {
              //   publishNotification("Something went wrong", "error");
              // }
            }
          }
        } else {
          publishNotification("Something went wrong", "error");
        }
      }
    );
  };
  const _enableResendOTPButton = () => {
    setTimeout(function () {
      setDisableOtpBtn(false);
    }, 5000);
  };

  const logoutFunction = (props) => {
    APIRequest.request("GET", ConfigAPIURL.adminLogout, "").then((response) => {
      if (response?.data?.responseCode === 100) {
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      }
    });
    sessionStorage.clear();
    localStorage.clear();
  };

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(0);

    if (userName === "") {
      return Toaster.error("Enter your Mobile Number", "topRight");
    }
    if (password === "") {
      return Toaster.error("Enter your Password", "topRight");
    }
    APIRequest.request(
      "POST",
      ConfigAPIURL.accountLogin,
      JSON.stringify({
        mobileNo: userName,
        password: password,
      })
    ).then((response) => {
      //redirect based on user type
      if (
        response?.data?.responseCode === 108 ||
        response?.data?.responseCode === 103
      ) {
        publishNotification("Wrong Credentials", "error");
      } else if (response?.data?.responseCode === 116) {
        publishNotification("Exceeded Password attempt", "error");
      } else if (response?.data?.responseCode === 122) {
        const newToken = response.data.token;
        localStorage.setItem("token", newToken);
        setResetTimer(true);
        setOTP("");
        setBlockEntry(false);

        publishNotification("OTP sent to your mobile number", "success");
        setTowFactAuth(true);
        // setToken(response.data.token);
      } else if (response.data.responseCode === 109) {
      } else if (response.data.responseCode === 104) {
        publishNotification(
          "Password is not matching, please check your password",
          "error"
        );
      }
    });
  };

  const forgotResendOtp = () => {
    setMinutes(1);
    setSeconds(0);

    if (userName === "") {
      return publishNotification("Enter your Mobile Number", "error");
    }
    // if (password === "") {
    //   return Toaster.error("Enter your Password", "topRight");
    // }
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOtp,
      JSON.stringify({
        mobileNo: userName,
        // password: password,
      })
    ).then((response) => {
      //redirect based on user type
      if (
        response?.data?.responseCode === 108 ||
        response?.data?.responseCode === 103
      ) {
        publishNotification("Wrong Credentials", "error");
      } else if (response?.data?.responseCode === 116) {
        publishNotification("Exceeded Password attempt", "error");
      } else if (response?.data?.responseCode === 122) {
        const newToken = response.data.token;
        localStorage.setItem("token", newToken);
        //  setPasswordFields(true);
        setBlockEntry(false);

        publishNotification("OTP sent to your mobile number", "success");
        // setTowFactAuth(true);
        // setToken(response.data.token);
      } else if (response.data.responseCode === 109) {
        publishNotification("OTP Verified Successfully", "success");
      } else if (response.data.responseCode === 104) {
        publishNotification(
          "Password is not matching, please check your password",
          "error"
        );
      }
    });
  };
  //verifying OTP for reset password page
  const verifyForgotPasswordOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.verifyOtpForgot,
      JSON.stringify({
        otpVal: otp,
      })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        setPasswordFields(true);
        const newToken = response.data.token;
        localStorage.setItem("token", newToken);
      } else if (response?.data?.responseCode === 118) {
        setBlockEntry(true);
        publishNotification(
          "OOPS! You entered the wrong OTP, Please click on resend to get new OTP",
          "error"
        );
      } else if (response?.data?.responseCode === 112) {
        publishNotification("Something went wrong !", "error");
      }
    });
  };

  return (
    <LoginPage
      userName={userName}
      setUserName={setUserName}
      password={password}
      setPassword={setPassword}
      // resendOTP={resendOTP}
      resendOTP={resendOTP}
      logout={logoutFunction}
      _enableResendOTPButton={_enableResendOTPButton}
      // verifyOTP={verifyOTP}
      verifyOTP={verifyOTP}
      submitMobileLogin={submitMobileLogin}
      handleDeleteRequest={handleDeleteRequest}
      handleSlideInDialog={handleSlideInDialog}
      handleTogglePassword={handleTogglePassword}
      handlePasswordChange={handlePasswordChange}
      notificationClose={notificationClose}
      snakbarValues={snakbarValues}
      disableOtpBtn={disableOtpBtn}
      slideInDialog={slideInDialog}
      setOTP={setOTP}
      twoFactAut={twoFactAut}
      showPassword={showPassword}
      otp={otp}
      setTowFactAuth={setTowFactAuth}
      timer={timer}
      setTimer={setTimer}
      resetTimer={resetTimer}
      minutes={minutes}
      seconds={seconds}
      setMinutes={setMinutes}
      setSeconds={setSeconds}
      passwordFields={passwordFields}
      forgotResendOtp={forgotResendOtp}
      verifyForgotPasswordOTP={verifyForgotPasswordOTP}
      isLogin={isLogin}
      setPasswordFields={setPasswordFields}
      history={props.history}
      // forgotToken={forgotToken}
      blockEntry={blockEntry}
      setBlockEntry={setBlockEntry}
    />
  );
}

export default Login;
