import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // overflowY: "auto",
    overflow: "auto",
    overflowX: "hidden",
    marginTop: "3.4rem",
    marginLeft: "1rem",
  },
  spaceBetween: {
    margin: `0 ${theme.spacingArea.large}`,
    marginBottom: "24px",
  },
  label: {
    color: "#323130",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    margin: "8px",
  },
  distinct: {
    marginTop: theme.spacingArea.doubleLarge,
  },
  gapDoubleLarge: {
    gap: theme.spacingArea.doubleLarge,
  },
  gapMedium: {
    gap: theme.spacingArea.xLarge,
  },
  gapSmall: {
    gap: theme.spacingArea.medium,
  },
  gapXsmall: {
    gap: theme.spacingArea.small,
  },
  input: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    overflow: "hidden",
    fontSize: "14px",
    marginTop: theme.spacingArea.xSmall,
    backgroundColor: "white",
    padding: "5px",
  },
  backgroundGrey: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacingArea.large,
  },
  smallComponentSpacingBetween: {
    marginTop: theme.spacingArea.large,
    paddingBottom: theme.spacingArea.small,
  },
  componentSpacingBetween: {
    marginTop: theme.spacingArea.xLarge,
    paddingBottom: theme.spacingArea.small,
  },

  dashBoardTitle: {
    color: "#000",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameStyle: {
    color: "#268581",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    fontWeight: "600",
    marginLeft: "10px",
    marginBottom:"2px"
  },
  dashBoardSubtitle: {
    color: "#868686",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    // display: "flex",
    gap: "8px",
    paddingTop: "10px",
  },
  graphGridLeft: {
    borderRadius: "10px",
    background: "#FFF",
    boxShadow: "0px 7px 17px 0px rgba(0, 0, 0, 0.10)",
    marginTop: "20px",
    padding: "15px",
    // marginLeft: "20px",
    width: "50%",
    display: "flex",
    height: "540px",
    // flexDirection: "column",
    // alignItems: "flex-start",
    gap: "10px",
  },
  graphBooking: {
    borderRadius: "10px",
    background: "#FFF",
    boxShadow: "0px 7px 17px 0px rgba(0, 0, 0, 0.10)",
    marginTop: "20px",
    padding: "15px",
    // marginLeft: "20px",
    width: "100%",
    display: "flex",
    height: "560px",
    // flexDirection: "column",
    // alignItems: "flex-start",
    gap: "10px",
  },
  graphSubscriptionGrid: {
    borderRadius: "10px",
    background: "#FFF",
    boxShadow: "0px 7px 17px 0px rgba(0, 0, 0, 0.10)",
    marginTop: "20px",
    padding: "15px",

    width: "96%",
    // display: "flex",
    height: "600px",
    // flexDirection: "column",
    // alignItems: "flex-start",
    gap: "10px",
    marginLeft: "16px",
    marginBottom: "40px",
  },

  gridTopCards: {
    marginTop: "20px",
    padding: "15px",
    width: "100%",
    
  },

  card: {
    width: "254px",
    height: "182px",
    border: "1px solid var(--others-white, #FFF)",
    background: "rgba(255, 255, 255, 0.75)",
    boxShadow: "0px 12px 16px -4px rgba(12, 26, 36, 0.07)",
    backdropFilter: "blur(13.600000381469727px)",
    borderRadius: "6px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  card2: {
    // width: "31%",
    height: "124px",
    flexShrink: "0",
    borderRadius: "8px",
    background: "linear-gradient(102deg, #F84E6B 0%, #F67086 99.21%)",
  },
  card3: {
    // width: "31%",
    height: "124px",
    flexShrink: "0",
    borderRadius: "8px",
    background: "linear-gradient(103deg, #F66B2F 0%, #EFA55F 100%)",
  },
  graphGridRight: {
    borderRadius: "10px",
    background: "#FFF",
    boxShadow: "0px 7px 17px 0px rgba(0, 0, 0, 0.10)",
    marginTop: "20px",
    padding: "15px",
    marginLeft: "20px",
    width: "44%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    float: "right",
    // overflow: "hidden",
  },
}));
