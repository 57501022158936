import {
  Avatar,
  TableCellLayout,
  createTableColumn,
} from "@fluentui/react-components";
import * as React from "react";

import { MoreVertical24Regular } from "@fluentui/react-icons";
import { Popover } from "../../../../components/popover/Index";

import {
  Accessibility32Regular,
  ChevronDown24Filled,
  Edit24Regular,
  Delete32Regular,
  ConvertRange24Regular,
  Edit32Regular,
} from "@fluentui/react-icons";
import utilController from "../../../../utils/Utilcontroller";

const useTableHeader = (setOpenForm, openForm, services) => {
  const columns = [
    {
      columnId: "couponId", // this is the unique id for a column
      fieldName: "Coupon Id", // field name visible on header
      minWidth: 200,
      primaryKey: true, // this is the primary one which cant be disabled in column option
      renderCell: (item) => (
        // what should be rendered on the cell
        <TableCellLayout
          style={{ textTransform: "capitalize", color: "#323130" }}
          truncate
        >
          {item?.couponId}
        </TableCellLayout>
      ),
    },
    {
      columnId: "couponName", // this is the unique id for a column
      fieldName: "Coupon Name",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {item?.couponName}
        </TableCellLayout>
      ),
    },

    {
      columnId: "memberSince", // this is the unique id for a column
      fieldName: "User Duration",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {item?.memberSince}
        </TableCellLayout>
      ),
    },

    {
      columnId: "userRating", // this is the unique id for a column
      fieldName: "User Rating",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout
          truncate
          style={{ textTransform: "capitalize", textAlign: "end" }}
        >
          {item?.userRating}
        </TableCellLayout>
      ),
    },
    {
      columnId: "startDate", // this is the unique id for a column
      fieldName: "Start Date",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.startDate)}
        </TableCellLayout>
      ),
    },

    {
      columnId: "endDate", // this is the unique id for a column
      fieldName: "End Date",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.endDate)}
        </TableCellLayout>
      ),
    },

    {
      columnId: "createdOn", // this is the unique id for a column
      fieldName: "Created On",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.createdAt)}
        </TableCellLayout>
      ),
    },
    {
      columnId: "updatedAt", // this is the unique id for a column
      fieldName: "Updated On",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.updatedAt)}
        </TableCellLayout>
      ),
    },
  ];
  return columns;
};

export default useTableHeader;
