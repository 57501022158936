import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  spaceBetween: {
    margin: `0 ${theme.spacingArea.large}`,
    padding: 0,
  },
  label: {
    fontWeight: 600,
    fontSize: theme.fonts.heading,
  },
  distinct: {
    marginTop: theme.spacingArea.doubleLarge,
  },
  gapDoubleLarge: {
    gap: theme.spacingArea.doubleLarge,
  },
  gapMedium: {
    gap: theme.spacingArea.xLarge,
  },
  gapSmall: {
    gap: theme.spacingArea.medium,
  },
  gapXsmall: {
    gap: theme.spacingArea.small,
  },
  input: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    overflow: "hidden",
    fontSize: "14px",
    marginTop: theme.spacingArea.xSmall,
    backgroundColor: "white",
  },
  backgroundGrey: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacingArea.large,
  },
  smallComponentSpacingBetween: {
    marginTop: theme.spacingArea.large,
    paddingBottom: theme.spacingArea.small,
  },
  componentSpacingBetween: {
    marginTop: theme.spacingArea.xLarge,
    paddingBottom: theme.spacingArea.small,
  },

  tableRoot: {
    borderRadius: "15px",
    background: "#FFF",
    boxShadow: "0px 5px 17px 0px rgba(0, 0, 0, 0.10)",
    fontFamily: "Segoe UI",
  },
  header: {
    borderRadius: "10px 10px 0px 0px",
    background: "#F1F1F1",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Segoe UI",
    paddingLeft:"8px"

  },
  title: {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    fontFamily: "Segoe UI",
    paddingLeft:"8px"
  },
  tableCell: {
    padding: "10px",
    borderBottom: "1px solid #ccc",
    textAlign: "center",
    fontFamily: "Segoe UI",
  },
  addButton: {
    color: "#06381C",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  columnSeparator: {
    borderRight: "1px solid #ccc",
  },
  inputStyle: {
    border: "none",
    textAlign: "center",
    width: "40%",
    content: "-",
    transition: "border 0.2s", // Add a smooth transition effect for the border
  },

  inputFocusStyle: {
    border: "1px solid #06381C", // Add a border on focus
  },
}));
