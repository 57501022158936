import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  spaceBetween: {
    margin: `0 ${theme.spacingArea.large}`,
    padding: 0,
  },
  label: {
    color: "#323130",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "630",
    lineHeight: "normal",
  },
  distinct: {
    marginTop: theme.spacingArea.doubleLarge,
  },
  gapDoubleLarge: {
    gap: theme.spacingArea.doubleLarge,
  },
  gapMedium: {
    gap: theme.spacingArea.xLarge,
  },
  gapSmall: {
    gap: theme.spacingArea.medium,
  },
  gapXsmall: {
    gap: theme.spacingArea.small,
  },
  input: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    overflow: "hidden",
    fontSize: "14px",
    marginTop: theme.spacingArea.xSmall,
    backgroundColor: "white",
    height: "45px",
  },
  backgroundGrey: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacingArea.large,
  },
  smallComponentSpacingBetween: {
    marginTop: theme.spacingArea.large,
    paddingBottom: theme.spacingArea.small,
  },
  componentSpacingBetween: {
    marginTop: theme.spacingArea.xLarge,
    paddingBottom: theme.spacingArea.small,
  },
  increment: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    overflow: "hidden",
    fontSize: "16px",
    marginTop: theme.spacingArea.xSmall,
    backgroundColor: "white",
    width: "52px ",
    height: "41px",
    justifyContent: "center",
    alignItems: "center",
  },
  parentTitle: {
    padding: ` ${theme.spacingArea.medium} ${theme.spacingArea.large}`,
    backgroundColor: "#F1F1F1",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  itenaryComponent: {
    padding: theme.spacingArea.large,
    paddingTop: theme.spacingArea.small,
    borderRight: "1px solid #E5E5E5",
    borderLeft: "1px solid #E5E5E5",
    gap: theme.spacingArea.large,
    height: "100%",
  },
  tabContainer: {
    display: "flex",
    width: "340px",
    height: "50px",
    borderRadius: "42px",
    border: "1px solid #268581",
    backgroundColor: "#fff",
    marginBottom: "10px",
    marginTop: "20px",
  },
  tab: {
    width: "122px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
    outline: "none",
    // borderRadius: "11px",
    borderRadius: "42px 0 0 42px",
  },
  tab2: {
    width: "127px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
    outline: "none",
    // borderRadius: "0 42px 42px 0",
  },
  tab3: {
    width: "127px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
    outline: "none",
    borderRadius: "0 42px 42px 0",
  },

  activeTab: {
    backgroundColor: "#268581",
    color: "#fff",
    borderRadius: "-1px",
    height: "50px",
  },
  inactiveTab: {
    height: "50px",
  },
  content: {
    display: "none",
    width: "100%",
    padding: "20px",
  },
  activeContent: {
    display: "block",
  },

  // ---------------
  tabContainerAddPlan: {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: "60px",
  },
  tabPlan: {
    borderRadius: "10px",
    border: "1px solid #268581",
    boxShadow: "0px 7px 17px 0px rgba(0, 0, 0, 0.10)",
    width: "100%",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    marginTop: "16px",
  },
  selectedTabPlan: {
    background: "#268581",
    color: "white",
  },
  reviewBackGround: {
    borderRadius: "8px",
    background: "#FAFAFB",
    margin: "8px",
    padding: "16px",
  },
  reviewName: {
    color: "#000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  reviewDate: {
    color: "#868686",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  feedback: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  ratingStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  overallRating: {
    color: "#17024B",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
}));
