import React, { useEffect, useMemo, useState } from "react";
import apiFetchRequest from "../../../../../utils/ApiFetchRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import images from "../../../../../images";
import useAlert from "../../../../../hooks/useAlert";
import utilController from "../../../../../utils/Utilcontroller";

const form = {
  packageCode: "",
  name: "",
  pickup: "",
  dropOff: "",
  description: "",
  discount: 0,
  city: "",
  attachment: [],
  status: "pending",
  triDetails:[]
};

const days = { location: "", days: 1 };

const useAevForm = ({ openForm, services }) => {
  const { publishNotification } = useAlert();
  // const [userForm, setUserForm] = useState(form);
  const [userForm, setUserForm] = useState([]);
  const [itenary, setItenary] = useState([]);
  const [totalDays, setTotalDays] = useState([{ ...days }]);
  const [editor, setEditor] = useState("");

  // checks the total days amnd fixes for the vendor to not add more than that days

  const daysCount = useMemo(() => {
    let days = 0;
    totalDays?.forEach((value) => (days += value?.days));
    return days;
  }, [totalDays]);

  const handleUpdateStatus = (userForm) => {
    const data = {
      ...userForm,
      itinerary: itenary,
      packageDays: totalDays,
      duration: daysCount,
      note: utilController.encodeData(editor),
    };
    data["recordId"] = openForm?.rowDetails;
    let isEdit = true;

    services?.sendToServer(data, isEdit);
  };

  useEffect(() => {
    // gettting details of row
    // fetch the details if row details found
    if (
      openForm?.rowDetails &&
      (openForm?.divType === "edit" || openForm?.divType === "view")
    ) {
      services?.getEditTable(
        openForm?.rowDetails,
        setUserForm,
        setItenary,
        setTotalDays,
        setEditor
      );
    }
  }, []);

  const addDaysInTransfer = (itenary, type, days) => {
    // this fn loops through the itenary array and add the no of days in every transfer

    itenary.forEach((value, ind) => {
      value?.dayItinerary?.forEach((item, index) => {
        if (item?.type === type) {
          item.vehicleNoOfDays = days;
        }
      });
    });
  };

  const handleAddItenary = (ind, data, selectedInd) => {
    // helps to add data to dyitenary table
    const itenaryCopy = [...itenary];
    if (selectedInd === 0 || selectedInd) {
      itenaryCopy[ind].dayItinerary[selectedInd] = { ...data };
    } else {
      if (data?.type === "transfer") {
        // every transfer should be at the top of the list and should be visible on first day
        itenaryCopy[0]?.dayItinerary?.unshift({ ...data });
        if (ind !== 0) {
          publishNotification(
            "Transfer has been added to the day one of Itenary",
            "success"
          );
        }
      } else {
        itenaryCopy[ind]?.dayItinerary?.push({ ...data });
      }
    }
    addDaysInTransfer(itenaryCopy, "transfer", daysCount);

    setItenary(itenaryCopy);
  };
  const handleDays = () => {
    // this is for adding days inside the itenary
    const itenaryCopy = [...itenary];
    if (itenaryCopy?.length >= daysCount) {
      return publishNotification(
        "Add the days at top before adding the days",
        "error"
      );
    }
    itenaryCopy?.push({ days: itenaryCopy.length, dayItinerary: [] });
    addDaysInTransfer(itenaryCopy, "transfer", daysCount);
    setItenary(itenaryCopy);
  };

  const handleDeleteDays = (ind) => {
    //fn helps to delete the days // normally the last day
    const itenaryCopy = [...itenary];
    itenaryCopy?.splice(ind, 1);
    setItenary(itenaryCopy);
  };
  const handleDaysChange = ({ ind, type, field, value }) => {
    // this is for the days change at the top of itenary table every fn includes here
    const daysCopy = [...totalDays];
    if (type === "add") {
      daysCopy?.push({ ...days });
    }
    if (type === "delete") {
      daysCopy?.splice(ind, 1);
    }
    if (type === "edit") {
      daysCopy[ind][field] = value;
    }
    setTotalDays(daysCopy);
  };
  const handleDeleteItenary = (ind, selectedDate) => {
    // helps to delete the dayitenary
    const itenaryCopy = [...itenary];
    itenaryCopy[selectedDate]?.dayItinerary?.splice(ind, 1);
    setItenary(itenaryCopy);
  };

  const handleDeleteAttachment = (ind) => {
    const userFormCopy = { ...userForm };
    userFormCopy?.attachment?.splice(ind, 1);
    setUserForm(userFormCopy);
  };
  return {
    userForm,
    setUserForm,
    itenary,
    handleAddItenary,
    totalDays,
    setTotalDays,
    handleDaysChange,
    daysCount,
    handleDays,
    handleDeleteItenary,
    editor,
    setEditor,
    handleDeleteAttachment,
    handleDeleteDays,
    handleUpdateStatus,
  };
};

export default useAevForm;
