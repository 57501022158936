import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Dropdown, Field, Input, Option } from "@fluentui/react-components";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textAlign: "end",
  },
}));
function AddRole(props) {
  const { addRoleObject, setAddRoleObject, handleClose, open, addRole } = props;
  const classes = useStyles();

  const options = [
    { key: true, text: "Active" },
    { key: false, text: "Inactive" },
  ];

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Create Roles</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "20px",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <Field label="Role Name" required className={classes.label}>
                    <Input
                      autoFocus
                      required
                      id="name"
                      autoComplete="off"
                      label="roleName"
                      className={`${classes.input} input__Style`}
                      type="text"
                      fullWidth
                      value={addRoleObject.name}
                      onChange={(event) => {
                        setAddRoleObject({
                          ...addRoleObject,
                          name: event.target.value,
                        });
                      }}
                      style={{ marginRight: "10px", height: "49px" }}
                    />
                  </Field>
                </Grid>
                {console.log(addRoleObject, "addRoleObject")}
                <Grid item xs={12}>
                  <Field label="Role Status" required className={classes.label}>
                    <Dropdown
                    style={{ marginTop: 4, height: "49px",display:"flex",alignItems:"center" }}
                      select
                      className={`${classes.input} input__Style`}
                      value={addRoleObject?.active? "Active" : "Inactive"}
                      onOptionSelect={(e, data) => {
                        setAddRoleObject({
                          ...addRoleObject,
                          active: data?.optionValue,
                        });
                      }}
                      fullWidth
                    >
                      {options.map((option) => (
                        <Option key={option.key} value={option.key}>
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>
                  </Field>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => handleClose()} style={{fontFamily:"Segoe UI"}}>
                Close
              </Button>
            </DialogTrigger>
            <button
              // appearance="primary"
              style={{
                background: "#268581",
                color: "white",
                border: "none",
                borderRadius: "3px",
                width: "99px",
                cursor: "pointer",
                fontFamily:"Segoe UI"
              }}
              onClick={() => addRole()}
            >
              Create Role
            </button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AddRole)
);
