import React, { useState } from "react";
import ConfigAPIURL from "../config/ConfigAPIURL";
import APIRequest from "../utils/APIRequest";

const useUpload = (setUserForm, userForm) => {
  const [isLoading, setIsLoading] = useState(false);

  const multipleFileUpload = async (e) => {
    let result = [];
    try {
      setIsLoading(true);

      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // find the extension of the file and generate the url
        const type = file?.name?.split(".")[1];
        const generatedUrl = await generateUrl(type);
        const name = file?.name;
        if (generatedUrl) {
          // if the url is found then upload the form
          const response = await fetch(generatedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: file,
          });
          // attachment value is spererated with ?
          const url = response?.url?.split("?")[0];
          result?.push({
            type,
            name,
            url,
          });
        } // an error occured
      }
      return result;
    } catch (err) {
      console.log(err);
      return result;
    } finally {
      setIsLoading(false);
    }
  };
  // file upload with serverless extension
  const fileUpload = async (e) => {
    try {
      setIsLoading(true);

      const file = e.target.files[0];
      // find the extension of the file and generate the url
      const type = file?.name?.split(".")[1];
      const generatedUrl = await generateUrl(type);
      const name = file?.name;
      if (generatedUrl) {
        // if the url is found then upload the form
        const response = await fetch(generatedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file,
        });
        // attachment value is spererated with ?
        const url = response?.url?.split("?")[0];
        return {
          type,
          name,
          url,
        };
      } else return false; // an error occured
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const generateUrl = async (fileType) => {
    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.uploadFile,
        JSON.stringify({ fileType })
      );
      if (response?.data?.responseCode === 109) {
        return response?.data?.result;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return { fileUpload, isLoading, multipleFileUpload };
};

export default useUpload;
