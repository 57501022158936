import {
  PeopleAdd20Regular,
  PeopleAdd20Filled,
  Edit20Regular,
  Edit20Filled,
  Delete20Filled,
  Delete20Regular,
  CloudArrowDown20Filled,
  CloudArrowDown20Regular,
  Eye20Regular,
  Eye20Filled,
  DualScreenVerticalScroll20Regular,
  DualScreenVerticalScroll20Filled
} from "@fluentui/react-icons";

const iconStyleProps: FluentIconsProps = {
  primaryFill: "blue",
  className: "iconClass",
  font: "14px",
};

export const tableButtons = [
  {
    id: "add",
    label: "Create",
    icons: {
      regular: PeopleAdd20Regular,
      filled: PeopleAdd20Filled,
    },
    handler: (item) => item && item?.add(),
  },
  {
    id: "view",
    label: "View",
    icons: {
      regular: Eye20Regular,
      filled: Eye20Filled,
    },
    handler: (item) => item && item?.view(),
  },
  {
    id: "edit",
    label: "Edit",
    icons: {
      regular: Edit20Regular,
      filled: Edit20Filled,
    },
    handler: (item) => item && item?.edit(),
  },
  {
    id: "delete",
    label: "Delete",
    icons: {
      regular: Delete20Regular,
      filled: Delete20Filled,
    },
    handler: (item) => item && item?.handleDelete(),
  },
  {
    id: "export",
    label: "Export",
    icons: {
      regular: CloudArrowDown20Regular,
      filled: CloudArrowDown20Filled,
    },
    handler: (item) => item && item?.handleCsvExport(),
  },
  {
    id: "assign",
    label: "Assign",
    icons: {
      regular: DualScreenVerticalScroll20Regular,
      filled: DualScreenVerticalScroll20Filled,
    },
    handler: (item) => item && item?.assign(),
  },
];
