import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Dropdown, Field, Input, Option } from "@fluentui/react-components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    marginRight: 10,
  },
}));

function AEVForm(props) {
  const { openForm, recordId, addForm, setAddForm, addFormDetails } = props;

  const classes = useStyles();

  useEffect(() => {
    getEditable();
  }, openForm?.divType);

  const resetForm = () => {
    setAddForm(addFormDetails);
  };

  const getEditable = () => {
    if (openForm?.divType === "edit") {
      APIRequest.request(
        "POST",
        ConfigAPIURL.roleDetails,
        JSON.stringify({ roleId: recordId })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let serverResponse = response.data.role;
            setAddForm(serverResponse);
          }
        }
      });
    }
  };

  const handlePermissionCheckChange = (event, labelIndex, index) => {
    let form = addForm;
    form.permission[labelIndex].buttons[index].enable = event.target.checked;
    console.log(form.permission[labelIndex].buttons[index].enable);

    setAddForm({ ...addForm }, form);
  };

  const handlePermissionChange = (event, labelIndex, index) => {
    console.log(event.target.checked);
    let form = [...addForm.permission];
    const data = form[labelIndex].buttons.map((val, ind) => {
      return {
        ...val,
        enable: event.target.checked,
      };
    });
    form[labelIndex].buttons = data;
    form[labelIndex].enable = event.target.checked;
    setAddForm({
      ...addForm,
      permission: form,
    });
  };

  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: "700",
            fontSize: "24px",
          }}
        >
          Edit Roles
        </Typography>
      </div>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "40px" }}
        >
          <Paper className={classes.paper} elevation={1}>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container item xs={12} spacing={theme.spacingArea.xLarge}>
                <Grid item xl={4}>
                  <Field className={classes.label} label="Role Name" required>
                    <Input
                      size="large"
                      className={`${classes.input} input__Style`}
                      placeholder=""
                      value={addForm?.name}
                      style={{
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        height: "49px",
                        marginTop: "8px",
                      }}
                      onChange={(e) =>
                        setAddForm({ ...addForm, name: e.target.value })
                      }
                    />
                  </Field>
                </Grid>

                <Grid item xs={4}>
                  <Field label="Active">
                    <Dropdown
                      value={addForm?.active ? "yes" : "no"}
                      size="large"
                      style={{
                        textTransform: "capitalize",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        height: "49px",
                        marginTop: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={`${classes.input} input__Style`}
                      onOptionSelect={(e, data) => {
                        setAddForm({
                          ...addForm,
                          active: data?.optionValue === "yes" ? true : false,
                        });
                      }}
                    >
                      <Option
                        style={{ textTransform: "capitalize" }}
                        text={true}
                        value={"yes"}
                      >
                        Yes
                      </Option>
                      <Option
                        style={{ textTransform: "capitalize" }}
                        text={false}
                        value={"no"}
                      >
                        No
                      </Option>
                    </Dropdown>
                  </Field>
                </Grid>
              </Grid>

              {addForm.permission.map((permission, index) => (
                <Grid
                  key={index}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "20px" }}
                  className={classes.formGrid}
                >
                  <Accordion>
                    <AccordionSummary
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                    >
                      <Grid container>
                        <Grid
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className={classes.heading}
                        >
                          <Typography>
                            {permission?.label?.replace("_", " ")}{" "}
                            <Checkbox
                              style={{ float: "right", color: "#268581" }}
                              checked={permission.enable}
                              onChange={(event) =>
                                handlePermissionChange(event, index)
                              }
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className={classes.secondaryHeading}
                        >
                          <Grid container>
                            {permission.buttons.map((buttons, idx) => (
                              <Grid
                                key={idx}
                                xl={4}
                                lg={4}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <Checkbox
                                  checked={buttons.enable}
                                  style={{ color: "#268581" }}
                                  onChange={(event) =>
                                    handlePermissionCheckChange(
                                      event,
                                      index,
                                      idx
                                    )
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                                {buttons.label}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AEVForm)
);
