import { Stack } from "@fluentui/react";
import {
  Combobox,
  Dropdown,
  Field,
  FluentProvider,
  Option,
  Persona,
  teamsLightTheme,
} from "@fluentui/react-components";
import useAlert from "../../../../../hooks/useAlert";
import React, { useEffect, useState } from "react";
import APIRequest from "../../../../../utils/APIRequest.js";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { Typography } from "@mui/material";
import { DatePicker } from "@fluentui/react-datepicker-compat";

// const status = ["pending", "rejected", "approved"];

const Filter = ({ query, setQuery, openForm, resetForm }) => {
  const { publishNotification } = useAlert();

  const [stateList, setStateList] = useState([]);

  const [keyWord, setKeyWord] = useState("");
  const [datesetting, setDateSetting] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const getStateList = async () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.fetchStates,
      JSON.stringify({ state: filter?.state, page: 0, pageSize: 100 })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        const data = response?.data?.result;
        data &&
          data?.map((value, i) => {
            setStateList([value?.state_name_english]);
          });
      }
    });
  };

  const [filter, setFilter] = useState({
    active: query?.active,
    status: query?.status,
    startDate: query?.startDate,
    endDate: query?.endDate,
    state: query?.state,
    bookingSort: query?.bookingSort,
    reviewSort: query?.reviewSort,
  });

  useEffect(() => {
    if (openForm?.isSaveForm) {
      setQuery({ ...query, ...filter });
      resetForm();
    }
  }, [openForm]);

  useEffect(() => {
    getStateList(filter?.state);
  }, [filter?.state]);

  return (
    <FluentProvider theme={teamsLightTheme}>
      <Stack style={{ gap: "24px", paddingTop: "24px" }}>
        <Stack style={{}}>
          <Typography
            variant="title"
            style={{
              color: "#323130",
              fontFamily: "Segoe UI",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Filter
          </Typography>
        </Stack>

        <Field label="Active">
          <Dropdown
            className={` input__Style`}
            value={filter?.active ? "Yes" : "No"}
            size="large"
            style={{ textTransform: "capitalize" }}
            onOptionSelect={(e, data) => {
              setFilter({
                ...filter,
                active: data?.optionValue === "yes" ? true : false,
              });
            }}
          >
            <Option
              style={{ textTransform: "capitalize" }}
              text={true}
              value={"yes"}
            >
              Yes
            </Option>
            <Option
              style={{ textTransform: "capitalize" }}
              text={false}
              value={"no"}
            >
              No
            </Option>
          </Dropdown>
        </Field>
        {/* <Field label="State">
          <Combobox
            className={` input__Style`}
            freeform={true}
            
            onInput={(e) => {
              setFilter({
                ...filter,
                state: e.target.value,
              });
            }}
            onOptionSelect={(e, data) => {
              setFilter({
                ...filter,
                state: data?.optionText,
              });
            }}
            // className={`${classes.input} input__Style`}
            size="large"
            value={filter?.state}
          >
            {stateList &&
              stateList?.map((value, i) => (
                <Option
                  style={{ textTransform: "capitalize" }}
                  key={i}
                  value={value}
                >
                
                  {value}
                </Option>
              ))}
          </Combobox>
        </Field>
        {console.log(filter?.status, "from filter")} */}
        <Field label="Booking Status">
          <Dropdown
            className={`input__Style`}
            size="large"
            style={{ textTransform: "capitalize" }}
            onOptionSelect={(e, data) => {
              let selectedStatus = data?.optionValue;

              if (selectedStatus === "Ongoing") {
                selectedStatus = "pending";
              }

              setFilter({
                ...filter,
                status: selectedStatus,
              });
            }}
            value={filter?.status === "pending" ? "Ongoing" : filter?.status}
          >
            <Option style={{ textTransform: "capitalize" }} value={"accepted"}>
              Accepted
            </Option>
            <Option style={{ textTransform: "capitalize" }} value={"completed"}>
              Completed
            </Option>
            <Option style={{ textTransform: "capitalize" }} value={"cancelled"}>
              Cancelled
            </Option>
            <Option style={{ textTransform: "capitalize" }} value={"Ongoing"}>
              Ongoing
            </Option>
            <Option style={{ textTransform: "capitalize" }} value={"started"}>
              Started
            </Option>
            <Option
              style={{ textTransform: "capitalize" }}
              value={"outForPickup"}
            >
              Out For Pickup
            </Option>
          </Dropdown>
        </Field>
        {/* <Field label="Sort booking by">
          <Dropdown
            className={`input__Style`}
            size="large"
            style={{ textTransform: "capitalize" }}
            onOptionSelect={(e, data) => {
              let selectedStatus = data?.optionValue;

              setFilter({
                ...filter,
                bookingSort: selectedStatus,
              });
            }}
            value={filter?.bookingSort}
          >
            <Option style={{ textTransform: "capitalize" }} value={"AtoZ"}>
              A to Z
            </Option>
            <Option style={{ textTransform: "capitalize" }} value={"ZtoA"}>
              Z to A
            </Option>
          </Dropdown>
        </Field>
        <Field label="Sort Review by">
          <Dropdown
            className={`input__Style`}
            size="large"
            style={{ textTransform: "capitalize" }}
            onOptionSelect={(e, data) => {
              let selectedStatus = data?.optionValue;

              setFilter({
                ...filter,
                reviewSort: selectedStatus,
              });
            }}
            value={filter?.reviewSort}
          >
            <Option style={{ textTransform: "capitalize" }} value={"1to5"}>
              1 to 5
            </Option>
            <Option style={{ textTransform: "capitalize" }} value={"5to1"}>
              5 to 1
            </Option>
          </Dropdown>
        </Field> */}
        <Field label="Start Date">
          <DatePicker
            maxDate={new Date(filter?.endDate * 1000)}
            className={` input__Style`}
            size="large"
            value={filter?.startDate && new Date(filter?.startDate * 1000)}
            onSelectDate={(e) =>
              setFilter({
                ...filter,
                startDate: new Date(e).setHours(12, 0, 0, 0) / 1000,
              })
            }
            placeholder="Select Start Date..."
          />
        </Field>
        <Field label="End Date">
          <DatePicker
            minDate={new Date(filter?.startDate * 1000)}
            maxDate={new Date()}
            className={` input__Style`}
            size="large"
            value={new Date(filter?.endDate * 1000)}
            onSelectDate={(e) =>
              setFilter({
                ...filter,
                endDate: new Date(e).setHours(23, 59, 0, 0) / 1000,
              })
            }
            placeholder="Select End Date..."
          />
        </Field>
      </Stack>
    </FluentProvider>
  );
};

export default Filter;
