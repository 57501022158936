const utilController = {
  textCapitalise: function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  isEncoded: (str) => {
    try {
      window.atob(str);
      return true;
    } catch {
      return false;
    }
  },
  getQueryParams: (history, param) => {
    // pass the history object as argument
    if (!history) throw new Error("History object is undefined");
    const urlParams = new URLSearchParams(history.location.search);
    const payload = urlParams.get(param);
    return payload;
  },
  getDateTime: (epochdate) => {
    return new Date(epochdate * 1000)?.toLocaleDateString("en-in");
  },

  getFormattedDate: (epochdate) => {
    const date = new Date(epochdate * 1000);
    const options = {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  },
  getReviewFormatedDate: (epochdate) => {
    const date = new Date(epochdate * 1000);
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric", // Change this to "numeric"
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  },

  formatedPrice: (price) => {
    if (price !== undefined && price !== null && price !== "") {
      const formattedPrice = `₹${price}`;
      return formattedPrice;
    }
    return "";
  },
  formattedStarCategory: (starCategory) => {
    if (
      starCategory !== undefined &&
      starCategory !== null &&
      starCategory !== ""
    ) {
      const formattedStar = `${starCategory} star`;
      return formattedStar;
    }
    return "";
  },

  formatTime: (timeString) => {
    if (!timeString) {
      return "";
    } else {
      const [hours, minutes] = timeString.split(":");
      const parsedHours = parseInt(hours, 10);
      const parsedMinutes = parseInt(minutes, 10);
      const period = parsedHours >= 12 ? "PM" : "AM";
      const formattedHours = parsedHours % 12 || 12;
      const formattedMinutes = String(parsedMinutes).padStart(2, "0");
      const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

      return formattedTime;
    }
  },
  fieldIsEmpty: (values = []) => {
    return values?.some(
      (value) => value === "" || value === undefined || value === null
    );
    // returns true if any of the fields is empty
  },
  decodeData: (data) => {
    try {
      return window.atob(data);
    } catch {
      return "";
    }
  },
  encodeData: (data) => {
    return window.btoa(data);
  },

  //this function is written to show time and date format like 28/12/2000 12:00 PM
  getFormattedDateAndTimeFromEpoch: (epoch) => {
    const dateObject = new Date(epoch * 1000);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedDateAndTime = `${day}/${month}/${year} ${formattedHours}:${minutes} ${amPm}`;
    return formattedDateAndTime;
  },
};

export default utilController;
