import React, { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import Backdrop from "../../../../../../components/Backdrop/Index";
import {
  Button,
  Combobox,
  Dropdown,
  Field,
  Input,
  Option,
} from "@fluentui/react-components";
import { Persona, PersonaInitialsColor, PersonaSize } from "@fluentui/react";
import FrontSideDrivingImg from "../FrontSideDrivingImg";
import BackSideDrivingImg from "../BacksideDrivingimg";
import { Grid, Typography } from "@mui/material";
import { DataContext } from "../AEVForm";
import { Stack } from "@fluentui/react";
import { ArrowUpload24Filled } from "@fluentui/react-icons";
import useUpload from "../../../../../../hooks/useUpload";
import { Delete20Regular } from "@fluentui/react-icons";
import { useTheme } from "@mui/styles";
import APIRequest from "../../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../../config/ConfigAPIURL";
import Status from "./UserStatus";
import { set } from "date-fns";
import Loader from "react-loader-spinner";
import LocalStorage from "../../../../../../config/LocalStorage";
import ConductCertificate from "../ConductCertificate";

const Details = (props) => {
  const {
    classes,
    theme,
    userForm,
    setUserForm,
    openForm,
    places,
    handleAddressChange,
    setCurrPlaceId,
    setPerPlaceId,
    setOpenForm,
    recordId,
    resetRecords,
    roles,
    services,
  } = React.useContext(DataContext);

  const [value, setValue] = useState(
    userForm?.currentLocationDetails?.fragmentedAddress || ""
  );
  const [passSetup, setPassSetup] = useState("******");
  const [isLoading, setLoader] = useState(false);
  const [permissionName, setPermissionName] = useState("");

  const deleteAadharCard = (index) => {
    setUserForm((prevState) => {
      const updatedAadharImages = [...prevState.adharImage];
      updatedAadharImages.splice(index, 1);

      return {
        ...prevState,
        adharImage: updatedAadharImages,
      };
    });
  };

  const uploadAadharFileFront = (props) => {
    APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      props
    ).then((response) => {
      if (response !== undefined && response !== null) {
        const attachmentUrl = {
          type: "",
          name: "",
          url: response.data.attachmentUrl[0],
          attachmentSide: "front",
        };

        setUserForm((p) => {
          return { ...p, adharImage: [...p.adharImage, attachmentUrl] };
        });

        setTimeout(function () {}, 1000);
      } else {
      }
    });
  };
  const uploadAadharFileBack = (props) => {
    APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      props
    ).then((response) => {
      if (response !== undefined && response !== null) {
        const attachmentUrl = {
          type: "",
          name: "",
          url: response.data.attachmentUrl[0],
          attachmentSide: "back",
        };

        setUserForm((p) => {
          return { ...p, adharImage: [...p.adharImage, attachmentUrl] };
        });

        setTimeout(function () {}, 1000);
      } else {
      }
    });
  };

  const uploadAadharImageFront = (event) => {
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      [...event.target.files].map(async (data) => {
        let formData = new FormData();
        // Add the file attachment and bucket name fields to the FormData object
        formData.append(`attachment`, data);
        formData.append("bucketName", "profile");
        uploadAadharFileFront(formData);
        // uploadAadharFileBack(formData);
        setLoader(true);
      });
    }
    setLoader(false);
  };
  const uploadAadharImageBack = (event) => {
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      [...event.target.files].map(async (data) => {
        let formData = new FormData();
        // Add the file attachment and bucket name fields to the FormData object
        formData.append(`attachment`, data);
        formData.append("bucketName", "profile");
        // uploadAadharFileFront(formData);
        uploadAadharFileBack(formData);
        setLoader(true);
      });
    }
    setLoader(false);
  };

  console.log(isLoading, "from loader");

  const uploadFile = (props) => {
    setLoader(true);
    APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      props
    ).then((response) => {
      if (response !== undefined && response !== null) {
        const attachmentUrl = response.data.attachmentUrl[0];
        if (attachmentUrl.length > 0) {
          setLoader(true);
        }

        setUserForm((prevData) => ({
          ...prevData,
          profileImage: attachmentUrl,
        }));
        if (userForm?.profileImage.length > 0) {
          setLoader(false);
        }

        setTimeout(function () {}, 1000);
      } else {
      }
      setLoader(false);
    });
  };

  const uploadProfileImage = (event) => {
    setLoader(true);
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      [...event.target.files].map(async (data) => {
        let formData = new FormData();
        console.log("upload data,", data);
        // Add the file attachment and bucket name fields to the FormData object
        formData.append(`attachment`, data);
        formData.append("bucketName", "profile");
        for (var pair of formData.entries()) {
          console.log(
            `${pair[0]}: ${pair[1]}uploadProfileImageuploadProfileImage`
          );
        }
        // Upload the file

        uploadFile(formData);
      });
      setLoader(false);
    }
  };

  const handleGenderClick = (gender) => {
    setUserForm({
      ...userForm,
      gender: gender,
    });
  };

  const handleExpiryDate = (date) => {
    if (date) {
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        licenseDetails: {
          ...prevUserForm.licenseDetails,
          expiryDate: unixTimestamp,
        },
      }));
    }
  };
  const handleDOB = (date) => {
    if (date) {
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        dob: unixTimestamp,
      }));
    }
  };

  useEffect(() => {
    handleAddressChange(userForm?.currentLocationDetails?.fragmentedAddress);
  }, [userForm?.currentLocationDetails?.fragmentedAddress]);

  useEffect(() => {
    handleAddressChange(userForm?.permanentAddress?.fragmentedAddress);
  }, [userForm?.permanentAddress?.fragmentedAddress]);

  const permissionNameSet = () => {
    const roleId = userForm?.permission;
    const matchingRole = roles?.find((role) => role?._id === roleId);

    if (matchingRole) {
      setPermissionName(matchingRole?.name);
    }
  };

  useEffect(() => {
    permissionNameSet();
  }, [userForm?.permission]);

  const formatLicenseNumber = (input) => {
    const formattedInput = input.substring(0, 16);

    const formattedLicenseNumber =
      formattedInput.substring(0, 2) +
      " " +
      formattedInput.substring(2, 4) +
      " " +
      formattedInput.substring(4, 8) +
      " " +
      formattedInput.substring(8);

    return formattedLicenseNumber;
  };

  const bloodGroup = [
    "A+",
    "A-",
    "B+",
    "B-",
    "AB+",
    "AB-",
    "O+",
    "O-",
    "A1+",
    "A1-",
    "A2+",
    "A2-",
    "A1B+",
    "A1B-",
    "A2B+",
    "A2B-",
  ];
  const editRolePermission = LocalStorage.adminButtonPermission.find(function (
    element
  ) {
    return element.button === "EditData" ? true : false;
  });

  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        spacing={theme.spacingArea.xLarge}
      >
        <Grid item xs={12}>
          <div>
            <Backdrop isOpen={isLoading} />
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  overflow: "visible",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f2f2f2",
                  position: "relative",
                  cursor: "default",
                }}
              >
                {userForm?.profileImage ? (
                  <img
                    src={userForm?.profileImage}
                    alt="Uploaded Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <Persona
                    size={PersonaSize.size100}
                    initialsColor={PersonaInitialsColor.coolGray}
                  />
                )}

                <CameraAltIcon
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    color: "#000",
                    backgroundColor: "#FAFAFB",
                    fontSize: "30px",
                    padding: "4px",
                    borderRadius: "50%",
                    boxShadow: " 0px 1px 4px rgba(0, 0, 0, 0.25)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("fileInput").click();
                  }}
                />
              </div>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={uploadProfileImage}
                  id="fileInput"
                  style={{ display: "none" }}
                />
              </div>
              {openForm?.divType === "edit" &&
                userForm?.userType === "driver" && (
                  <Status
                    userForm={userForm}
                    openForm={openForm}
                    setOpenForm={setOpenForm}
                    recordId={recordId}
                    resetRecords={resetRecords}
                  />
                )}
            </Grid>
          </div>
        </Grid>

        <Grid item xs={4}>
          <Field className={classes.label} label="User Type">
            <Dropdown
              required
              disabled={
                // userForm?.userType !== "admin" &&
                // LocalStorage.userDetails.userType === "admin"
                // userForm?.active === true ||
                // editRolePermission === undefined ||
                openForm?.divType === "edit" &&
                userForm?.active === true &&
                editRolePermission === undefined
              }
              className={`${classes.input} input__Style`}
              style={{
                borderRadius: "8px",
                minWidth: "100%",
                height: "45px",
                outline: "none",
                display: "flex",
                alignItems: "center",
              }}
              value={
                userForm?.userType === "user"
                  ? "Customer"
                  : userForm?.userType.charAt(0).toUpperCase() +
                      userForm?.userType.slice(1) || ""
              }
            >
              <Option
                onClick={(event) => {
                  setUserForm({
                    ...userForm,
                    userType: "admin",
                  });
                }}
              >
                Admin
              </Option>

              {/* below are the options for drivers and customer */}

              {editRolePermission && (
                <>
                  <Option
                    onClick={(event) => {
                      setUserForm({
                        ...userForm,
                        userType: "driver",
                      });
                    }}
                  >
                    Driver
                  </Option>
                  <Option
                    onClick={(event) => {
                      setUserForm({
                        ...userForm,
                        userType: "user",
                      });
                    }}
                  >
                    Customer
                  </Option>
                </>
              )}
              {userForm?.active === false && (
                <>
                  <Option
                    onClick={(event) => {
                      setUserForm({
                        ...userForm,
                        userType: "driver",
                      });
                    }}
                  >
                    Driver
                  </Option>
                  <Option
                    onClick={(event) => {
                      setUserForm({
                        ...userForm,
                        userType: "user",
                      });
                    }}
                  >
                    Customer
                  </Option>
                </>
              )}
            </Dropdown>
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field className={classes.label} label="Active">
            <Dropdown
              required
              // disabled={
              //    userForm?.active === true
              // }
              className={`${classes.input} input__Style`}
              style={{
                borderRadius: "8px",
                minWidth: "100%",
                height: "45px",
                outline: "none",
                display: "flex",
                alignItems: "center",
              }}
              value={userForm?.active ? "Yes" : "No"}
            >
              <Option
                onClick={(event) => {
                  setUserForm({
                    ...userForm,
                    active: true,
                  });
                }}
              >
                Yes
              </Option>

              <Option
                onClick={(event) => {
                  setUserForm({
                    ...userForm,
                    active: false,
                  });
                }}
              >
                No
              </Option>
            </Dropdown>
          </Field>
        </Grid>
        {userForm?.userType === "admin" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Superadmin">
              <Dropdown
                disabled={
                  // userForm?.userType !== "admin" &&
                  // LocalStorage.userDetails.userType === "admin"
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                className={`${classes.input} input__Style`}
                style={{
                  borderRadius: "8px",
                  minWidth: "100%",
                  height: "45px",
                  outline: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                value={userForm?.isSuperAdmin ? "Yes" : "No"}
              >
                {["Yes", "No"].map((option) => (
                  <Option
                    onClick={(event) => {
                      setUserForm({
                        ...userForm,
                        isSuperAdmin: option === "Yes" ? true : false,
                      });
                    }}
                  >
                    {option}
                  </Option>
                ))}
              </Dropdown>
            </Field>
          </Grid>
        )}
        {userForm?.userType === "admin" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Permission">
              <Dropdown
                disabled={
                  // userForm?.userType !== "admin" &&
                  // LocalStorage.userDetails.userType === "admin"
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                style={{
                  border: "1px solid #E5E5E5",
                  borderRadius: "8px",
                  width: "100%",
                  height: "45px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  outline: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                value={
                  permissionName.charAt(0).toUpperCase() +
                  permissionName.slice(1)
                }
              >
                {roles &&
                  roles?.map((role) => (
                    <Option
                      onClick={(event) => {
                        setUserForm({
                          ...userForm,
                          permission: role?._id,
                        });
                        setPermissionName(role?.name);
                      }}
                    >
                      {role?.name}
                    </Option>
                  ))}
              </Dropdown>
            </Field>
          </Grid>
        )}
        <Grid item xs={4}>
          <Field className={classes.label} label="First Name" required>
            <Input
              disabled={
                // userForm?.userType !== "admin" &&
                // LocalStorage.userDetails.userType === "admin"
                editRolePermission === undefined && openForm?.divType === "edit"
              }
              size="large"
              className={`${classes.input} input__Style`}
              placeholder=""
              value={userForm?.fname || ""}
              onChange={(e) =>
                setUserForm({
                  ...userForm,
                  fname: e.target.value,
                })
              }
            />
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field className={classes.label} label="Last Name" required>
            <Input
              disabled={
                // userForm?.userType !== "admin" &&
                // LocalStorage.userDetails.userType === "admin"
                editRolePermission === undefined && openForm?.divType === "edit"
              }
              size="large"
              className={`${classes.input} input__Style`}
              placeholder=""
              value={userForm?.lname || ""}
              onChange={(e) =>
                setUserForm({
                  ...userForm,
                  lname: e.target.value,
                })
              }
            />
          </Field>
        </Grid>

        {userForm?.userType === "driver" && (
          <Grid item xs={4}>
            <Field
              className={classes.label}
              label="Year Of Experience"
              required
            >
              <Input
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={userForm?.experience || ""}
                onChange={(e) => {
                  const enteredValue = e.target.value;
                  const truncatedValue = enteredValue.slice(0, 2);
                  setUserForm({ ...userForm, experience: truncatedValue });
                }}
              />
            </Field>
          </Grid>
        )}
        <Grid item xs={4}>
          <Field className={classes.label} label="Gender" required>
            <div
              style={{
                display: "flex",
                gap: "7px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                onClick={
                  // userForm?.userType !== "admin" &&
                  // LocalStorage.userDetails.userType === "admin"
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                    ? () => console.log("nothing")
                    : () => handleGenderClick("Male")
                }
                style={{
                  width: "114px",
                  height: "48px",
                  flexShrink: "0",
                  borderRadius: "8px",
                  border: "1px solid #E5E5E5",
                  background: userForm?.gender === "Male" ? "#268581" : "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor:
                    editRolePermission === undefined &&
                    openForm?.divType === "edit"
                      ? ""
                      : "pointer",
                }}
              >
                <Typography
                  style={{
                    color: userForm?.gender === "Male" ? "#fff" : "#000",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: userForm?.gender === "Male" ? 600 : 400,
                    lineHeight: "normal",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Male
                </Typography>
              </div>
              <div
                onClick={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                    ? () => console.log("nothing")
                    : () => handleGenderClick("Female")
                }
                style={{
                  width: "114px",
                  height: "48px",
                  flexShrink: "0",
                  borderRadius: "8px",
                  border: "1px solid #E5E5E5",
                  background:
                    userForm?.gender === "Female" ? "#268581" : "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor:
                    editRolePermission === undefined &&
                    openForm?.divType === "edit"
                      ? ""
                      : "pointer",
                }}
              >
                <Typography
                  style={{
                    color: userForm?.gender === "Female" ? "#fff" : "#000",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: userForm?.gender === "Female" ? 600 : 400,
                    lineHeight: "normal",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Female
                </Typography>
              </div>
              <div
                onClick={
                  // userForm?.userType !== "admin" &&
                  // LocalStorage.userDetails.userType === "admin"
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                    ? () => console.log("nothing")
                    : () => handleGenderClick("Other")
                }
                style={{
                  width: "114px",
                  height: "48px",
                  flexShrink: "0",
                  borderRadius: "8px",
                  border: "1px solid #E5E5E5",
                  background: userForm?.gender === "Other" ? "#268581" : "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor:
                    editRolePermission === undefined &&
                    openForm?.divType === "edit"
                      ? ""
                      : "pointer",
                }}
              >
                <Typography
                  style={{
                    color: userForm?.gender === "Other" ? "#fff" : "#000",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: userForm?.gender === "Other" ? 600 : 400,
                    lineHeight: "normal",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Others
                </Typography>
              </div>
            </div>
          </Field>
        </Grid>

        {userForm?.userType !== "admin" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="DOB">
              <DatePicker
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                showWeekNumbers={false}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay={true}
                className={`${classes.input} input__Style`}
                placeholder="Select Date Of Birth...."
                style={{ height: "45px" }}
                value={userForm?.dob ? new Date(userForm?.dob * 1000) : null}
                onSelectDate={handleDOB}
                formatDate={(date) => {
                  const day = date.getDate().toString().padStart(2, "0");
                  const month = (date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = date.getFullYear();
                  return `${day}/${month}/${year}`;
                }}
                {...props}
              />
            </Field>
          </Grid>
        )}

        <Grid item xs={4}>
          {userForm?.userType === "user" ? (
            <Field className={classes.label} label="Email">
              <Input
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={userForm?.email || ""}
                onChange={(e) =>
                  setUserForm({ ...userForm, email: e.target.value })
                }
              />
            </Field>
          ) : (
            <Field className={classes.label} label="Email" required>
              <Input
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={userForm?.email || ""}
                onChange={(e) =>
                  setUserForm({ ...userForm, email: e.target.value })
                }
              />
            </Field>
          )}
        </Grid>

        <Grid item xs={4}>
          <Field className={classes.label} label="Mobile Number" required>
            <Input
              disabled={
                editRolePermission === undefined && openForm?.divType === "edit"
              }
              size="large"
              className={`${classes.input} input__Style`}
              placeholder=""
              value={userForm?.mobileNo || ""}
              onChange={(e) => {
                const inputText = e.target.value;
                const digitsOnly = inputText.replace(/\D/g, "");
                const limitedText = digitsOnly.slice(0, 10);
                setUserForm({ ...userForm, mobileNo: limitedText });
              }}
            />
          </Field>
        </Grid>

        {userForm?.userType !== "admin" && userForm?.userType !== "user" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Emergency Number" required>
              <Input
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={userForm?.alternativeNo || ""}
                onChange={(e) => {
                  const inputText = e.target.value;
                  const digitsOnly = inputText.replace(/\D/g, "");
                  const limitedText = digitsOnly.slice(0, 10);
                  setUserForm({ ...userForm, alternativeNo: limitedText });
                }}
              />
            </Field>
          </Grid>
        )}

        {userForm?.userType === "user" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Alternate Number" required>
              <Input
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={userForm?.alternativeNo || ""}
                onChange={(e) => {
                  const inputText = e.target.value;
                  const digitsOnly = inputText.replace(/\D/g, "");
                  const limitedText = digitsOnly.slice(0, 10);
                  setUserForm({ ...userForm, alternativeNo: limitedText });
                }}
              />
            </Field>
          </Grid>
        )}
        {userForm?.userType !== "admin" && userForm?.userType !== "user" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Current Address" required>
              <Combobox
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                placeholder="Select Location"
                freeform={true}
                style={{ height: "45px", minWidth: "100%" }}
                onInput={(e) => {
                  setUserForm((prevUserForm) => ({
                    ...prevUserForm,
                    currentLocationDetails: {
                      ...prevUserForm.currentLocationDetails,

                      fragmentedAddress: e.target.value,
                    },
                  }));
                }}
                className={`${classes.input} input__Style`}
                onOptionSelect={(event, value) => {
                  setUserForm((prevUserForm) => ({
                    ...prevUserForm,
                    currentLocationDetails: {
                      ...prevUserForm.currentLocationDetails,

                      fragmentedAddress: value?.optionValue,
                    },
                  }));
                  setValue(value?.optionValue);
                }}
                value={userForm?.currentLocationDetails?.fragmentedAddress}
              >
                {places.map((option) => (
                  <Option
                    key={option}
                    onClick={() => setCurrPlaceId(option?.place_id)}
                  >
                    {option?.description}
                  </Option>
                ))}
              </Combobox>
            </Field>
          </Grid>
        )}
        {userForm?.userType !== "admin" && userForm?.userType !== "user" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Permanent Address" required>
              <Combobox
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                placeholder="Select Location"
                freeform={true}
                style={{ height: "45px", minWidth: "100%" }}
                onInput={(e) => {
                  setUserForm((prevUserForm) => ({
                    ...prevUserForm,
                    permanentAddress: {
                      ...prevUserForm.permanentAddress,

                      fragmentedAddress: e.target.value,
                    },
                  }));
                }}
                // selectedOptions={places}
                value={userForm?.permanentAddress?.fragmentedAddress}
                className={`${classes.input} input__Style`}
                onOptionSelect={(event, value) => {
                  setUserForm((prevUserForm) => ({
                    ...prevUserForm,
                    permanentAddress: {
                      ...prevUserForm.permanentAddress,

                      fragmentedAddress: value?.optionValue,
                    },
                  }));
                }}
              >
                {places.map((option) => (
                  <Option
                    key={option}
                    onClick={() => setPerPlaceId(option?.place_id)}
                  >
                    {option?.description}
                  </Option>
                ))}
              </Combobox>
            </Field>
          </Grid>
        )}
        {userForm?.userType === "user" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Address" required>
              <Combobox
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                placeholder="Select Location"
                freeform={true}
                style={{ height: "45px", minWidth: "100%" }}
                onInput={(e) => {
                  setUserForm((prevUserForm) => ({
                    ...prevUserForm,
                    permanentAddress: {
                      ...prevUserForm.permanentAddress,

                      fragmentedAddress: e.target.value,
                    },
                  }));
                }}
                // selectedOptions={places}
                value={userForm?.permanentAddress?.fragmentedAddress}
                className={`${classes.input} input__Style`}
                onOptionSelect={(event, value) => {
                  setUserForm((prevUserForm) => ({
                    ...prevUserForm,
                    permanentAddress: {
                      ...prevUserForm.permanentAddress,

                      fragmentedAddress: value?.optionValue,
                    },
                  }));
                }}
              >
                {places.map((option) => (
                  <Option
                    key={option}
                    onClick={() => setPerPlaceId(option?.place_id)}
                  >
                    {option?.description}
                  </Option>
                ))}
              </Combobox>
            </Field>
          </Grid>
        )}
        {userForm?.userType === "driver" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Aadhaar Number" required>
              <Input
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={(userForm?.adharNumber || "")
                  .toString()
                  .replace(/(\d{4})/g, "$1 ")
                  .trim()} // Format for display
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/\D/g, "");
                  const formattedValue = numericValue.slice(0, 12).trim();
                  setUserForm({
                    ...userForm,
                    adharNumber: formattedValue,
                  });
                }}
              />
            </Field>
          </Grid>
        )}
        {userForm?.userType === "driver" && (
          <Grid item xs={4}>
            <Field
              className={classes.label}
              label="Driving License Number"
              required
            >
              <Input
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={(userForm?.licenseDetails?.drivingLicenseNumber || "")
                  .toString()
                  .replace(/(\d{2})(\d{2})(\d{4})(\d{7})/, "$1 $2 $3 $4")
                  .trim()}
                onChange={(e) => {
                  const input = e.target.value
                    .toUpperCase()
                    .replace(/[^A-Z0-9]/g, "");
                  const formattedInput = input
                    .replace(/(.{2})(.{2})(.{4})(.{7})/, "$1 $2 $3 $4")
                    .trim()
                    .slice(0, 18); // Limit to 18 characters
                  setUserForm({
                    ...userForm,
                    licenseDetails: {
                      ...userForm?.licenseDetails,
                      drivingLicenseNumber: formattedInput,
                    },
                  });
                }}
              />
            </Field>
          </Grid>
        )}
        {userForm?.userType === "driver" && (
          <Grid item xs={4}>
            <Field
              className={classes.label}
              label="Driving License Expiry Date"
              required
            >
              <DatePicker
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                showWeekNumbers={false}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay={true}
                className={`${classes.input} input__Style`}
                placeholder="Select End date"
                style={{ height: "45px" }}
                value={
                  userForm?.licenseDetails?.expiryDate
                    ? new Date(userForm?.licenseDetails?.expiryDate * 1000)
                    : null
                }
                onSelectDate={handleExpiryDate}
                {...props}
              />
            </Field>
          </Grid>
        )}

        {userForm?.userType === "driver" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Blood Group">
              <Dropdown
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                className={`${classes.input} input__Style`}
                style={{
                  border: "2px solid #E5E5E5",
                  borderRadius: "8px",
                  width: "100%",
                  height: "45px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  outline: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                value={userForm?.bloodGroup || ""}
              >
                {bloodGroup.map((blood) => (
                  <Option
                    onClick={(event) => {
                      setUserForm({
                        ...userForm,
                        bloodGroup: blood,
                      });
                    }}
                  >
                    {blood}
                  </Option>
                ))}
              </Dropdown>
            </Field>
          </Grid>
        )}

        {userForm?.userType === "driver" &&
          userForm?.userType !== "customer" && (
            <Grid item xs={3.4}>
              <ConductCertificate
                userForm={userForm}
                setUserForm={setUserForm}
                label="Conduct Certificate (Optional)"
                classes={classes}
                editRolePermission={editRolePermission}
              />
            </Grid>
          )}

        {/* {userForm?.userType === "driver" && <Grid item xs={5}></Grid>} */}

        {userForm?.userType === "driver" && (
          <Grid item xs={3}>
            <Backdrop isOpen={isLoading} />

            <Stack style={{ gap: "4px", width: "100%" }}>
              <Field
                className={classes.label}
                label="Aadhaar Card Image (Front)"
              />
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{
                  background: "white",
                  padding: "16px",
                  height: "142px",
                  borderRadius: "8px",
                  gap: "8px",
                  border: "1px solid #E5E5E5",
                  overflow: "auto",
                }}
              >
                <Stack horizontal className={classes.gapSmall}>
                  {/* image array */}
                  {userForm?.adharImage &&
                    userForm?.adharImage?.map(
                      (value, i) =>
                        value?.url !== null &&
                        value?.attachmentSide === "front" && (
                          <Stack style={{ position: "relative" }} key={i}>
                            <Stack
                              verticalAlign="center"
                              horizontalAlign="center"
                              style={{
                                width: "28px",
                                height: "28px",
                                background: "white",
                                position: "absolute",
                                borderRadius: "100%",
                                top: -8,
                                right: -5,
                                cursor: "pointer",
                                display: editRolePermission ? "" : "none",
                              }}
                            >
                              <Delete20Regular
                                onClick={() => deleteAadharCard(i)}
                                className="deleteIcon"
                                style={{
                                  color: theme.palette.primary.main,
                                }}
                              />
                            </Stack>

                            <img
                              style={{
                                borderRadius: "10px",
                                width: "117px",
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const newWindow = window.open("", "_blank");
                                if (newWindow) {
                                  const imgElement = new Image();
                                  imgElement.onload = function () {
                                    newWindow.resizeTo(
                                      1000,
                                      (imgElement.height / imgElement.width) *
                                        1000
                                    );
                                    imgElement.style.width = "1000px";
                                    newWindow.document.body.appendChild(
                                      imgElement
                                    );
                                  };
                                  imgElement.src = value?.url;
                                } else {
                                  console.error("Failed to open new window");
                                }
                              }}
                              key={i}
                              src={value?.url}
                              alt="packageimage"
                            />
                          </Stack>
                        )
                    )}
                </Stack>

                {userForm?.adharImage?.every(
                  (value) => value?.attachmentSide !== "front"
                ) && (
                  <Stack>
                    <label htmlFor="file2">
                      <ArrowUpload24Filled style={{ cursor: "pointer" }} />
                    </label>

                    <input
                      accept=".png, .jpg, .jpeg"
                      multiple
                      id="file2"
                      type="file"
                      style={{ display: "none" }}
                      onChange={uploadAadharImageFront}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
        )}

        {userForm?.userType === "driver" && (
          <Grid item xs={3}>
            <Backdrop isOpen={isLoading} />

            <Stack
              // className={classes.distinct}
              style={{ gap: "4px", width: "100%" }}
            >
              <Field
                className={classes.label}
                label="Aadhaar Card Image (Back)"
              />
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{
                  background: "white",
                  padding: "16px",
                  height: "142px",
                  borderRadius: "8px",
                  gap: "8px",
                  border: "1px solid #E5E5E5",
                  overflow: "auto",
                }}
              >
                <Stack horizontal className={classes.gapSmall}>
                  {/* image array */}
                  {userForm?.adharImage &&
                    userForm?.adharImage?.map(
                      (value, i) =>
                        value?.url !== null &&
                        value?.attachmentSide === "back" && (
                          <Stack style={{ position: "relative" }}>
                            <Stack
                              verticalAlign="center"
                              horizontalAlign="center"
                              style={{
                                width: "28px",
                                height: "28px",
                                background: "white",
                                position: "absolute",
                                borderRadius: "100%",
                                top: -8,
                                right: -5,
                                cursor: "pointer",
                                display: editRolePermission ? "" : "none",
                              }}
                            >
                              {editRolePermission && (
                                <Delete20Regular
                                  onClick={() => deleteAadharCard(i)}
                                  className="deleteIcon"
                                  style={{
                                    color: theme.palette.primary.main,
                                  }}
                                />
                              )}
                            </Stack>

                            <img
                              style={{
                                borderRadius: "10px",
                                width: "117px",
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const newWindow = window.open("", "_blank");
                                if (newWindow) {
                                  const imgElement = new Image();
                                  imgElement.onload = function () {
                                    newWindow.resizeTo(
                                      1000,
                                      (imgElement.height / imgElement.width) *
                                        1000
                                    );
                                    imgElement.style.width = "1000px";
                                    newWindow.document.body.appendChild(
                                      imgElement
                                    );
                                  };
                                  imgElement.src = value?.url;
                                } else {
                                  console.error("Failed to open new window");
                                }
                              }}
                              key={i}
                              src={value?.url}
                              alt="packageimage"
                            />
                          </Stack>
                        )
                    )}
                </Stack>
                <Stack>
                  {userForm?.adharImage?.every(
                    (value) => value?.attachmentSide !== "back"
                  ) && (
                    <Stack>
                      <label htmlFor="file1">
                        <ArrowUpload24Filled style={{ cursor: "pointer" }} />
                      </label>

                      <input
                        accept=".png, .jpg, .jpeg"
                        multiple
                        id="file1"
                        type="file"
                        style={{ display: "none" }}
                        onChange={uploadAadharImageBack}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        )}
        {userForm?.userType === "driver" && (
          <Grid item xs={3}>
            <FrontSideDrivingImg
              userForm={userForm}
              setUserForm={setUserForm}
              label="Driving License Image (Front)"
              classes={classes}
              editRolePermission={editRolePermission}
            />
          </Grid>
        )}

        {userForm?.userType === "driver" && (
          <Grid item xs={3}>
            <BackSideDrivingImg
              userForm={userForm}
              setUserForm={setUserForm}
              label="Driving License Image (Back)"
              classes={classes}
              editRolePermission={editRolePermission}
            />
          </Grid>
        )}

        {userForm?.userType === "admin" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Password">
              <Input
                disabled={
                  editRolePermission === undefined &&
                  openForm?.divType === "edit"
                }
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                value={passSetup}
                onChange={(e) => {
                  setUserForm({
                    ...userForm,
                    password: e.target.value,
                  });
                  setPassSetup(e.target.value);
                }}
              />
            </Field>
            {openForm?.divType === "edit" && (
              <div style={{ padding: "10px" }}>
                <Typography
                  style={{
                    color: "#268581",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => services?.resetPassAttempts(recordId)}
                >
                  Reset password attempts
                </Typography>
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Details;
