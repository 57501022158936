class ConfigAPIURL {
  //Base URL For Live & Staging
  static baseUrl =
    process.env.REACT_APP_PayDefination_BaseUrl ||
    process.env.REACT_APP_Dev_PayDefination_BaseUrl;
  // static baseUrl = "http://localhost:4000";
  // static baseUrl = "http://35.154.184.255:4001";
  // static baseUrl = "http://2ndsmart.com";
  // process.env.REACT_APP_PayDefination_BaseUrl || process.env.REACT_APP_Dev_PayDefination_BaseUrl;
  // static baseUrl = "https://flyden.visitxpert.com";
  // static baseUrl = "http://43.205.230.123:4000";
  // static baseUrl = "https://3372akafqg.execute-api.ap-south-1.amazonaws.com";
  // static baseUrl = "https://r65ksxdf-4000.inc1.devtunnels.ms";
  // static baseUrl = "http://43.205.230.123:4000";
  // static baseUrl="http://15.207.107.56"

  //Upload API
  // static uploadFile = this.baseUrl + "/api" + "/upload/file";

  //List of Pay Defination APIs and request method type
  static sessionValidation = this.baseUrl + "/admin/islogin"; //get
  static accountLogin = this.baseUrl + "/admin/accountLogin";
  static verifyOtp = this.baseUrl + "/admin/verify/otp";
  static resendOtp = this.baseUrl + "/admin/resend/otp";
  static adminLogout = this.baseUrl + "/admin/logout";
  static adminGeneratePassword = this.baseUrl + "/admin/password/generate";
  static forgotPassword = this.baseUrl + "/admin/forgot/password";
  static resetPassword = this.baseUrl + "/admin/reset/password";

  static uploadFile = this.baseUrl + "/user/upload/file";


  //forgot password page

  static getOtp = this.baseUrl + "/admin/forgot/password/otp";
  static verifyOtpForgot = this.baseUrl + "/admin/verify/otp";

  //user's page API here

  static fetchUsers = this.baseUrl + "/admin/user/all";
  static createUser = this.baseUrl + "/admin/user/add";
  static userDetail = this.baseUrl + "/admin/user/details";
  static userUpdate = this.baseUrl + "/admin/user/update";

  //Booking's page API here

  static fetchBookings = this.baseUrl + "/admin/booking/all"; //Post
  static bookingsDetail = this.baseUrl + "/admin/booking/details"; //Post

  //Tariff's page API
  static fetchAllTariff = this.baseUrl + "/admin/tariff/all"; //Post
  static tariffDetail = this.baseUrl + "/admin/tariff/details"; //Post
  static createTariff = this.baseUrl + "/admin/tariff/create"; //Post
  static updateTariff = this.baseUrl + "/admin/tariff/update"; //Post

  //Users's page subscription API here

  static getSubscriptionHistory =
    this.baseUrl + "/admin/user/subscription/history"; //Post
  static subscribePlan =
    this.baseUrl + "/admin/user/subscription/plan/subscribe"; //Post
  static getAllPlans = this.baseUrl + "/admin/user/subscription/plan/all"; //Post

  //Roles related apis

  static fetchRoles = this.baseUrl + "/admin/roles/all";
  static createRole = this.baseUrl + "/admin/role/create";
  static roleTitle = this.baseUrl + "/admin/roles/titles";
  static roleUpdate = this.baseUrl + "/admin/role/permission/update";
  static roleDetails = this.baseUrl + "/admin/role/details";
  static roleUnassigned = this.baseUrl + "/admin/role/permission/unassigned";
  static roleGrant = this.baseUrl + "/admin/role/permission/grant";

  //Dashboard
  static dashboardCardStats = this.baseUrl + "/admin/dashboard/stats"; //Get
  static pieChartStats = this.baseUrl + "/admin/dashboard/users/count"; //Get
  static revenueBarGraph =
    this.baseUrl + "/admin/dashboard/subscription/revenue"; //Get
  static bookingsGraph = this.baseUrl + "/admin/dashboard/booking/revenue"; //Get

  //plans related apis

  static fetchPlans = this.baseUrl + "/admin/subscription/plan/all";
  static createPlan = this.baseUrl + "/admin/subscription/plan/create";
  static planDetails = this.baseUrl + "/admin/subscription/plan/details";
  static updatePlan = this.baseUrl + "/admin/subscription/plan/update";

  //Google API Location Integration Search

  static getLocation = this.baseUrl + "/admin/location/search";
  static getCordinates = this.baseUrl + "/admin/location/coordinates";

  //state and city related apis

  static fetchStates = this.baseUrl + "/admin/state/list"; //POST

  //For Creating, Updating, Fetching all coupons

  static createCoupon = this.baseUrl + "/admin/coupon/create"; // POST
  static updateCoupon = this.baseUrl + "/admin/coupon/update"; // POST
  static fetchAllCoupons = this.baseUrl + "/admin/coupon/all"; // POST
  static couponDetails = this.baseUrl + "/admin/coupon/details"; // POST


  //Reviews related apis
  static fetchReviewCount = this.baseUrl + "/admin/user/review/count"; // POST
  static fetchReviews = this.baseUrl + "/admin/user/review/all"; // POST
}

export default ConfigAPIURL;
