import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Typography from "../../../../../components/Text/Typography";

const CustomTooltip = ({ active, payload, label, dashboardFilter }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          // width: "159px",
          height: "100%",
          boxShadow: "0px 8px 22px 0px rgba(0, 0, 0, 0.07)",
          //   padding: "15px",
          background: "var(--Dark-color, #081735)",
          borderRadius: "10px",
          padding: "5px 14px",
        }}
      >
        <div>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#fff",
              fontWeight: 700,
            }}
          >
            {dashboardFilter.requestType === "revenue"
              ? `Amount : ₹${payload[0].value}`
              : `Count : ${payload[0].value}`}
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};

function BarGraph(props) {
  const { services, dashboardFilter } = props;
  console.log("barGraphData", services?.barGraphData);
  return (
    <ResponsiveContainer width="100%" height="70%">
      <BarChart
        // width={500}

        // height={300}
        data={services?.barGraphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 35,
        }}
        barSize={30}
      >
        {dashboardFilter?.dateType === "week" ? (
          <XAxis
            dataKey="week"
            scale="point"
            padding={{ left: 20, right: 10 }}
          />
        ) : (
          <></>
        )}
        {dashboardFilter?.dateType === "year" ? (
          <XAxis
            dataKey="year"
            scale="point"
            padding={{ left: 20, right: 10 }}
          />
        ) : (
          <></>
        )}
        {dashboardFilter?.dateType === "month" ? (
          <XAxis
            dataKey="month"
            scale="point"
            padding={{ left: 20, right: 10 }}
          />
        ) : (
          <></>
        )}
        {dashboardFilter?.dateType === "day" ? (
          <XAxis
            dataKey="day"
            scale="point"
            padding={{ left: 20, right: 10 }}
          />
        ) : (
          <></>
        )}
        <YAxis dataKey="totalCount" />
        <Tooltip
          content={<CustomTooltip dashboardFilter={dashboardFilter} />}
        />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          dataKey="totalCount"
          fill="#B8B7FF"
          background={{ fill: "#fff" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarGraph;
