import React from "react";
import { CSVLink } from "react-csv";
import { ExportToCsv } from "export-to-csv";
import { enqueueSnackbar } from "notistack";
import useAlert from "../../../../../hooks/useAlert";
import { useMediaQuery } from "@mui/material";

const useTableFunctions = ({
  downloadCsv,
  viewColumn,
  setOpenForm,
  selectedRows,
  setSelectedRows,
}) => {
  const isLargeScreen = useMediaQuery("(min-width: 1084px)");
  const width = isLargeScreen ? "82.5%" : "75.2%";
  const { publishNotification, closeSnackbar } = useAlert();
  const handleCsvExport = () => {
    // downloadcsv function whih is from useDownload hook gives the data of items to be downloaded
    const { data, headers } = downloadCsv(viewColumn);
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "",
      useTextFile: false,
      useBom: true,
      // useKeysAsHeaders: true,
      headers, //<-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  const add = () => {
    if (selectedRows?.length >0) {
      return publishNotification(
        `Please unselect all Items from the table`,
        "error"
      );
    }
    setOpenForm((p) => {
      return {
        ...p,
        isOpen: true,
        divType: "add",
        title: "Create tarif",
        width: width,
        hasCloseButton: false,
      };
    });
  };
  const edit = () => {
    if (selectedRows?.length === 0 || selectedRows?.length > 1) {
      return publishNotification(
        `Please select one Item from the table`,
        "error"
      );
    }
    setOpenForm((p) => {
      return {
        ...p,
        isOpen: true,
        divType: "edit",
        title: "Edit tarif",
        rowDetails: selectedRows[0]?._id,
        width: width,
        hasCloseButton: false,
      };
    });
  };
  const view = () => {
    if (selectedRows?.length === 0 || selectedRows?.length > 1) {
      return publishNotification(
        `Please select one Item from the table`,
        "error"
      );
    }
    setOpenForm((p) => {
      return {
        ...p,
        isOpen: true,
        divType: "view",
        title: "View tarif",
        rowDetails: selectedRows[0]?._id,
        width: width,
        hasCloseButton: false,
      };
    });
  };
  const handleDelete = () => {
    if (selectedRows?.length === 0) {
      return publishNotification(
        `Please select one Item from the table`,
        "error"
      );
    }
    setOpenForm((p) => {
      return {
        ...p,
        isDialogOpen: true,
        divType: "deleteDialog",
        title: "Delete Record",
        rowDetails: selectedRows,
      };
    });
  };
  const viewFilter = () => {
    setOpenForm((p) => {
      return {
        ...p,
        isOpen: true,
        divType: "filter",
        title: "",
        description: "",
        width: 500,
        hasCloseButton: true,
      };
    });
  };

  const editColumn = () => {
    setOpenForm((p) => {
      return {
        ...p,
        isOpen: true,
        divType: "column",
        title: "",
        description: "",
        width: 500,
        hasCloseButton: false,
      };
    });
  };
  return {
    handleCsvExport,
    add,
    viewFilter,
    editColumn,
    edit,
    handleDelete,
    view,
  };
};

export default useTableFunctions;
