import React, { useEffect, useState } from "react";
import {
  Button,
  Combobox,
  Field,
  Input,
  Option,
  Textarea,
} from "@fluentui/react-components";
import { Grid, Typography } from "@mui/material";
import { DataContext } from "./AEVForm";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import useAlert from "../../../../../hooks/useAlert";

const PlanDetails = (props) => {
  const { recordId } = props;
  const { classes, theme, userForm, setUserForm, services, openForm,resetRecords } =
    React.useContext(DataContext);

  const { publishNotification } = useAlert();


  return (
    <>
      <Grid
        container
        spacing={theme.spacingArea.xLarge}
        marginTop={theme.spacingArea.xLarge}
        // paddingLeft={"20px"}
        style={{background:"#fff"}}
      >
        <Grid item xs={12}>
          <Grid container spacing={theme.spacingArea.xLarge}>
            <Grid item xs={6}>
              <Field className={classes.label} label="Plan Name" required>
                <Input
                  autoFocus
                  type="text"
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.planName}
                  onChange={(e) =>
                    setUserForm({ ...userForm, planName: e.target.value })
                  }
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field className={classes.label} label="Recharge Amount" required>
                <Input
                type="number"
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.planAmount}
                  onChange={(e) =>
                    setUserForm({ ...userForm, planAmount: e.target.value })
                  }
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field className={classes.label} label="Ride Earning Amount" required>
                <Input
                  type="number"
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.validityAmount}
                  onChange={(e) =>
                    setUserForm({ ...userForm, validityAmount: e.target.value })
                  }
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field className={classes.label} label="Discount (%)" required>
                <Input
                  type="number"
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.discountInPercent}
                  onChange={(e) =>
                    setUserForm({
                      ...userForm,
                      discountInPercent: e.target.value,
                    })
                  }
                />
              </Field>
            </Grid>
            {/* <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                style={{
                  borderRadius: "34px",
                  background: "#06381C",
                  width: "132px",
                  height: "39px",
                }}
                onClick={() =>
                  services?.sendToServer(userForm, openForm?.divtype === "edit")
                }
              >
                <Typography
                  style={{
                    color: "#FFF",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                 {openForm?.divType === "edit" ? "Update Plan" : "Create Plan"}
                </Typography>
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PlanDetails;
