import React, { useEffect, useState } from "react";
import { Field, Input, Textarea } from "@fluentui/react-components";
import { Grid, Stack, Typography } from "@mui/material";
import { DataContext } from "../../AEVForm";
import BoxContainer from "../../../../../../../components/Box/Index";
import { Delete24Filled } from "@fluentui/react-icons";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const LocalTrip = (props) => {
  const { classes, theme, userForm, setUserForm } =
    React.useContext(DataContext);
  const [oneWayDelete, setOneWayDelete] = useState(null);
  const [roundTripDelete, setRoundTripDelete] = useState(null);

  const handleRowHover = (index) => {
    setOneWayDelete(index);
  };

  const handleRowLeave = () => {
    setOneWayDelete(null);
  };

  const roundTripDel = (index) => {
    setRoundTripDelete(index);
  };
  const roundTripDelLeave = (index) => {
    setRoundTripDelete(null);
  };

  const handleAddRow = (section) => {
    const newData = { ...userForm };

    if (section === "oneWay") {
      newData.cityLimit.oneWay.push({ timeInHours: "", price: "" });
    } else if (section === "roundTrip") {
      newData.cityLimit.roundTrip.push({ timeInHours: "", price: "" });
    }

    setUserForm(newData);
  };

  const handleInputChange = (e, index, field, section) => {
    const newData = { ...userForm };
    if (section === "oneWay") {
      newData.cityLimit.oneWay[index] = {
        ...newData.cityLimit.oneWay[index],
        [field]: e.target.value,
      };
    } else if (section === "roundTrip") {
      newData.cityLimit.roundTrip[index] = {
        ...newData.cityLimit.roundTrip[index],
        [field]: e.target.value,
      };
    }

    setUserForm(newData);
  };

  const handleOneWayNoteChange = (e) => {
    setUserForm({
      ...userForm,
      cityLimit: {
        ...userForm.cityLimit,
        oneWayNotes: e.target.value,
      },
    });
  };
  const handleRoundTripNoteChange = (e) => {
    setUserForm({
      ...userForm,
      cityLimit: {
        ...userForm.cityLimit,
        roundTripNotes: e.target.value,
      },
    });
  };

  const handleDeleteRow = (index, section) => {
    const newData = { ...userForm };
    if (section === "oneWay") {
      newData.cityLimit.oneWay.splice(index, 1);
    } else if (section === "roundTrip") {
      newData.cityLimit.roundTrip.splice(index, 1);
    }
    setUserForm(newData);
  };

  return (
    <>
      <Grid
        container
        spacing={theme.spacingArea.xLarge}
        style={{ marginTop: "12px" }}
        paddingLeft={"20px"}
      >
        <Grid item xs={12}>
          <Grid container spacing={theme.spacingArea.xLarge}>
            <Grid item xs={3}>
              <Field className={classes.label} label="Radius From (Km)">
                <Input
                  autoFocus
                  style={{ height: "48px" }}
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.cityLimit?.minRadius}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const isValidInput = /^\d{0,4}$/.test(inputValue);

                    if (isValidInput) {
                      setUserForm({
                        ...userForm,
                        cityLimit: {
                          ...userForm.cityLimit,
                          minRadius: inputValue,
                        },
                      });
                    }
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field className={classes.label} label="Radius To (Km)">
                <Input
                  autoFocus
                  size="large"
                  style={{ height: "48px" }}
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.cityLimit?.maxRadius}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const isValidInput = /^\d{0,4}$/.test(inputValue);

                    if (isValidInput) {
                      setUserForm({
                        ...userForm,
                        cityLimit: {
                          ...userForm.cityLimit,
                          maxRadius: inputValue,
                        },
                      });
                    }
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3} style={{ position: "relative", bottom: "28px" }}>
              <Field className={classes.label} label="One Way">
                <Field
                  className={classes.label}
                  label="Extra time fare (per min)"
                  required
                >
                  <Input
                    autoFocus
                    size="large"
                    style={{ height: "48px" }}
                    className={`${classes.input} input__Style`}
                    placeholder=""
                    value={userForm?.cityLimit?.onewayExtraTime}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isValidInput = /^\d{0,3}(\.\d{0,2})?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        setUserForm({
                          ...userForm,
                          cityLimit: {
                            ...userForm.cityLimit,
                            onewayExtraTime: inputValue,
                          },
                        });
                      }
                    }}
                  />
                </Field>
              </Field>
            </Grid>
            <Grid item xs={3} style={{ position: "relative", bottom: "28px" }}>
              <Field className={classes.label} label="Round Trip">
                <Field
                  className={classes.label}
                  label="Extra time fare (per min)"
                  required
                >
                  <Input
                    autoFocus
                    size="large"
                    style={{ height: "48px" }}
                    className={`${classes.input} input__Style`}
                    placeholder=""
                    value={userForm?.cityLimit?.roundTripExtraTime}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isValidInput = /^\d{0,3}(\.\d{0,2})?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        setUserForm({
                          ...userForm,
                          cityLimit: {
                            ...userForm.cityLimit,
                            roundTripExtraTime: inputValue,
                          },
                        });
                      }
                    }}
                  />
                </Field>
              </Field>
            </Grid>
            {/* <Grid item xs={6}></Grid> */}
            {/* Table Section Here */}
            <Grid item xs={6}>
              <Box className={classes.tableRoot}>
                <div className={classes.header}>
                  <Typography className={classes.title}>Oneway</Typography>
                  <Box style={{ padding: "10px", gap: "7px" }}>
                    <IconButton
                      color="primary"
                      onClick={() => handleAddRow("oneWay")}
                      className={classes.addButton}
                    >
                      <AddCircleOutlineIcon
                        style={{
                          color: "#268581",
                          width: "20px",
                          height: "20px",
                        }}
                      />{" "}
                      <Typography className={classes.addButton}>
                        Add More
                      </Typography>
                    </IconButton>
                  </Box>
                </div>
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={`${classes.tableCell} ${classes.columnSeparator}`}
                        >
                          Hours
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ whiteSpace: "nowrap" }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userForm?.cityLimit &&
                        userForm?.cityLimit?.oneWay.map((row, index) => (
                          <TableRow
                            key={index}
                            onMouseEnter={() => handleRowHover(index)}
                            onMouseLeave={handleRowLeave}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <TableCell
                              className={`${classes.tableCell} ${classes.columnSeparator}`}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <input
                                type="text"
                                value={row?.timeInHours}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const isValidInput = /^\d{0,2}$/.test(
                                    inputValue
                                  );

                                  if (isValidInput) {
                                    handleInputChange(
                                      e,
                                      index,
                                      "timeInHours",
                                      "oneWay"
                                    );
                                  }
                                }}
                                style={{ border: "none", textAlign: "center" }}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <input
                                type="text"
                                value={row?.price}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const isValidInput = /^\d{0,5}$/.test(
                                    inputValue
                                  );

                                  if (isValidInput) {
                                    handleInputChange(
                                      e,
                                      index,
                                      "price",
                                      "oneWay"
                                    );
                                  }
                                }}
                                style={{ border: "none", textAlign: "center" }}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <div
                                style={{ position: "relative", height: "100%" }}
                              >
                                {oneWayDelete === index && (
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleDeleteRow(index, "oneWay")
                                    }
                                    className={classes.deleteButton}
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    <Delete24Filled />
                                  </IconButton>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack style={{ paddingLeft: "16px", paddingTop: "16px" }}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#000000",
                    }}
                  >
                    Notes
                  </Typography>
                </Stack>
                <Stack padding={"16px"}>
                  <BoxContainer style={{ borderRadius: "13px" }}>
                    <Textarea
                      className={"textbox__Style"}
                      value={userForm?.cityLimit?.oneWayNotes || ""}
                      onChange={handleOneWayNoteChange}
                      aria-multiline="true"
                      rows={7}
                      style={{
                        width: "100%",
                        outline: "none",
                      }}
                    />
                  </BoxContainer>
                </Stack>
              </Box>
            </Grid>

            {/* second table starts here */}

            <Grid item xs={6}>
              <Box className={classes.tableRoot}>
                <div className={classes.header}>
                  <Typography className={classes.title}>Round Trip</Typography>
                  <Box style={{ padding: "10px" }}>
                    <IconButton
                      color="primary"
                      onClick={() => handleAddRow("roundTrip")}
                      className={classes.addButton}
                    >
                      <AddCircleOutlineIcon
                        style={{
                          color: "#268581",
                          width: "20px",
                          height: "20px",
                        }}
                      />{" "}
                      <Typography className={classes.addButton}>
                        Add More
                      </Typography>
                    </IconButton>
                  </Box>
                </div>
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={`${classes.tableCell} ${classes.columnSeparator}`}
                        >
                          Hours
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Amount
                        </TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userForm?.cityLimit &&
                        userForm?.cityLimit?.roundTrip.map((row, index) => (
                          <TableRow
                            key={index}
                            onMouseEnter={() => roundTripDel(index)}
                            onMouseLeave={roundTripDelLeave}
                          >
                            <TableCell
                              className={`${classes.tableCell} ${classes.columnSeparator}`}
                            >
                              <input
                                type="text"
                                value={row?.timeInHours}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const isValidInput = /^\d{0,2}$/.test(
                                    inputValue
                                  );

                                  if (isValidInput) {
                                    handleInputChange(
                                      e,
                                      index,
                                      "timeInHours",
                                      "roundTrip"
                                    );
                                  }
                                }}
                                style={{ border: "none", textAlign: "center" }}
                              />
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <input
                                type="text"
                                value={row?.price}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const isValidInput = /^\d{0,5}$/.test(
                                    inputValue
                                  );

                                  if (isValidInput) {
                                    handleInputChange(
                                      e,
                                      index,
                                      "price",
                                      "roundTrip"
                                    );
                                  }
                                }}
                                style={{ border: "none", textAlign: "center" }}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <div
                                style={{ position: "relative", height: "100%" }}
                              >
                                {roundTripDelete === index && (
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleDeleteRow(index, "roundTrip")
                                    }
                                    className={classes.deleteButton}
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    <Delete24Filled />
                                  </IconButton>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Stack style={{ paddingLeft: "16px", paddingTop: "16px" }}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#000000",
                      }}
                    >
                      Notes
                    </Typography>
                  </Stack>
                  <Stack padding={"16px"}>
                    <BoxContainer style={{ borderRadius: "13px" }}>
                      <Textarea
                        className={"textbox__Style"}
                        value={userForm?.cityLimit?.roundTripNotes || ""}
                        onChange={handleRoundTripNoteChange}
                        aria-multiline="true"
                        rows={7}
                        style={{
                          width: "100%",
                          outline: "none",
                        }}
                      />
                    </BoxContainer>
                  </Stack>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LocalTrip;
