import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  Box,
  Badge,
  Tooltip,
  Collapse,
  Link,
  Stack,
} from "@mui/material";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import SideBarMenu from "./SideBarMenu";
import { withTranslation } from "react-i18next";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import LocalStorage from "../../../../config/LocalStorage";
// import logo from "../../../../assets/images/logo.png";
import logo from "@mui/icons-material/AddBusiness";
import LeftDrawer from "./LeftDrawer";

const drawerWidth = 250;
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
      height: "100vh",
    },
  },
  drawerDrawerClose: {
    flexShrink: 0,
    width: 70,
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: theme.custom.sideDrawer.boxShadow,
    backdropFilter: theme.custom.sideDrawer.backdropFilter,
    backgroundColor: theme.custom.sideDrawer.backgroundColor,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  companyName: {
    color: theme.custom.sideDrawer.companyName,
    fontSize: "16px",
    alignItems: "center",
    fontWeight: "500",
    justifyContent: "center",
    textDecoration: "none",
    background: "transparent",
  },
  textAlignCenter: {},
  userImage: {
    width: "70px",
    height: "70px",
  },
  userInfoBox: {
    background: "transparent",
    textAlign: "center",
  },
  userName: {
    color: theme.custom.sideDrawer.userName,
    fontSize: "18px",
    alignItems: "center",
    fontWeight: "600",
    justifyContent: "center",
    textDecoration: "none",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menuLink: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  menuStyle: {
    color: theme.custom.sideDrawer.menuColor,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [values, setMenuStatus] = React.useState({});
  const location = useLocation();
  const [allowedMenus, setAllowedMenus] = React.useState([]);

  useMemo(() => {
    let result = [];
    if (LocalStorage.userDetails?.permission?.active) {
      result = LocalStorage.userDetails?.permission?.permission
        ?.filter((v) => v?.enable)
        ?.map((value) => value?.label);
      console.log(result, "this is local storsge");
    }
    setAllowedMenus(result);
  }, []);

  const mappedSideBarMenu = LocalStorage.userDetails.isSuperAdmin
    ? SideBarMenu.menuForSuperAdmin
    : SideBarMenu.menuForAdmin;

  useEffect(() => {
    let menuStatus = values;
    mappedSideBarMenu.map((data) => {
      if (data.display !== undefined && data.display !== null) {
        data?.subMenu &&
          data.subMenu.map((subData) => {
            if (subData.link === location.pathname) {
              props.callback(subData.display);
            }
            return (menuStatus[subData.eventKey] =
              menuStatus[subData.eventKey] !== undefined &&
              menuStatus[subData.eventKey] !== null
                ? menuStatus[subData.eventKey]
                : false);
          });
        if (data.link === location.pathname) {
          props.callback(data.display);
        }
        return (menuStatus[data.eventKey] =
          menuStatus[data.eventKey] !== undefined &&
          menuStatus[data.eventKey] !== null
            ? menuStatus[data.eventKey]
            : false);
      }
      return null;
    });
    setMenuStatus(menuStatus);
  }, [location]);

  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const CompanyUserInfo = () => {
    return (
      <div className={classes.toolbar}>
        {/* Company Name & Icon */}
        <Box
          m={1}
          bgcolor="background.paper"
          p={1}
          style={{ display: "flex" }}
          className={classes.companyName}
        >
          <Avatar
            alt={LocalStorage.ProjectName}
            src={logo}
            m={3}
            className={classes.small}
          />
          <Box
            component="span"
            color="text.primary"
            ml={1}
            className={classes.companyName}
          >
            {LocalStorage.ProjectName}
          </Box>
        </Box>
        {/* User Name & Icon */}
        <Box bgcolor="background.paper" p={1} className={classes.userInfoBox}>
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              className={classes.userImage}
              alt="User Logo"
              src={
                LocalStorage.userDetails.profileImage !== undefined &&
                LocalStorage.userDetails.profileImage !== null &&
                LocalStorage.userDetails.profileImage !== ""
                  ? LocalStorage.userDetails.profileImage
                  : logo
              }
            />
          </StyledBadge>
        </Box>
        <Box bgcolor="background.paper" p={1} className={classes.userInfoBox}>
          <Box
            component="span"
            color="text.primary"
            ml={1}
            className={classes.userName}
            onClick={() => {
              props.callback(props.t("sideNavMenu.profile"));
            }}
          >
            <Link href="#/admin/profile">
              {LocalStorage.userDetails.userName !== undefined &&
              LocalStorage.userDetails.userName !== null &&
              LocalStorage.userDetails.userName !== ""
                ? LocalStorage.userDetails.userName
                : LocalStorage.userDetails.email !== undefined &&
                  LocalStorage.userDetails.email !== null &&
                  LocalStorage.userDetails.email !== ""
                ? LocalStorage.userDetails.email
                : LocalStorage.userDetails.mobileNo !== undefined &&
                  LocalStorage.userDetails.mobileNo !== null &&
                  LocalStorage.userDetails.mobileNo !== ""
                ? LocalStorage.userDetails.mobileNo
                : "Starya"}
            </Link>
          </Box>
        </Box>
      </div>
    );
  };
  const handleMenu = (eventKey) => {
    if (eventKey !== undefined && eventKey !== null) {
      setMenuStatus({
        ...values,
        [eventKey]:
          values !== undefined && values !== null
            ? values[eventKey] !== undefined && values[eventKey] !== null
              ? !values[eventKey]
              : false
            : false,
      });
    }
  };
  const SubMenu = (menu) => {
    return menu.subMenu.map((subMenu, subMenuIndex) =>
      LocalStorage.maintenanceOptions.includes(subMenu.permissionLevel) ||
      subMenu.permissionLevel === "" ? (
        <Collapse
          in={
            values !== undefined && values !== null
              ? values[menu.eventKey] !== undefined &&
                values[menu.eventKey] !== null
                ? values[menu.eventKey]
                : false
              : false
          }
          timeout="auto"
          unmountOnExit
          key={subMenuIndex}
        >
          <List component="div" disablePadding>
            <Link
              href={"#" + subMenu.link}
              underline="none"
              className={classes.menuLink}
            >
              <ListItem
                button
                selected={location.pathname === subMenu.link ? true : false}
                className={classes.nested}
              >
                <ListItemIcon>{subMenu.icon}</ListItemIcon>
                <Tooltip title={props.t(subMenu.display)}>
                  <ListItemText
                    className={classes.menuStyle}
                    primary={props.t(subMenu.display)}
                  />
                </Tooltip>
              </ListItem>
            </Link>
          </List>
        </Collapse>
      ) : null
    );
  };
  const MainMenu = (menu) =>
    menu.subMenu.length === 0 &&
    (LocalStorage.maintenanceOptions.includes(menu.permissionLevel) ||
      menu.permissionLevel === "") ? (
      <Link
        href={"#" + menu.link}
        underline="none"
        className={classes.menuLink}
      >
        <ListItem
          button
          key={menu.eventKey}
          selected={location.pathname === menu.link ? true : false}
        >
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <Tooltip title={props.t(menu.display)}>
            <ListItemText
              className={classes.menuStyle}
              primary={props.t(menu.display)}
            />
          </Tooltip>
        </ListItem>
      </Link>
    ) : menu.subMenu.length > 0 ? (
      <div>
        <ListItem button onClick={() => handleMenu(menu.eventKey)}>
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <Tooltip title={props.t(menu.display)}>
            <ListItemText
              primaryTypographyProps={{
                className: classes.menuStyle,
              }}
              primary={props.t(menu.display)}
            />
          </Tooltip>
          {values !== undefined && values !== null ? (
            values[menu.eventKey] !== undefined &&
            values[menu.eventKey] !== null ? (
              values[menu.eventKey] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : (
              <ExpandMore />
            )
          ) : <ExpandMore /> ? (
            <ExpandMore />
          ) : (
            <ExpandMore />
          )}
        </ListItem>
        <SubMenu {...menu} />
      </div>
    ) : null;
  const DrawerMenu = () => {
    return (
      <div>
        <CompanyUserInfo />
        <Divider />
        <List>
          {mappedSideBarMenu.map((menu, menuIndex) =>
            menu.display !== undefined && menu.display !== null ? (
              <MainMenu key={menuIndex} {...menu} />
            ) : (
              <Divider key={menuIndex} />
            )
          )}
        </List>
      </div>
    );
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <nav
      className={props.mobileOpen ? classes.drawerDrawerClose : classes.drawer}
      aria-label="mailbox folders"
    >
      {
        <LeftDrawer
          isMobile={props.mobileOpen}
          menus={mappedSideBarMenu}
          translate={props.t}
          expanded={expanded}
          setExpanded={setExpanded}
          handleExpand={handleExpand}
          allowedMenus={allowedMenus}
        />
      }

      {/* <Hidden only={["xl", "lg", "md"]}>
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerMenu />
        </Drawer>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant={props.mobileOpen ? "temporary" : "permanent"}
          open={!props.mobileOpen}
        >
          <DrawerMenu />
        </Drawer>
      </Hidden> */}
    </nav>
  );
}
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};
export default withTranslation("translations")(ResponsiveDrawer);
