import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function SlideInDialog(props) {
	return (
		<div>
			<Dialog
				fullWidth={props.fullWidth}
				maxWidth={props.maxWidth}
				open={props.slideInDialog}
				TransitionComponent={Transition}
				keepMounted
				// onClose={() => props.setSlideInDialog(false)}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				{props.title !== undefined && props.title !== null ? <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle> : <></>}
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">{props.contentComponent}</DialogContentText>
				</DialogContent>
				{props.dialogActionsStatus === true ? (
					<DialogActions>
						<Button onClick={() => props.handleDeleteRequest("disagree")} color="primary">
							{props.t("buttons.disagree")}
						</Button>
						<Button onClick={() => props.handleDeleteRequest("agree")} color="primary">
							{props.t("buttons.agree")}
						</Button>
					</DialogActions>
				) : props.dialogActionsStatus === "submit" ? (
					<DialogActions>
						<Button onClick={() => props.handleDeleteRequest("disagree")} color="primary">
							{props.t("buttons.cancel")}
						</Button>
						<Button onClick={() => props.handleDeleteRequest("submit")} color="primary">
							{props.t("buttons.submit")}
						</Button>
					</DialogActions>
				) : props.dialogActionsStatus === "ok" ? (
					<DialogActions>
						<Button onClick={() => props.handleButtonResponse("cancel")} color="primary">
							{props.t("buttons.cancel")}
						</Button>
						<Button onClick={() => props.handleButtonResponse("ok")} color="primary">
							{props.t("buttons.ok")}
						</Button>
					</DialogActions>
				) : (
					<></>
				)}
			</Dialog>
		</div>
	);
}
export default withTranslation("translations")(SlideInDialog);
