import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import Typography from "../../../../../components/Text/Typography";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const CustomTooltip = ({ active, payload, label, dashboardFilter }) => {
  if (active && payload && payload.length) {
    // console.log("payloadpayload", payload);
    function getTotalBookings(data) {
      const completed = data?.completed || 0;
      const cancelled = data?.cancelled || 0;
      const pending = data?.pending || 0;
      const enquiry = data?.enquiry || 0;
      const accepted = data?.accepted || 0;
      const outForPickup = data?.outForPickup || 0;

      return (
        completed + cancelled + pending + enquiry + accepted + outForPickup
      );
    }

    return (
      <div
        style={{
          // width: "159px",
          height: "100%",
          boxShadow: "0px 8px 22px 0px rgba(0, 0, 0, 0.07)",
          padding: "10px",
          background: "#fff",
          borderRadius: "10px",
          padding: "5px 14px",
        }}
      >
        <div style={{ borderBottom: "1px solid #F1F1F1" }}>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 600,
            }}
          >
            No. of Bookings: {getTotalBookings(payload[0]?.payload?.data)}
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 400,
            }}
          >
            Completed : {payload[0]?.payload?.data?.completed}
          </Typography>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 400,
            }}
          >
            Cancelled : {payload[0]?.payload?.data?.cancelled}
          </Typography>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 400,
            }}
          >
            Pending : {payload[0]?.payload?.data?.pending}
          </Typography>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 400,
            }}
          >
            Enquiry : {payload[0]?.payload?.data?.enquiry}
          </Typography>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 400,
            }}
          >
            Accepted : {payload[0].payload?.data?.accepted}
          </Typography>
          <Typography
            variant={"subHeading"}
            style={{
              color: "#000",
              fontWeight: 400,
            }}
          >
            Out For Pickup : {payload[0].payload?.data?.outForPickup}
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};
function LineChartBooking(props) {
  const { services, dashboardFilter } = props;
  console.log("LineGraphDatabookingGraphData", services?.bookingGraphData);

  return (
    // <div>
    <ResponsiveContainer width="100%" height={370}>
      <LineChart
        width={600}
        height={370}
        data={services?.bookingGraphData}
        syncId="anyId"
        margin={{
          top: 10,
          right: 50,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        {/* <Tooltip /> */}
        <Tooltip
          content={<CustomTooltip dashboardFilter={dashboardFilter} />}
        />
        <Line
          type="linear"
          dataKey="totalCount"
          stroke="#046CE8"
          fill="#046CE8"
          strokeWidth={"2px"}
        />
      </LineChart>
    </ResponsiveContainer>
    // </div>
  );
}
export default LineChartBooking;
