import React, { useState } from "react";
import PrimaryBtn from "../../../../../../components/Button/PrimaryBtn";
import APIRequest from "../../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../../config/ConfigAPIURL";
import useAlert from "../../../../../../hooks/useAlert";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../../styles/style";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Textarea,
  Field,
} from "@fluentui/react-components";
import { Grid, Typography } from "@mui/material";
import { set } from "date-fns";

const Status = (props) => {
  const classes = useStyles();
  const { userForm, openForm, setOpenForm, recordId, resetRecords } = props;
  const [noteMOdal, setNoteModal] = React.useState(false);
  const { publishNotification } = useAlert();

  // State to track hover status for buttons
  const [isApproveButtonHovered, setIsApproveButtonHovered] = useState(false);
  const [isRejectButtonHovered, setIsRejectButtonHovered] = useState(false);

  const handleNoteModal = () => {
    setNoteModal(true);
  };

  const [noteByAdmin, setNoteByAdmin] = React.useState(
    userForm?.rejectionReason
  );

  const updateStatus = (status) => {
    APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdate,
      JSON.stringify({
        ...userForm,
        // userId: recordId[0]?._id,
        status: status,
        rejectionReason: noteByAdmin,
        userId: recordId[0]?._id,
      })
    ).then((response) => {
      if (
        response?.data?.responseCode === 109 ||
        response?.data?.responseCode === 114
      ) {
        publishNotification("Status Updated Successfully", "success");
        setOpenForm({
          ...openForm,
          isOpen: false,
        });
        setNoteModal(false);
        resetRecords();
      }
    });
  };

  return (
    <>
      <div
        style={{
          marginTop: "22px",
          display: openForm?.divtype === "edit" ? "none" : "",
        }}
      >
        <button
          onClick={() => handleNoteModal()}
          style={{
            background:
              userForm?.status === "rejected" || isRejectButtonHovered
                ? "#F32828"
                : "white",
            color:
              userForm?.status === "rejected" || isRejectButtonHovered
                ? "white"
                : "black",
            border:
              userForm?.status === "rejected" || isRejectButtonHovered
                ? "none"
                : "1px solid black",
            height: "38px",
            width: "116px",
            borderRadius: "34px",
            cursor: "pointer",
            fontWeight: 500,
            fontFamily: "Segoe UI",
          }}
          onMouseEnter={() => setIsRejectButtonHovered(true)}
          onMouseLeave={() => setIsRejectButtonHovered(false)}
        >
          Reject
        </button>
        <button
          style={{
            marginLeft: "6px",
            background:
              userForm?.status === "approved" || isApproveButtonHovered
                ? "#27CA4B"
                : "white",
            color:
              userForm?.status === "approved" || isApproveButtonHovered
                ? "white"
                : "black",
            border:
              userForm?.status === "approved" || isApproveButtonHovered
                ? "none"
                : "1px solid black",

            height: "38px",
            width: "116px",
            cursor: "pointer",
            borderRadius: "34px",
            fontWeight: 500,
            fontFamily: "Segoe UI",
          }}
          onClick={() => updateStatus("approved")}
          onMouseEnter={() => setIsApproveButtonHovered(true)}
          onMouseLeave={() => setIsApproveButtonHovered(false)}
        >
          {" "}
          Approve
        </button>
      </div>

      <Dialog open={noteMOdal}>
        <DialogSurface
          style={{
            padding: "0px",
            // position: "fixed",
            // right: "16px",
            // bottom: "16px",
          }}
        >
          <DialogTitle
            style={{
              width: "100%",
              height: "48px",
              background: " #FBFBFB",
              // justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: "0 10px 0 10px",
            }}
          >
            <Typography
              variant={"heading"}
              style={{
                fontWeight: 600,
              }}
            >
              Reason For Rejection
            </Typography>

            <CloseIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => setNoteModal(false)}
            />
          </DialogTitle>
          <DialogBody style={{ padding: "12px" }}>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Field className={classes.label} label="Reason">
                    <Textarea
                      placeholder="Reason"
                      value={noteByAdmin}
                      className={` input__Style`}
                      rows={4}
                      style={{ width: "100%",fontWeight: "600" }}
                      onChange={(e) => setNoteByAdmin(e.target.value)}
                    />
                  </Field>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "24px",
                }}
              >
                <button
                  style={{
                    background: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    width: "99px",
                    cursor: "pointer",
                    height: "30px",
                  }}
                  onClick={() => updateStatus("rejected")}
                >
                  <Typography style={{ color: "white", fontWeight: "600" }}>
                    Submit
                  </Typography>
                </button>
              </div>
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default Status;
