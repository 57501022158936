import { Stack } from "@fluentui/react";
import { Field } from "@fluentui/react-components";
import React from "react";

import { ArrowUpload24Filled } from "@fluentui/react-icons";
import useUpload from "../../../../../hooks/useUpload";

import { Delete20Regular } from "@fluentui/react-icons";
import { useTheme } from "@mui/styles";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import Backdrop from "../../../../../components/Backdrop/Index";
import LocalStorage from "../../../../../config/LocalStorage";

const ConductCertificate = ({
  classes,
  setUserForm,
  userForm,
  handleDelete,
  label,
  editRolePermission,
}) => {
  const { multipleFileUpload, isLoading } = useUpload();
  const [isUploading, setIsUploading] = React.useState(false);

  const uploadLicenceFile = (props) => {
    setIsUploading(true); // Set loading state to true

    APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      props
    ).then((response) => {
      if (response !== undefined && response !== null) {
        const attachmentUrl = {
          type: "",
          name: "",
          url: response.data.attachmentUrl[0],
        };

        setUserForm((prevState) => {
          return {
            ...prevState,
            conductCertificate: [
              ...prevState.conductCertificate,
              attachmentUrl,
            ],
          };
        });

        setTimeout(function () {}, 1000);
        setIsUploading(false); // Set loading state to false
      } else {
        setIsUploading(false); // Handle errors here and set loading state to false
      }
    });
  };

  const uploadConductCertificate = (event) => {
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      setIsUploading(true); // Set loading state to true

      [...event.target.files].map(async (data) => {
        let formData = new FormData();
        // Add the file attachment and bucket name fields to the FormData object
        formData.append(`attachment`, data);
        formData.append("bucketName", "profile");
        uploadLicenceFile(formData);
      });
    }
  };

  const deleteDrivingLicense = (index) => {
    setUserForm((prevState) => {
      const updateConductCertificate = [...prevState.conductCertificate];

      updateConductCertificate.splice(index, 1);

      return {
        ...prevState,
        conductCertificate: updateConductCertificate,
      };
    });
  };

  const theme = useTheme();
  return (
    <Stack style={{ gap: "4px", width: "100%" }}>
      <Backdrop open={isUploading} />
      <Field className={classes.label} label={label} />
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{
          background: "white",
          padding: "16px",
          height: "142px",
          borderRadius: "8px",
          gap: "8px",
          border: "1px solid #E5E5E5",
          overflowX: "auto",
        }}
      >
        <Stack horizontal className={classes.gapSmall}>
          {/* image array */}
          {userForm?.conductCertificate &&
            userForm?.conductCertificate?.map(
              (value, i) =>
                value?.url && (
                  <Stack style={{ position: "relative" }}>
                    {console.log(value, "from conduct")}
                    <Stack
                      verticalAlign="center"
                      horizontalAlign="center"
                      style={{
                        width: "28px",
                        height: "12px",
                        background: "white",
                        position: "absolute",
                        borderRadius: "100%",
                        top: -8,
                        right: -5,
                        cursor: "pointer",
                        display: editRolePermission ? "" : "none",
                      }}
                    >
                      {editRolePermission && (
                        <Delete20Regular
                          onClick={() => deleteDrivingLicense(i)}
                          className="deleteIcon"
                          style={{
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </Stack>
                    <img
                      style={{
                        borderRadius: "10px",
                        width: "117px",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => {
                        const newWindow = window.open("", "_blank");
                        if (newWindow) {
                          const imgElement = new Image();
                          imgElement.onload = function () {
                            newWindow.resizeTo(
                              1000,
                              (imgElement.height / imgElement.width) * 1000
                            );
                            imgElement.style.width = "1000px";
                            newWindow.document.body.appendChild(imgElement);
                          };
                          imgElement.src = value?.url;
                        } else {
                          console.error("Failed to open new window");
                        }
                      }}
                      src={value?.url}
                      alt="conductCertificate"
                    />
                  </Stack>
                )
            )}
        </Stack>
        <Stack>
          <Stack>
            <label htmlFor="file">
              <ArrowUpload24Filled style={{ cursor: "pointer" }} />
            </label>

            <input
              accept=".png, .jpg, .jpeg"
              multiple
              id="file"
              type="file"
              style={{ display: "none" }}
              onChange={uploadConductCertificate}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ConductCertificate;
