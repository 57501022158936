import logo from "./assets/images/falainalogo.png";
import companyImage from "./assets/images/company image.png";
import costSheet from "./assets/images/costsheetimg.png";
import uploadIcon from "./assets/images/upload-file 1.png";
import logo2 from "./assets/images/genex logo 2.png";
import accomadationLogo from "./assets/images/accomadation dummy logo.png";
import trekking from "./assets/images/iconoir_trekking.png";
import building from "./assets/images/building-3.png";
import car from "./assets/images/car.png";
import mountain from "./assets/images/fluent_mountain-location-top-20-regular.png";
import seat from "./assets/images/ic_round-airline-seat-recline-normal.png";
import airConditioner from "./assets/images/Mask group.png";
import luggage from "./assets/images/material-symbols_luggage-rounded.png";
import firstAid from "./assets/images/mdi_first-aid-kit.png";
import clock from "./assets/images/clock.png";
import download from "./assets/images/download.png";
import calendar from "./assets/images/calendar.png";
import Filter from "./assets/images/Filter.png";
import threeUser from "./assets/images/threeUser.svg";
import Paper from "./assets/images/Paper.svg";
import Bag from "./assets/images/Bag.svg";
import Activity from "./assets/images/Activity.svg";
import checkMark from "./assets/images/checkMark.png";

export default {
  companyLogo: logo,
  companyImage,
  costSheet,
  uploadIcon,
  logo2,
  accomadationLogo,
  mountain,
  car,
  building,
  trekking,
  luggage,
  airConditioner,
  seat,
  firstAid,
  clock,
  download,
  calendar,
  Filter,
  threeUser,
  Paper,
  Bag,
  Activity,
  checkMark,
};
