import {
  Dropdown,
  Field,
  FluentProvider,
  Option,
  teamsLightTheme,
} from "@fluentui/react-components";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import Typography from "../../../../../../components/Text/Typography";
import images from "../../../../../../images";

const recharge = {
  subscriptionPlanId: "",
  paymentRefId: "",
  modeOfPayment: "",
  note: "",
  userId: "",
};

const AddPlan = (props) => {
  const { classes, allRechargablePlan, services, openForm, setIsDialog,setSubscriptionHistory } =
    props;
  const [selectedTab, setSelectedTab] = useState("");
  const [rechargeData, setRechargeData] = useState(recharge);
  //   console.log("allRechargablePlan", allRechargablePlan);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <div style={{ padding: "16px", overflowY: "auto", height: "399px" }}>
      <FluentProvider theme={teamsLightTheme}>
        <Field label="Payment Type">
          <Dropdown
          className="input__Style"
            value={rechargeData?.modeOfPayment}
            size="large"
            style={{ textTransform: "capitalize" }}
            onOptionSelect={(e, data) => {
              setRechargeData({
                ...rechargeData,
                modeOfPayment: data?.optionValue,
              });
            }}
          >
            <Option
              style={{ textTransform: "capitalize" }}
              text={true}
              value={"cash"}
            >
              Cash
            </Option>
            <Option
              style={{ textTransform: "capitalize" }}
              text={false}
              value={"online"}
            >
              Online
            </Option>
          </Dropdown>
        </Field>
      </FluentProvider>

      <div className={classes.tabContainerAddPlan}>
        {allRechargablePlan?.map((data, index) => {
          return (
            <div
              className={`${classes.tabPlan} ${
                selectedTab === `tab${index}` ? classes.selectedTabPlan : ""
              }`}
              onClick={() => handleTabClick(`tab${index}`)}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  padding: "15px",
                }}
                onClick={() => {
                  setRechargeData({
                    ...rechargeData,
                    subscriptionPlanId: data?._id,
                    userId: openForm?.rowDetails,
                  });
                }}
              >
                <Stack>
                  <Typography
                    variant={"heading"}
                    style={{
                      fontWeight: 700,
                      color: selectedTab === `tab${index}` ? "#fff" : "",
                    }}
                  >
                    {data?.planName}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 600,
                      marginTop: "8px",
                      color: selectedTab === `tab${index}` ? "#fff" : "",
                    }}
                  >
                    ₹{data?.planAmount}{" "}
                    <span style={{ fontWeight: 400 }}>
                      / Ride upto {data?.validityAmount}
                    </span>
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant={"heading"} style={{ fontWeight: 700 }}>
                    {selectedTab === `tab${index}` ? (
                      <img
                        src={images.checkMark}
                        width={25}
                        height={25}
                        style={{ backgroundColor: "#fff", borderRadius: "50%" }}
                        alt=""
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9.5"
                          fill="white"
                          stroke="#268581"
                        />
                      </svg>
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </div>
          );
        })}
      </div>
      <Stack
        style={{
          marginTop: "24px",
          backgroundColor: "#fff",
          padding: "10px",
          width: "100%",
          position: "absolute",
          bottom: "0",
          left: "50%" /* Center horizontally */,
          transform: "translateX(-50%)",
          height: "50px",
        }}
      >
        <Typography
          variant={"subHeading"}
          style={{
            backgroundColor: "#268581",
            borderRadius: "34px",
            padding: "10px 27px",
            color: "#fff",
            cursor: "pointer",
            position: "absolute",
            bottom: "6px",
            left: "50%" /* Center horizontally */,
            transform: "translateX(-50%)" /* Center horizontally */,
          }}
          onClick={() => {
            services?.subscribePlan(rechargeData, setIsDialog,setSubscriptionHistory);
          }}
        >
          Recharge Now
        </Typography>
      </Stack>
    </div>
  );
};

export default AddPlan;
