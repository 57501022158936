import React, { useEffect } from "react";
import {
  Combobox,
  Dropdown,
  FluentProvider,
  Label,
  Option,
  teamsLightTheme,
} from "@fluentui/react-components";
import { Field } from "@fluentui/react-components";
import { Grid } from "@mui/material";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useStyles } from "../styles/style";
import Typography from "../../../../../components/Text/Typography";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { DateRangePicker } from "materialui-daterange-picker";
import { Stack } from "@fluentui/react";
import images from "../../../../../images"

const GraphFilters = (props) => {
  const classes = useStyles();
  const { dashboardFilter, setDashboardFilter } = props;

  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);
  const currentDate = new Date();
  const threeMonthsAgo = new Date(currentDate);
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
  const [dateRange, setDateRange] = React.useState({
    startDate: threeMonthsAgo,
    endDate: currentDate,
  });
    function dateToUnixTimestamp(date) {
    return Math.floor(date?.getTime() / 1000);
  }
  const startTimestamp = dateToUnixTimestamp(dateRange.startDate);
    const endTimestamp = dateToUnixTimestamp(dateRange.endDate);
  
    useEffect(()=>{
      setDashboardFilter({
        ...dashboardFilter,
        startDate : startTimestamp,
        endDate : endTimestamp
      })
    },[startTimestamp,endTimestamp])
  const [places, setPlaces] = React.useState([]);
  const [placeId, setPlaceId] = React.useState("");

  const handleAddressChange = async (keyword) => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?keyword=${keyword}`
    ).then((res) => {
      setPlaces(res.data.result.predictions);
    });
  };

  const handleStartDateChange = (date) => {
    if (date) {
      // Convert the selected date to Unix timestamp format (in seconds)
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setDashboardFilter({
        ...dashboardFilter,
        startDate: unixTimestamp,
      });
    } else {
      // Handle the case when no date is selected (optional)
      setDashboardFilter({
        ...dashboardFilter,
        startDate: "",
      });
    }
  };
  const handleEndDateChange = (date) => {
    if (date) {
      // Convert the selected date to Unix timestamp format (in seconds)
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setDashboardFilter({
        ...dashboardFilter,
        endDate: unixTimestamp,
      });
    } else {
      // Handle the case when no date is selected (optional)
      setDashboardFilter({
        ...dashboardFilter,
        endDate: "",
      });
    }
  };
  function formatDate(unixTimestamp) {
    // Convert the Unix timestamp to a JavaScript Date object
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds by multiplying by 1000

    // Format the date as a string in your desired format
    const formattedDate = date; // You can customize the format here

    return formattedDate;
  }
  function formatDateRangePicker(date) {
    return date?.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  }
  const today = new Date(); // Get the current date
  const maxSelectableDate = new Date(today);
  maxSelectableDate.setDate(today.getDate() + 31);
  const onFormatDate = (date) => {
    return !date
      ? ""
      : `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };


  return (
    <div>
      <FluentProvider theme={teamsLightTheme}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={"title"}>Revenue</Typography>
            <Stack horizontal verticalAlign="center">
            <Combobox
              style={{
                background: "#FFF",
                // border: "1px solid #E5E5E5",
                border: "none",
                height: "42px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "110px",
                marginRight:'16px',
                
              }}
              onInput={(e) => handleAddressChange(e.target.value)}
              className={`${classes.input} input__Style`}
              onOptionSelect={(e, data) => {
                const keyword = data?.optionValue?.toLowerCase();

                setDashboardFilter({
                  ...dashboardFilter,
                  keyword: keyword === "all" ? "" : keyword,
                });
              }}
              placeholder="Select Location"
              // value={
              //   (dashboardFilter?.keyword).charAt(0).toUpperCase() +
              //   (dashboardFilter?.keyword).slice(1)
              // }
            >
              <Option onClick={() => setPlaceId("")}>{"All"}</Option>
              {places?.map((option) => (
                <>
                  <Option
                    key={option}
                    onClick={() => setPlaceId(option?.place_id)}
                  >
                    {option?.structured_formatting?.main_text}
                  </Option>
                </>
              ))}
            </Combobox>
            <div
              style={{
                width: "156px",
                height:'42px',
                borderBottom: "1px solid #E6E8F0",
                paddingBottom: "10px",
                backgroundColor:'#F1F1F1',
                textAlign:'center',
                borderRadius:"8px",
                cursor: "pointer",
              }}
            >
              <Grid
                item
                xs={4}
                style={{  position: "relative" }}
              >
                <Stack
                  className={classes.actionButtons}
                  style={{ width:"156px",display:'flex',flexDirection:'row',padding:'10px' }}
                  onClick={toggle}
                >
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  >
                    <img src={images.calendar} width="24px" height="24px" />
                  </div>
                  <Typography
                    variant={"subHeading"}
                    style={{
                      color: "var(--greyscale-600, #718096)",
                      fontWeight: 400,
                    }}
                  >
                    {" "}
                    {formatDateRangePicker(dateRange.startDate)} -{" "}
                    {formatDateRangePicker(dateRange.endDate)}
                  </Typography>
                </Stack>
                <div style={{ position: "absolute", top: "0", left: "0" }}>
                  <DateRangePicker
                    open={open}
                    toggle={toggle}
                    onChange={(range) => setDateRange(range)}
                    initialDateRange={dateRange}
                  />
                </div>
              </Grid>
            </div>
            </Stack>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // width: "100%",
              // alignItems: "baseline",
              justifyContent: "space-between",
              borderBottom: "1px solid #E6E8F0",
              paddingBottom: "10px",
            }}
          >
            <Grid
              item
              xs={3.5}
              style={{ marginRight: "10px", position: "relative" }}
            >
              {/* <DatePicker
                firstWeekOfYear={1}
                formatDate={onFormatDate}
                showMonthPickerAsOverlay={true}
                style={{ width: "100%", marginTop: "8px" }}
                className={`${classes.input} input__Style`}
                showWeekNumbers={false}
                value={formatDate(dashboardFilter?.startDate)}
                maxDate={
                  dashboardFilter?.dateType === "day"
                    ? maxSelectableDate
                    : undefined
                }
                onSelectDate={handleStartDateChange}
              /> */}
            </Grid>
            {/* <Grid item xs={3.5} style={{ marginRight: "10px" }}>
              <DatePicker
                firstWeekOfYear={1}
                formatDate={onFormatDate}
                showMonthPickerAsOverlay={true}
                style={{ width: "100%", marginTop: "8px" }}
                className={`${classes.input} input__Style`}
                value={formatDate(dashboardFilter?.endDate)}
                maxDate={
                  dashboardFilter?.dateType === "day"
                    ? maxSelectableDate
                    : undefined
                }
                minDate={formatDate(dashboardFilter?.startDate)} // Set minDate to the selected start date
                onSelectDate={handleEndDateChange}
              />
            </Grid> */}
          </div>
          <div style={{ marginTop: "10px" }}>
            <Typography variant={"mainTitle"}>
              ₹
              {dashboardFilter?.totalRevenue &&
                Number(dashboardFilter?.totalRevenue?.toFixed(2))}
            </Typography>
          </div>
        </div>
      </FluentProvider>
    </div>
  );
};

export default GraphFilters;
