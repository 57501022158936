import React, { useState } from "react";
import { useEffect } from "react";
import LocalStorage from "../../../../../config/LocalStorage";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import useAlert from "../../../../../hooks/useAlert";

// this is api calls happen
const useServices = (props) => {
  const { query } = props;
 
  const { publishNotification } = useAlert();
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  const [loading, setLoading] = useState({
    isOpen: false,
    message: "",
    type: "",
  });


  useEffect(() => {
    if (!query) return;
    tableQuery(query);
  }, [query, LocalStorage.adminButtonPermission]);

  const tableQuery = (query) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "query" });
      if (
        LocalStorage.adminButtonPermission.find(function (element) {
          return element.button === "query" ? true : false;
        })
      ) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.fetchRoles,
          JSON.stringify(query)
        ).then((tableData) => {
          setTableData(tableData?.data);
          setLoading({ ...loading, isOpen: false, type: "" });
        });
      } else {
        // notification(props.t('toaster.tableRecordPermission'), 'error');
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");

      console.log(err);
    } finally {
    }
  };



  const [addRoleObject, setAddRoleObject] = React.useState({
    name: "",
    active: true,
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetRoleObject = () => {
    const { openForm, setOpenForm } = props;
    setAddRoleObject({ name: "", active: true });
    setOpenForm({ ...openForm, isOpen: false });
  };
  const addRole = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.createRole,
      JSON.stringify(addRoleObject)
    ).then((response) => {
      if (response.data.responseCode === 109) {
        resetRoleObject();
        tableQuery(query);
        handleClose();
        publishNotification("Role created successfully", "success");
      } else {
        publishNotification("Something went wrong", "error");
      }
    });
  };

  const handleDeleteRecord = async (recordId) => {
    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.roleUpdate,
        JSON.stringify({ roleId:recordId[0]?._id,active:false })
      );
      if (response?.data?.responseCode === 109) {
        // successfully deleted the record
        publishNotification("Successfully deleted the Item", "success");
        tableQuery(props?.query);
        props?.resetForm();
        props?.resetRecords();
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");
    }
  };

  
 

  return {
    addRoleObject,
    setAddRoleObject,
    resetRoleObject,
    addRole,
    handleOpen,
    handleClose,
    open,
    setOpen,
    tableData,
    loading,
    setLoading,
    tableQuery,
    handleDeleteRecord

    
    
  };
};

export default useServices;
