import { Checkbox, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import utilController from "../../utils/Utilcontroller";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#06381C",
    },
  },
});

const heading = "Choose Columns";
const paragraph = "Choose the conditions for your Custom Columns";

const ColumnOption = ({ filteredColumn, UpdateFilter, resetColumns, openForm }) => {
  useEffect(() => {
    if (openForm?.divType === "column" && openForm?.isSaveForm) {
      resetColumns();
    }
  }, [openForm]);

  return (
    <Stack style={{marginTop:"1.7rem"}}>
      <Typography fs="xLarge" style={{ fontWeight: 700, fontSize: "28px", fontFamily: "Segoe UI",}}>
        {heading}
      </Typography>
      <Typography fs="medium" style={{ fontWeight: 400, marginTop: "8px" , fontFamily: "Segoe UI",}}>
        {paragraph}
      </Typography>

      <Stack style={{  marginTop: "1.3rem", fontFamily: "Segoe UI",}}>
        {filteredColumn?.length > 0 &&
          filteredColumn?.map((value, ind) => {
            if (!value?.fieldName) return;

            return (
              <Stack direction="row" alignItems="center" key={ind}>
                <ThemeProvider theme={theme}>
                  <Checkbox
                    // color="primary"
                    style={{color:"#268581"}}
                    disabled={value?.primaryKey}
                    checked={value?.visibility}
                    onChange={(e) => UpdateFilter(ind, e.target.checked)}
                  />
                </ThemeProvider>
                <Typography variant="body1" style={{fontFamily:"Segoe UI"}}>
                  {utilController?.textCapitalise(value?.fieldName)}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default ColumnOption;
