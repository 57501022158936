import { Typography } from "@mui/material";
import React from "react";

const fontSize = {
  small: "12px",
  medium: "14px",
  large: "16px",
  xLarge: "20px",
};

const ResponsiveTypography = ({ fs = "small", children, style }) => {
  const fontChoosen = fontSize[fs];
  return (
    <Typography
      fontSize={fontChoosen}
      style={{ textTransform:"capitalize",fontFamily: "Segoe UI", ...style }}
    >
      {children}
    </Typography>
  );
};

export default ResponsiveTypography;
