import { TableCellLayout } from "@fluentui/react-components";
import * as React from "react";

import utilController from "../../../../utils/Utilcontroller";

const useTableHeader = (setOpenForm, openForm, services) => {
  const columns = [
    {
      columnId: "userId", // this is the unique id for a column
      fieldName: "User ID", // field name visible on header
      minWidth: 170,
      primaryKey: true, // this is the primary one which cant be disabled in column option
      renderCell: (item) => (
        // what should be rendered on the cell
        <TableCellLayout
          style={{ textTransform: "capitalize", color: "#323130" }}
          truncate
        >
          {item?.userId}
        </TableCellLayout>
      ),
    },
    {
      columnId: "userType", // this is the unique id for a column
      fieldName: "User Type",
      minWidth: 120,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {item?.userType === "user" ? "Customer" : item?.userType}
        </TableCellLayout>
      ),
    },

    {
      columnId: "status",
      fieldName: "status",
      minWidth: 140,
      renderCell: (item) => {
        let statusColor;
        switch (item?.status) {
          case "approved":
            statusColor = "green";
            break;
          case "rejected":
            statusColor = "red";
            break;
          case "pending":
            statusColor = "orange";
            break;
          default:
            statusColor = "black"; // Default color for other statuses
        }

        return (
          <TableCellLayout style={{ color: statusColor }} truncate>
            {item?.status.charAt(0).toUpperCase() + item?.status.slice(1)}
          </TableCellLayout>
        );
      },
    },

    {
      columnId: "userName", // this is the unique id for a column
      fieldName: "User Name",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {`${item?.fname} ${item?.lname}`}
        </TableCellLayout>
      ),
    },

    {
      columnId: "mobileNo", // this is the unique id for a column
      fieldName: "Mobile Number",
      minWidth: 160,
      renderCell: (item) => (
        <TableCellLayout
          truncate
          style={{ textTransform: "capitalize", textAlign: "end" }}
        >
          {item?.mobileNo}
        </TableCellLayout>
      ),
    },

    {
      columnId: "review", // this is the unique id for a column
      fieldName: "Review",
      minWidth: 100,
      renderCell: (item) => (
        <TableCellLayout truncate>
          {typeof item?.review?.average === "number"
            ? Math.round(item?.review?.average) + " Star"
            : ""}
        </TableCellLayout>
      ),
    },
    {
      columnId: "booking", // this is the unique id for a column
      fieldName: "Booking Count",
      minWidth: 100,
      renderCell: (item) => (
        <TableCellLayout truncate>{item?.booking?.totalCount}</TableCellLayout>
      ),
    },

    {
      columnId: "currentAddress", // this is the unique id for a column
      fieldName: "Current Address",
      minWidth: 300,
      renderCell: (item) => (
        <TableCellLayout truncate>
          {item?.currentLocationDetails?.fragmentedAddress}
        </TableCellLayout>
      ),
    },
    {
      columnId: "permanentAddress", // this is the unique id for a column
      fieldName: "Permanent Address",
      minWidth: 300,
      renderCell: (item) => (
        <TableCellLayout truncate>
          {item?.permanentAddress?.fragmentedAddress}
        </TableCellLayout>
      ),
    },

    {
      columnId: "createdOn", // this is the unique id for a column
      fieldName: "Created On",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.createdAt)}
        </TableCellLayout>
      ),
    },
    {
      columnId: "updatedat", // this is the unique id for a column
      fieldName: "Updated On",
      minWidth: 200,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.updatedAt)}
        </TableCellLayout>
      ),
    },
  ];
  return columns;
};

export default useTableHeader;
