import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  spaceBetween: {
    margin: `0 ${theme.spacingArea.large}`,
    padding: 0
  },
  label: {
    fontWeight: 600,
    fontSize: theme.fonts.heading,
  },
  distinct: {
    marginTop: theme.spacingArea.doubleLarge,
  },
  gapDoubleLarge: {
    gap: theme.spacingArea.doubleLarge,
  },
  gapMedium: {
    gap: theme.spacingArea.xLarge,
  },
  gapSmall: {
    gap: theme.spacingArea.medium,
  },
  gapXsmall: {
    gap: theme.spacingArea.small,
  },
  input: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    overflow: "hidden",
    fontSize: "14px",
    marginTop: theme.spacingArea.xSmall,
    backgroundColor: "white",
  },
  backgroundGrey: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacingArea.large,
  },
  smallComponentSpacingBetween: {
    marginTop: theme.spacingArea.large,
    paddingBottom: theme.spacingArea.small,
  },
  componentSpacingBetween: {
    marginTop: theme.spacingArea.xLarge,
    paddingBottom: theme.spacingArea.small,
  },
  increment: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    overflow: "hidden",
    fontSize: "16px",
    marginTop: theme.spacingArea.xSmall,
    backgroundColor: "white",
    width: "52px ",
    height: "41px",
    justifyContent: "center",
    alignItems: "center",
  },
  parentTitle: {
    padding: ` ${theme.spacingArea.medium} ${theme.spacingArea.large}`,
    backgroundColor: "#ECF9FF",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  itenaryComponent: {
    padding: theme.spacingArea.large,
    paddingTop: theme.spacingArea.small,
    borderRight: "1px solid #E5E5E5",
    borderLeft: "1px solid #E5E5E5",
    gap: theme.spacingArea.large,
    height: "100%",
  },
}));
