import { makeStyles } from "@mui/styles";

const drawerWidth = 250;

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: "42px",
    // width: "65px",
    objectFit: "contain",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      minHeight: theme.topNavbar.height,
    },
    minHeight: theme.topNavbar.height,
    left: 0,
    right: 0,
    backgroundImage: theme.custom.topHeader.toolbarColor,
  },
  appBarDrawerClose: {
    backgroundImage: theme.custom.topHeader.toolbarColor,
  },
  Toolbar: {
    minHeight: theme.topNavbar.height,
    marginLeft: ".5rem",
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  floatRight: {
    textAlign: "right",
  },
  languageMenuColor: {
    color: theme.custom.topHeader.toolBarLanguage,
    background: "transparent",
  },
  logoutIcon: {
    color: theme.custom.topHeader.toolBarLogout,
    // marginTop: "20px",
  },
  profile: {
    width: "40px",
    height: "35px",
    borderRadius: "100%",
    overflow: "hidden",
  },
  pageTitle: {
    color: "#000",
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    padding: "24px",
  },
}));
