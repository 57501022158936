import {
  Avatar,
  TableCellLayout,
  createTableColumn,
} from "@fluentui/react-components";
import * as React from "react";

import { MoreVertical24Regular } from "@fluentui/react-icons";
import { Popover } from "../../../../components/popover/Index";

import {
  Accessibility32Regular,
  ChevronDown24Filled,
  Edit24Regular,
  Delete32Regular,
  ConvertRange24Regular,
  Edit32Regular,
} from "@fluentui/react-icons";
import utilController from "../../../../utils/Utilcontroller";

const useTableHeader = (setOpenForm, openForm, services) => {
  const columns = [
    {
      columnId: "planId", // this is the unique id for a column
      fieldName: "Plan Id", // field name visible on header
      minWidth: 170,
      primaryKey: true, // this is the primary one which cant be disabled in column option
      renderCell: (item) => (
        // what should be rendered on the cell
        <TableCellLayout
          style={{ textTransform: "capitalize", color: "#323130" }}
          truncate
        >
          {item?.planId}
        </TableCellLayout>
      ),
    },
    {
      columnId: "planName", // this is the unique id for a column
      fieldName: "Plan Name",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {item?.planName}
        </TableCellLayout>
      ),
    },

    {
      columnId: "rechargeAmount", // this is the unique id for a column
      fieldName: "Recharge Amount",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout style={{ textTransform: "capitalize" }} truncate>
          {item?.planAmount}
        </TableCellLayout>
      ),
    },

    {
      columnId: "earningAmount", // this is the unique id for a column
      fieldName: "Earning Amount",
      minWidth: 170,
      renderCell: (item) => (
        <TableCellLayout
          truncate
          style={{ textTransform: "capitalize", textAlign: "end" }}
        >
          {item?.validityAmount}
        </TableCellLayout>
      ),
    },
  
    {
      columnId: "createdAt", // this is the unique id for a column
      fieldName: "Created On",
      minWidth: 220,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.createdAt)}
        </TableCellLayout>
      ),
    },
   
    {
      columnId: "updatedat", // this is the unique id for a column
      fieldName: "Updated On",
      minWidth: 220,
      renderCell: (item) => (
        <TableCellLayout truncate style={{ textTransform: "capitalize" }}>
          {utilController.getFormattedDate(item?.updatedAt)}
        </TableCellLayout>
      ),
    },
  ];
  return columns;
};

export default useTableHeader;
