import React from "react";
import { Field, Input, Dropdown, Option } from "@fluentui/react-components";
import { Grid } from "@mui/material";
import { DataContext } from "./AEVForm";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { ComboBox } from "@fluentui/react";

const PlanDetails = (props) => {
  const { classes, theme, userForm, setUserForm } =
    React.useContext(DataContext);

  const handleStartDateChange = (date) => {
    if (date) {
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        startDate: unixTimestamp,
      }));
    } else {
    }
  };
  const handleEndDateChange = (date) => {
    if (date) {
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        endDate: unixTimestamp,
      }));
    } else {
    }
  };

  return (
    <>
      <Grid
        spacing={theme.spacingArea.xLarge}
        marginTop={theme.spacingArea.xLarge}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={theme.spacingArea.xLarge}
            style={{
              background: "#FAFAFB",
              paddingBottom: "10px",
              marginTop: theme.spacingArea.xLarge,
            }}
          >
            <Grid item md={6} xs={12}>
              <Field className={classes.label} label="Coupon Id">
                <Input
                  autoFocus
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.couponId}
                  onChange={(e) =>
                    setUserForm({ ...userForm, couponId: e.target.value })
                  }
                  disabled={true}
                />
              </Field>
            </Grid>

            <Grid item md={6} xs={12}>
              <Field className={classes.label} label="Coupon Type">
                <Dropdown
                  size="large"
                  className={`${classes.input} input__Style`}
                  value={
                    userForm?.couponType.charAt(0).toUpperCase() +
                      userForm?.couponType.slice(1) || ""
                  }
                >
                  <Option
                    onClick={(e) => {
                      setUserForm({ ...userForm, couponType: "rating" });
                    }}
                  >
                    Rating
                  </Option>
                  <Option
                    onClick={(e) => {
                      setUserForm({ ...userForm, couponType: "normal" });
                    }}
                  >
                    Normal
                  </Option>
                </Dropdown>
              </Field>
            </Grid>

            <Grid item md={6} xs={12}>
              <Field className={classes.label} label="Coupon Name" required>
                <Input
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.couponName}
                  onChange={(e) =>
                    setUserForm({ ...userForm, couponName: e.target.value })
                  }
                />
              </Field>
            </Grid>
            {userForm?.couponType !== "normal" && (
              <>
                <Grid item md={6} xs={12}>
                  <Field
                    className={classes.label}
                    label="User Duration (in years)"
                    required
                  >
                    <Input
                      size="large"
                      className={`${classes.input} input__Style`}
                      placeholder=""
                      value={userForm?.memberSince}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        if (
                          inputValue === "" ||
                          (!isNaN(inputValue) && inputValue.trim() !== "")
                        ) {
                          setUserForm({
                            ...userForm,
                            memberSince:
                              inputValue === ""
                                ? null
                                : parseInt(inputValue, 10),
                          });
                        }
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field className={classes.label} label="User Rating" required>
                    <Input
                      size="large"
                      className={`${classes.input} input__Style`}
                      placeholder=""
                      value={userForm?.userRating}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        // Regex to match the allowed format
                        const regex = /^([1-5](\.\d{0,1})?)?$/;

                        if (regex.test(inputValue)) {
                          setUserForm({
                            ...userForm,
                            userRating: inputValue,
                          });
                        } else {
                          // If the input doesn't match the allowed format, clear the value
                          setUserForm({
                            ...userForm,
                            userRating: "",
                          });
                        }
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    className={classes.label}
                    label="No. of Attempts (per person)"
                    required
                  >
                    <Input
                      size="large"
                      className={`${classes.input} input__Style`}
                      placeholder=""
                      value={userForm?.numberOfAttempts}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (!isNaN(inputValue) && inputValue.length <= 2) {
                          setUserForm({
                            ...userForm,
                            numberOfAttempts:
                              inputValue === ""
                                ? null
                                : parseInt(inputValue, 10),
                          });
                        }
                      }}
                    />
                  </Field>
                </Grid>
              </>
            )}

            <Grid item md={6} xs={12}>
              <Field className={classes.label} label="No. of Drivers" required>
                <Input
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.noOfUsers}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (!isNaN(inputValue) && inputValue.length <= 6) {
                      setUserForm({
                        ...userForm,
                        noOfUsers:
                          inputValue === "" ? null : parseInt(inputValue, 10),
                      });
                    }
                  }}
                />
              </Field>
            </Grid>
            <Grid item md={6} xs={12}>
              <Field className={classes.label} label="Coupon Amount" required>
                <Input
                  size="large"
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.amount}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (!isNaN(inputValue) && inputValue.length <= 3) {
                      setUserForm({
                        ...userForm,
                        amount:
                          inputValue === "" ? null : parseInt(inputValue, 10),
                      });
                    }
                  }}
                />
              </Field>
            </Grid>
            <Grid item md={6} xs={12}>
              <Field className={classes.label} label="Start Date" required>
                <DatePicker
                  showWeekNumbers={false}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay={true}
                  className={`${classes.input} input__Style`}
                  placeholder="Select Start date"
                  style={{ height: "48px" }}
                  value={
                    userForm?.startDate
                      ? new Date(userForm.startDate * 1000)
                      : null
                  }
                  onSelectDate={handleStartDateChange}
                  {...props}
                />
              </Field>
            </Grid>
            <Grid item md={6} xs={12}>
              <Field className={classes.label} label="End Date">
                <DatePicker
                  showWeekNumbers={false}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay={true}
                  className={`${classes.input} input__Style`}
                  placeholder="Select End date"
                  style={{ height: "48px" }}
                  value={
                    userForm?.endDate ? new Date(userForm.endDate * 1000) : null
                  }
                  onSelectDate={handleEndDateChange}
                  {...props}
                />
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PlanDetails;
