import React, { useState } from "react";
import useAevForm from "../hooks/useAevForm";
import UserDetails from "./UserDetails";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import { useTheme } from "@mui/styles";
import { Stack, createTheme } from "@fluentui/react";
import { Typography } from "@mui/material";
export const DataContext = React.createContext();

const customTheme = createTheme({
  palette: {
    themePrimary: "#f8f8f8",
   
  },
});

const AEVForm = ({ openForm, setOpenForm, classes, services,recordId,resetRecords }) => {
  const AevFormData = useAevForm({
    openForm,
    services,
    setOpenForm,
    recordId,

  });

  const theme = useTheme();

  return (
    <DataContext.Provider
      value={{
        theme,
        classes,
        services,
        openForm,
        recordId,
        resetRecords,
        setOpenForm,
        ...AevFormData,
      }}
    >
      <FluentProvider theme={customTheme}>
      <Stack style={{ paddingTop: "25px",paddingLeft:"12px" }}>
            <Typography
              variant="title"
              style={{
                color: "#323130",
                fontFamily: "Segoe UI",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              {openForm?.divType === "add" ? "Create User" : "Edit User"}
            </Typography>
          </Stack>
        <UserDetails openForm={openForm} setOpenForm={setOpenForm} />
      </FluentProvider>
    </DataContext.Provider>
  );
};

export default AEVForm;
