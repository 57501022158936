import * as React from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import { useDispatch, useSelector } from "react-redux";
// import { openPanel, closePanel } from "../../../Redux/panelSlice";

const buttonStyles = { root: { marginRight: 8 } };

export const PanelConfirmation: React.FunctionComponent<{
  panelType: PanelType;
  description: string;
}> = (props) => {
  const dispatch = useDispatch();
  // const { isOpen } = useSelector((state) => state?.panel);

  // isOpen state for opening the panel
  // children what all should be rendered

  const {
    description,
    panelType,
    children,
    width,
    isOpen,
    isFooterAtBottom,
    onRenderFooterContent,
    hasCloseButton,
    dismissPanel,
  }: any = props;

  // const dismissPanel = () => {
  //   // dispatch(closePanel());
  // };
  // const openPanel = () => setIsOpen(!isOpen);

  // const onRenderFooterContent = React.useCallback(
  //   () => (
  //     <div>
  //       <PrimaryButton onClick={dismissPanel} styles={buttonStyles}>
  //         Save
  //       </PrimaryButton>
  //       <DefaultButton onClick={dismissPanel}>Cancel</DefaultButton>
  //     </div>
  //   ),
  //   [dismissPanel]
  // );

  return (
    <div>
      <Panel
        styles={{ root: { marginTop: 50, padding: 0 } }}
        // forceFocusInsideTrap={true}
        isBlocking={false}
        className="notification__panel"
        hasCloseButton={hasCloseButton}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        // headerText="Panel with footer at bottom"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        type={PanelType.custom}
        customWidth={width ? width : "1368px"}
        isFooterAtBottom={true}
        onOuterClick={() => undefined}
        // Stretch panel content to fill the available height so the footer is positioned
        // at the bottom of the page
      >
        <div style={{marginLeft:'16px',marginRight:'16px', backgroundColor: "#fff",
        borderRadius: "15px",}}>
        {children}

        </div>
      </Panel>
    </div>
  );
};
