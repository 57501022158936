import React, { useEffect, useState } from "react";
import APIRequest from "../../.././../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { set } from "date-fns";

const form = {
  active: true,
  profileImage: "",
  isSuperAdmin: true,
  userType: "admin",
  userName: "",
  fname: "",
  lname: "",
  experience: "",
  gender: "",
  email: "",
  mobileNo: 0,
  dob: "",
  emergencyNo: "",
  passwordAttempt: 0,
  password: "",
  currentLocationDetails: {
    fragmentedAddress: "",
    location: {
      type: "point",
      coordinates: [0, 0],
    },
  },
  permanentAddress: {
    fragmentedAddress: "",
    location: {
      type: "Point",
      coordinates: [0, 0],
    },
  },
  licenseDetails: {
    drivingLicenseNumber: 0,
    drivingLicenses: [],
    expiryDate: "",
  },
  adharNumber: 0,

  conductCertificate: [],
  bloodGroup: "",
  adharImage: [],
  permission: "",
  status: "",
  alternativeNo: 0,
};

const queryBody = {
  userType: "all",
  keyword: "",
  active: true,
  page: 0,
  pageSize: 10,
  startDate: "",
  endDate: "",
};

const useAevForm = ({ openForm, services }) => {
  const [userForm, setUserForm] = useState(form);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [allRechargablePlan, setAllRechargablePlan] = useState([]);
  const [places, setPlaces] = useState([]);
  const [currPlaceId, setCurrPlaceId] = useState("");
  const [perPLaceId, setPerPlaceId] = useState("");
  const [roles, setRoles] = useState([]);
  const [review, setReview] = useState([]);
  const [reviewCount, setReviewCount] = useState([]);

  React.useEffect(() => {
    if (openForm?.isSaveForm) {
      let isEdit = false;

      if (openForm?.divType === "edit") {
        userForm["userId"] = openForm?.rowDetails;
        isEdit = true;
      }
      let userformCopy = { ...userForm };
      userformCopy["userName"] = userformCopy["mobileNo"];
      if (userformCopy?.userType === "driver") {
        userformCopy.licenseDetails = {
          ...userformCopy?.licenseDetails,
          drivingLicenseNumber:
            userformCopy?.licenseDetails?.drivingLicenseNumber?.replace(
              /\s/g,
              ""
            ),
        };
      }

      services?.sendToServer(userForm, isEdit, services?.recordId);
    }
  }, [openForm]);

  useEffect(() => {
    if (
      openForm?.rowDetails &&
      (openForm?.divType === "edit" || openForm?.divType === "view")
    ) {
      services?.getEditTable(openForm?.rowDetails, setUserForm);
      services?.getSubscriptionHistory(
        openForm?.rowDetails,
        setSubscriptionHistory
      );
      services?.getAllPlans(openForm?.rowDetails, setAllRechargablePlan);
      getReviewCount(openForm?.rowDetails);
      getReviewsInDetailed(openForm?.rowDetails);
    }
  }, []);

  const handleAddressChange = async (keyword) => {
    // const placeName = event.target.value;

    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?keyword=${keyword}`
    ).then((res) => {
      setPlaces(res?.data?.result?.predictions);
    });
  };

  const handleLocation = async () => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getCordinates}?place_id=${currPlaceId}`
    ).then((res) => {
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        currentLocationDetails: {
          ...prevUserForm.currentLocationDetails,
          location: {
            ...prevUserForm.currentLocationDetails.location,
            type: "Point",
            coordinates: [
              res.data?.result?.result?.geometry?.location?.lng || "",
              res.data?.result?.result?.geometry?.location?.lat || "",
            ],
          },
        },
      }));
    });
  };
  const handlePerLocation = async () => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getCordinates}?place_id=${perPLaceId}`
    ).then((res) => {
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        permanentAddress: {
          ...prevUserForm.permanentAddress,
          location: {
            ...prevUserForm.permanentAddress.location,
            type: "Point",
            coordinates: [
              res.data?.result?.result?.geometry?.location?.lng || "",
              res.data?.result?.result?.geometry?.location?.lat || "",
            ],
          },
        },
      }));
    });
  };

  React.useEffect(() => {
    if (currPlaceId) {
      handleLocation();
    }
  }, [currPlaceId]);

  React.useEffect(() => {
    if (perPLaceId) {
      handlePerLocation();
    }
  }, [perPLaceId]);

  const fetchRoles = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.fetchRoles,
      JSON.stringify(queryBody)
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        setRoles(response?.data?.rows);
      }
    });
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const getReviewCount = (id) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.fetchReviewCount,
      JSON.stringify({ userId: id })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        setReviewCount(response?.data?.result);
      }
    });
  };
  const getReviewsInDetailed = (id) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.fetchReviews,
      JSON.stringify({ userId: id, page: 0, pageSize: 10 })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        setReview(response?.data?.result);
      }
    });
  };

  return {
    userForm,
    setUserForm,
    subscriptionHistory,
    allRechargablePlan,
    places,
    setPlaces,
    handleAddressChange,
    setCurrPlaceId,
    setPerPlaceId,
    roles,
    setSubscriptionHistory,
    review,
    reviewCount,
  };
};

export default useAevForm;
