import React from "react";
import { DataContext } from "../AEVForm";
import { Grid, Typography, Rating } from "@mui/material";
import { Stack } from "@fluentui/react";
import utilController from "../../../../../../utils/Utilcontroller";

const Reviews = () => {
  const { reviewCount, review, classes } = React.useContext(DataContext);
  function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  return (
    <Stack style={{ background: "#fff", marginTop: "8px" }}>
      <Stack
        style={{ marginTop: "8px", alignSelf: "flex-end", marginRight: "24px" }}
      >
        <Typography className={classes.overallRating}>
          Overall Rating:{Math.floor(reviewCount[0]?.average)} / 5
        </Typography>
      </Stack>
      <Stack style={{ marginTop: "8px" }}>
        {review &&
          review?.map((item, index) => (
            <React.Fragment key={index}>
              <Grid className={classes.reviewBackGround}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Stack>
                      <Typography className={classes.reviewName}>
                        {item?.userId?.fname} {item?.userId?.lname}
                      </Typography>
                    </Stack>
                    <Stack style={{ marginTop: "3px" }}>
                      <Typography className={classes.reviewDate}>
                        {formatDate(item?.updatedAt)}
                      </Typography>
                    </Stack>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.reviewName}>
                        Punctuality:
                      </Typography>
                      <Rating
                        value={item?.ratings?.punctuality}
                        readOnly
                        size="small"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.reviewName}>
                        Behavior:
                      </Typography>
                      <Rating
                        value={item?.ratings?.behavior}
                        readOnly
                        size="small"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.reviewName}>
                        Driving Skills:
                      </Typography>
                      <Rating
                        name="read-only"
                        value={item?.ratings?.drivingSkills}
                        readOnly
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "14px" }}>
                  <Typography className={classes.feedback}>
                    {item?.feedback}
                  </Typography>
                </div>
              </Grid>
            </React.Fragment>
          ))}
      </Stack>
    </Stack>
  );
};

export default Reviews;
