import React, { useState } from "react";
import { useEffect } from "react";
import LocalStorage from "../../../../../config/LocalStorage";
import APIRequestDataTableQuery from "../../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import apiFetchRequest from "../../../../../utils/ApiFetchRequest";
import useAlert from "../../../../../hooks/useAlert";
import utilController from "../../../../../utils/Utilcontroller";

// this is api calls happen
const useServices = (props) => {
  const { openForm, recordId } = props;
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  const [loading, setLoading] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { publishNotification } = useAlert();

  useEffect(() => {
    if (!props.query) return;
    tableQuery(props?.query);
    // publishNotification("Something went wrong ", "error");
  }, [props.query, LocalStorage.adminButtonPermission]);
  // table query body value handler
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "query" });
      if (
        LocalStorage.adminButtonPermission.find(function (element) {
          return element.button === "query" ? true : false;
        })
      ) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.fetchAllCoupons,
          JSON.stringify(query)
        ).then((tableData) => {
          setTableData(tableData?.data);
          setLoading({ ...loading, isOpen: false, type: "" });
        });
      } else {
        // notification(props.t('toaster.tableRecordPermission'), 'error');
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");

      console.log(err);
    } finally {
    }
  };

  const getEditTable = async (
    recordId,
    setUserForm,
    setItenary,
    setTotalDays,
    setEditor
  ) => {
    try {
      setLoading({ ...loading, isOpen: true, type: "edit" });

      const response = await apiFetchRequest(
        "POST",
        ConfigAPIURL.couponDetails,
        JSON.stringify({ recordId: recordId })
      );

      if (response) {
        setUserForm({
          couponId: response?.couponId,
          couponName: response?.couponName,
          couponType: response?.couponType,
          userRating: response?.userRating,
          memberSince: response?.memberSince,
          numberOfAttempts: response?.numberOfAttempts,
          noOfUsers: response?.noOfUsers,
          amount: response?.amount,
          startDate: response?.startDate,
          endDate: response?.endDate,
        });
      } else {
        publishNotification("Something went wrong ", "error");

        //show the popup something went wrong
      }
    } catch (err) {
      publishNotification(
        "Something went wrong while recieving data ",
        err,
        "error"
      );
    } finally {
      setLoading({ ...loading, isOpen: false, type: "" });
    }
  };

  const handleDeleteRecord = async (recordId) => {
    recordId.map((id) => {
      const payload = {
        recordId: id?._id,
        active: false,
      };

      APIRequest.request(
        "POST",
        ConfigAPIURL.updateCoupon,
        JSON.stringify(payload)
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response?.data?.responseCode === 109) {
            publishNotification("Coupons Deleted Successfully", "success");
            tableQuery(props.query);
            props.resetRecords();
            props?.resetForm();
          }
        }
      });
    });
  };

  const sendToServer = async (userForm, isEdit) => {
    const userFormCopy = { ...userForm };
    if (openForm?.divType === "edit") {
      userFormCopy.recordId = recordId[0]?._id;
    }
    const url =
      openForm?.divType === "edit"
        ? ConfigAPIURL.updateCoupon
        : ConfigAPIURL.createCoupon;
    const fieldValidation = [];
    if (userFormCopy?.couponType === "normal") {
      fieldValidation.push(
        "couponName",
        "noOfUsers",
        "startDate",
        "amount",
        "couponType"
      );
    } else {
      fieldValidation.push(
        "couponName",
        "memberSince",
        "userRating",
        "numberOfAttempts",
        "noOfUsers",
        "startDate",
        "amount",
        "couponType"
      );
    }

    const hasEmptyField = fieldValidation.some((field) => !userFormCopy[field]);

    if (hasEmptyField) {
      publishNotification("Please fill all the required fields", "error");
      return;
    }

    try {
      const response = await apiFetchRequest(
        "POST",
        url,
        JSON.stringify(userForm)
      );
      if (response) {
        // show the popup successfully created lead
        publishNotification(
          `Successfully ${isEdit ? "Updated" : "created"} Item`,
          "success"
        );
        // close the panel and make request to fetch the results
       const query = {
        userType: "all",
        keyword: "",
        active: true,
        page: 0,
        pageSize: 10,
        startDate: null,
        endDate: new Date() / 1000,
       };
        tableQuery(query);
        props.resetForm();
        props?.resetRecords();
      } else {
        publishNotification(
          `Something went wrong while ${isEdit ? "Updating" : "Creating"} `,
          "error"
        );

        //show the popup something went wrong
      }
    } catch (err) {
      publishNotification("Something went wrong ", "error");
    }
  };

  return {
    tableData,
    setTableData,
    loading,
    sendToServer,
    getEditTable,
    handleDeleteRecord,
    tableQuery,
    query: props?.query,
    resetForm: props?.resetForm,
  };
};

export default useServices;
