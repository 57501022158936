import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "react-dual-listbox/lib/react-dual-listbox.css";
import DateFnsUtils from "@date-io/date-fns";
// import ChipInput from 'material-ui-chip-input';
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { connect } from "react-redux";
import DualListBox from "react-dual-listbox";
import { makeStyles } from "@mui/styles";
import { Grid, Paper } from "@mui/material";
import Typography from "../../../../../components/Text/Typography";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

// const icons = {
//   leftArrow: "fa-arrow-left",
//   rightArrow: "fa-arrow-right",
// };

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));
function AssignRole(props) {
  const classes = useStyles();
  const { isAssign, recordId, assigned, setAssigned } = props;

  const [unAssigned, setUnAssigned] = useState([]);

  useEffect(() => {
    getEditable();
  }, [isAssign]);

  const handleDualchange = (selected, value) => {
    setAssigned(selected);
  };

  const resetForm = () => {
    setAssigned([]);
    setUnAssigned([]);
  };

  const getEditable = () => {
    if (isAssign) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.roleUnassigned,
        JSON.stringify({ roleId: recordId })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setAssigned(response.data.admins);

            let employeeList = [];
            response.data.employees.map((employees) => {
              employeeList.push({
                label: employees.fname + " " + employees.lname,
                value: employees._id,
              });
            });
            setUnAssigned(employeeList);
          }
        }
      });
    }
  };

  return (
    <div
      className={classes.root}
      style={{ background: "#f8f8f8", width: "100%" }}
    >
      <Grid container style={{ height: "100%", width: "100%" }}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ height: "100%", width: "100%" }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="title">
              {props.t("role.assignRole")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ background: "#f8f8f8", width: "100%" }}>
            <Grid item xs={12} style={{ padding: "12px" }}>
              <DualListBox
                icons={{
                  moveLeft: "<",
                  moveAllLeft: "<<",
                  moveRight: ">",
                  moveAllRight: ">>",
                }}
                // icons={icons}
                sortable={true}
                searchable={true}
                options={unAssigned}
                selected={assigned}
                onChange={handleDualchange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AssignRole)
);
