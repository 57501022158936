import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Snackbar,
  Link,
  Grid,
  Divider,
  Typography,
  Alert,
  InputAdornment,
} from "@mui/material";
import { IconButton } from "@mui/material";
import driveLogo from "../../../assets/images/driveLogo.png";
import { makeStyles } from "@mui/styles";
import SlideInDialog from "../../../templates/dialog/SlideInDialog";
// import images from "../../../assets/images/loginImage.png";
import { Label } from "@fluentui/react-components";
import Logo from "../../../assets/images/loginLogo.png";
import OtpInput from "react18-input-otp";
import "../../../index.css";
import useAlert from "../../../hooks/useAlert";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import loginLogo from "../../../assets/images/loginLogo.png";
import { Config } from "aws-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    cursor: "default",

    width: "100%",
  },
  imageGrid: {
    width: "100%",
    height: "100%",
  },

  vectorDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "400px",
    width: "500px",
  },
  loginGrid: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255)",
  },
  paper: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },

  avatar: {
    margin: 1,
    backgroundColor: "#8BC6EC",
  },
  form: {
    width: "100%",
    // opacity: 1,
    fontFamily: "Segoe UI",
    marginTop: "12px",
  },

  forgotPassword: {
    display: "flex",
    justifyContent: "end",
    margin: 0,
    fontFamily: "Segoe UI",
  },
  submit: {
    borderRadius: "12px !important",
    background: "#DB2D2E",
    padding: "12px 4px !important",
    marginTop: "20px",
    fontFamily: "Segoe UI",
    fontWeight: 600,
  },
  textAlign: {
    textAlign: "end",
  },
  firstGrid: {
    width: "100%",
    height: "100%",
    background: "#268581",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  forgotPageGrid: {
    width: "100%",
    height: "100%",
    // background: `url(${forgotImage})`, // Set the URL of the default image
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

const LoginPage = (props) => {
  const classes = useStyles();
  const { publishNotification } = useAlert();
  const {
    userName,
    setUserName,
    password,
    setPassword,
    showPassword,
    verifyOTP,
    submitMobileLogin,
    handleDeleteRequest,
    handleSlideInDialog,
    handleTogglePassword,
    handlePasswordChange,
    notificationClose,
    snakbarValues,
    disableOtpBtn,
    slideInDialog,
    setOTP,
    twoFactAut,
    otp,
    resendOTP,
    setTowFactAuth,
    resetTimer,
    minutes,
    seconds,
    setMinutes,
    setSeconds,
    passwordFields,
    forgotResendOtp,
    verifyForgotPasswordOTP,
    setPasswordFields,
    isLogin,
    history,
    blockEntry,
    setBlockEntry,
  } = props;

  const handleEdit = () => {
    setTowFactAuth(false);
    setUserName("");
    setPassword("");
    setOTP("");
  };

  const [forgotPassword, setForgotPassword] = useState(false);
  const [otpState, setOtpState] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setshowNewPassword] = useState(false);
  const [showConfirmPass, setShowConPass] = useState(false);
  const [userId, setuserId] = useState("");

  const handleForgotPassword = () => {
    setForgotPassword(true);
    localStorage.clear();
  };

  useEffect(() => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        if (response.data.responseCode === 109) {
          const id = response.data.user?._id;
          setuserId(id);
        }
      }
    );
  }, [passwordFields]);

  useEffect(() => {
    if (twoFactAut || otpState) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [twoFactAut, seconds, minutes, otpState]);

  const [isFocused, setIsFocused] = useState(false);
  const [isPassFocused, setpassFocused] = useState(false);
  const [confirmPassFocused, setConfirmPassFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handlePass = () => {
    setpassFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const passBlur = () => {
    setpassFocused(false);
  };

  const handleConfirmPassBlur = () => {
    setConfirmPassFocused(false);
  };
  const handleConFocused = () => {
    setConfirmPassFocused(true);
  };

  //calling getOtp function here

  const getOtp = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOtp,
      JSON.stringify({ mobileNo: userName })
    ).then((response) => {
      if (response?.data?.responseCode === 122) {
        // const token = response?.data?.token;
        // localStorage.setItem("token", token);
        // setToken(response?.data?.token);
        setOtpState(true);
      } else {
        publishNotification("Something went wrong contact Admin", "error");
      }
    });
  };

  const toggleNewPassword = () => {
    setshowNewPassword(!showNewPassword);
  };

  const toggleConPassword = () => {
    setShowConPass(!showConfirmPass);
  };

  //here we are calling the update profile api to update the password

  const passwordMatching = newPassword === confirmPassword;

  const updatePassword = () => {
    if (passwordMatching) {
      APIRequest.request(
        "PUT",
        ConfigAPIURL.userUpdate,
        JSON.stringify({ password: newPassword, userId: userId })
      ).then((response) => {
        if (response.data.responseCode === 109) {
          publishNotification("Password Changed Successfully", "success");
          // isLogin();
          history.replace("/login");
          localStorage.clear();
          sessionStorage.clear();
          setPasswordFields(false);
          setForgotPassword(false);
          setOtpState(false);
          setOTP("");
          setPassword("");
          setUserName("");
          setNewPassword("");
          setConfirmPassword("");
        }
      });
    } else {
      publishNotification(
        "Password and Confirm Password should be same",
        "error"
      );
    }
  };

  return (
    <Grid container className={classes.root}>
      <div style={{ position: "relative" }}>
        <img
          src={driveLogo}
          alt="company Logo"
          style={{
            position: "absolute",
            cursor: "pointer",
            top: 0,
            left: 0,
            width: "55px",
            height: "55px",
            marginLeft: "14px",
            marginTop: "8px",
            // filter:
            // "invert(48%) sepia(35%) saturate(2041%) hue-rotate(179deg) brightness(104%) contrast(98%)",
          }}
        />
      </div>

      <Grid
        container
        xl={6}
        lg={6}
        sm={12}
        xs={12}
        className={classes.firstGrid}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginBottom: "220px" }}>
          <Typography
            style={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "38px",
            }}
          >
            Discover top-notch drivers for your vehicle
            <div>– one call away, ready to hire</div>
          </Typography>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          <img
            src={loginLogo}
            alt="company Logo"
            style={{
              width: "80%",
              height: "70%",
            }}
          />
        </div>
      </Grid>
      <Grid item xl={6} lg={6} sm={12} xs={12} className={classes.loginGrid}>
        <div className={classes.paper}>
          <Grid
            container
            style={{
              margin: "auto",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {!passwordFields && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#000",
                      fontFamily: "Segoe UI",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    {!twoFactAut && !forgotPassword
                      ? "Login to your account"
                      : forgotPassword
                      ? "Forgot Password"
                      : "OTP Verification"}
                  </Typography>
                  <Typography
                    style={{
                      color: " #868686",
                      fontFamily: "Segoe UI",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      marginTop: "8px",
                    }}
                  >
                    {!twoFactAut && !forgotPassword ? (
                      "Get started by entering a few details"
                    ) : forgotPassword && !otpState ? (
                      <div>
                        Enter your Mobile No below to recover the forgotten
                        password
                      </div>
                    ) : otpState ? (
                      "OTP sent to your mobile number"
                    ) : (
                      "OTP sent to your mobile number"
                    )}
                  </Typography>
                </div>
              </>
            )}
            {passwordFields && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#000",
                    fontFamily: "Segoe UI",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }}
                >
                  Reset Password
                </Typography>
                <Typography
                  style={{
                    color: " #868686",
                    fontFamily: "Segoe UI",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    marginTop: "8px",
                  }}
                >
                  Choose your new password
                </Typography>
              </div>
            )}

            <Typography
              style={{
                color: " #868686",
                fontFamily: "Segoe UI",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginTop: "8px",
              }}
            >
              {twoFactAut && (
                <div style={{ display: "flex", gap: "7px" }}>
                  <Typography
                    style={{
                      color: "#323130",
                      fontFamily: "Segoe UI",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {userName}
                  </Typography>
                  {"   "}
                  <Typography
                    style={{
                      color: "#268581",
                      fontFamily: "Segoe UI",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit()}
                  >
                    Edit
                  </Typography>
                </div>
              )}
            </Typography>
          </Grid>

          {!twoFactAut ? (
            <div className={classes.form}>
              <div
                style={{
                  // marginBottom: "-4px",
                  display: passwordFields ? "none" : "",
                }}
              >
                <Label>
                  <Typography
                    style={{
                      color: "#323130",
                      fontFamily: "Segoe UI",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    Mobile Number
                  </Typography>
                </Label>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                // type="number"
                required
                fullWidth
                value={userName}
                disabled={otpState}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    background: "#FFF",
                    border: "1px solid #E5E5E5",
                    fontFamily: "Segoe UI",
                    display: passwordFields ? "none" : "",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {isFocused ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="#268581" // Set fill color to red when focused
                        >
                          <path d="M7.77189 2.43881L8.84789 2.09481C9.85789 1.77281 10.9349 2.29381 11.3679 3.31181L12.2269 5.33981C12.6009 6.22281 12.3939 7.26181 11.7129 7.90781L9.81889 9.70581C9.93489 10.7818 10.2969 11.8408 10.9029 12.8828C11.4787 13.891 12.2509 14.7734 13.1739 15.4778L15.4489 14.7178C16.3119 14.4308 17.2509 14.7618 17.7789 15.5388L19.0119 17.3488C19.6269 18.2528 19.5169 19.4988 18.7539 20.2648L17.9359 21.0858C17.1219 21.9028 15.9589 22.1998 14.8839 21.8638C12.3449 21.0718 10.0109 18.7208 7.88089 14.8108C5.74789 10.8948 4.99489 7.57081 5.62289 4.84281C5.88689 3.69481 6.70389 2.77981 7.77189 2.43881Z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="#000" // Set fill color to black when not focused
                        >
                          <path d="M7.77189 2.43881L8.84789 2.09481C9.85789 1.77281 10.9349 2.29381 11.3679 3.31181L12.2269 5.33981C12.6009 6.22281 12.3939 7.26181 11.7129 7.90781L9.81889 9.70581C9.93489 10.7818 10.2969 11.8408 10.9029 12.8828C11.4787 13.891 12.2509 14.7734 13.1739 15.4778L15.4489 14.7178C16.3119 14.4308 17.2509 14.7618 17.7789 15.5388L19.0119 17.3488C19.6269 18.2528 19.5169 19.4988 18.7539 20.2648L17.9359 21.0858C17.1219 21.9028 15.9589 22.1998 14.8839 21.8638C12.3449 21.0718 10.0109 18.7208 7.88089 14.8108C5.74789 10.8948 4.99489 7.57081 5.62289 4.84281C5.88689 3.69481 6.70389 2.77981 7.77189 2.43881Z" />
                        </svg>
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode: "numeric", // Set input mode to "numeric" to hide the arrow spinner
                  style: {
                    paddingRight: "0", // Remove the right padding
                  },
                }}
                onChange={(event) => {
                  const numericValue = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );

                  const truncatedValue = numericValue.slice(0, 10);

                  setUserName(truncatedValue);
                }}
                id="mobileNo"
                autoComplete="off"
                placeholder="Enter Mobile No."
                name="mobileNo"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />

              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "-4px",
                  display: forgotPassword ? "none" : "",
                }}
              >
                <Label>
                  <Typography
                    style={{
                      color: "#323130",
                      fontFamily: "Segoe UI",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    Password
                  </Typography>
                </Label>
              </div>

              <TextField
                variant="outlined"
                margin="normal"
                required
                value={password}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    background: "#FFF",
                    border: "1px solid #E5E5E5",
                    fontFamily: "Segoe UI",
                    display: forgotPassword ? "none" : "",
                  },
                  type: showPassword ? "text" : "password",
                  endAdornment: (
                    <IconButton onClick={handleTogglePassword}>
                      {!showPassword ? (
                        <VisibilityOffIcon
                          style={{ color: isPassFocused ? "#268581" : "black" }}
                        />
                      ) : (
                        <VisibilityIcon
                          style={{ color: isPassFocused ? "#268581" : "black" }}
                        />
                      )}
                    </IconButton>
                  ),
                }}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                name="password"
                autoComplete="off"
                placeholder="Enter password"
                id="password"
                onFocus={handlePass}
                onBlur={passBlur}
              />

              <div
                className={classes.forgotPassword}
                style={{ display: otpState || forgotPassword ? "none" : "" }}
              >
                <Link
                  href="javascript:void(0)"
                  onClick={handleForgotPassword}
                  style={{
                    textDecoration: "none",
                    fontFamily: "Segoe UI",
                  }}
                >
                  <Typography
                    style={{
                      color: "#212121",
                      fontFamily: "Segoe UI",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              </div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() =>
                  forgotPassword ? getOtp() : submitMobileLogin()
                }
                style={{
                  marginTop: "56px",
                  borderRadius: "12px",
                  background: "#268581",
                  fontFamily: "Segoe UI",
                  fontWeight: 600,
                  display: otpState ? "none" : "",
                }}
              >
                {forgotPassword ? "verify" : "Continue"}
              </Button>
            </div>
          ) : (
            <div className={classes.form}>
              <OtpInput
                id="demo_input"
                value={otp}
                onChange={setOTP}
                isDisabled={blockEntry}
                numInputs={6}
                separator={<span style={{ color: "#666" }}> </span>}
                containerStyle={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  width: "95%",
                  maxWidth: "500px",
                  margin: "0 auto",
                  fontFamily: "Segoe UI",
                }}
                inputStyle={{
                  flex: "1",
                  width: "30%",
                  height: "50px",
                  textAlign: "center",
                  border: "2px solid #E1E1E1",
                  backgroundColor: "#F0F5F9",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "10px",
                  outline: "none",
                  transition: "border-color 0.3s, background-color 0.3s",
                }}
                focusStyle={{
                  borderColor: "#3498db !important",
                  backgroundColor: "#ECF5FC",
                }}
                isInputNum={true}
              />

              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // gap:"100%"
                    }}
                  >
                    <div>
                      {seconds !== 0 ? (
                        <Link
                          variant="body2"
                          disabled={seconds !== 0}
                          style={{
                            textDecoration: "none",
                            marginTop: "8px",
                            color: "#868686",
                            cursor: seconds !== 0 ? "not-allowed" : "pointer",
                            fontFamily: "Segoe UI",
                          }}
                        >
                          Resend OTP
                        </Link>
                      ) : (
                        <Link
                          onClick={resendOTP}
                          variant="body2"
                          disabled={seconds !== 0}
                          style={{
                            textDecoration: "none",
                            marginTop: "8px",
                            color: seconds === 0 ? "#268581" : "#868686",
                            cursor: "pointer",
                            fontFamily: "Segoe UI",
                          }}
                        >
                          Resend OTP
                        </Link>
                      )}
                    </div>
                    <div>
                      <span style={{ fontFamily: "Segoe UI" }}>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => verifyOTP()}
                style={{
                  marginTop: "60px",
                  borderRadius: "12px",
                  background: "#268581",
                  fontFamily: "Segoe UI",
                  fontWeight: 600,
                }}
              >
                Login
              </Button>
            </div>
          )}

          {/* here we are setting up the fields for otp verification for forgot password reset */}
          {otpState && (
            <div
              style={{
                width: "100%",
                fontFamily: "Segoe UI",
                // marginTop: "8px",
              }}
            >
              <div
                style={{
                  display: passwordFields ? "none" : "",
                }}
              >
                <Label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "10px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#268581",
                      fontFamily: "Segoe UI",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    OTP
                  </Typography>
                </Label>
              </div>
              <OtpInput
                id="demo_input"
                value={otp}
                onChange={setOTP}
                isDisabled={blockEntry}
                numInputs={6}
                separator={<span style={{ color: "#666" }}> </span>}
                containerStyle={{
                  justifyContent: "center",
                  // display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  width: "96%",
                  maxWidth: "510px",
                  margin: "0 auto",
                  fontFamily: "Segoe UI",
                  display: passwordFields ? "none" : "flex",
                }}
                inputStyle={{
                  flex: "1",
                  width: "30%",
                  height: "50px",
                  textAlign: "center",
                  border: "2px solid #E1E1E1",
                  backgroundColor: "#F0F5F9",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "10px",
                  outline: "none",
                  transition: "border-color 0.3s, background-color 0.3s",
                }}
                focusStyle={{
                  borderColor: "#3498db !important",
                  backgroundColor: "#ECF5FC",
                }}
                isInputNum={true}
              />
              <Grid
                container
                style={{
                  marginTop: "16px",
                  display: passwordFields ? "none" : "",
                }}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // gap:"100%"
                    }}
                  >
                    <div>
                      {seconds !== 0 ? (
                        <Link
                          variant="body2"
                          disabled={seconds !== 0}
                          style={{
                            textDecoration: "none",
                            marginTop: "8px",
                            color: "#868686",
                            cursor: seconds !== 0 ? "not-allowed" : "pointer",
                            fontFamily: "Segoe UI",
                          }}
                        >
                          Resend OTP
                        </Link>
                      ) : (
                        <Link
                          onClick={forgotResendOtp}
                          variant="body2"
                          disabled={seconds !== 0}
                          style={{
                            textDecoration: "none",
                            marginTop: "8px",
                            color: seconds === 0 ? "red" : "#868686",
                            cursor: "pointer",
                            fontFamily: "Segoe UI",
                          }}
                        >
                          Resend OTP
                        </Link>
                      )}
                    </div>
                    <div>
                      <span style={{ fontFamily: "Segoe UI" }}>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {passwordFields && (
                <>
                  <Label>
                    <Typography
                      style={{
                        color: "#323130",
                        fontFamily: "Segoe UI",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                      }}
                    >
                      New Password
                    </Typography>
                  </Label>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={newPassword}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        background: "#FFF",
                        border: "1px solid #E5E5E5",
                        fontFamily: "Segoe UI",
                      },
                      type: showNewPassword ? "text" : "password",
                      endAdornment: (
                        <IconButton onClick={toggleNewPassword}>
                          {!showNewPassword ? (
                            <VisibilityOffIcon
                              style={{
                                color: isPassFocused ? "#268581" : "black",
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{
                                color: isPassFocused ? "#268581" : "black",
                              }}
                            />
                          )}
                        </IconButton>
                      ),
                    }}
                    onChange={(event) => setNewPassword(event.target.value)}
                    fullWidth
                    name="password"
                    autoComplete="off"
                    placeholder="Enter password"
                    id="password"
                    onFocus={handlePass}
                    onBlur={passBlur}
                  />
                  <Label>
                    <Typography
                      style={{
                        color: "#323130",
                        fontFamily: "Segoe UI",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        marginTop: "16px",
                      }}
                    >
                      Confirm Password
                    </Typography>
                  </Label>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={confirmPassword}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        background: "#FFF",
                        border: "1px solid #E5E5E5",
                        fontFamily: "Segoe UI",
                      },
                      type: showConfirmPass ? "text" : "password",
                      endAdornment: (
                        <IconButton onClick={toggleConPassword}>
                          {!showConfirmPass ? (
                            <VisibilityOffIcon
                              style={{
                                color: confirmPassFocused ? "#268581" : "black",
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{
                                color: confirmPassFocused ? "#268581" : "black",
                              }}
                            />
                          )}
                        </IconButton>
                      ),
                    }}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    fullWidth
                    name="password"
                    autoComplete="off"
                    placeholder="Enter password"
                    id="password"
                    onFocus={handleConFocused}
                    onBlur={handleConfirmPassBlur}
                  />
                </>
              )}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() =>
                  passwordFields ? updatePassword() : verifyForgotPasswordOTP()
                }
                style={{
                  marginTop: "60px",
                  borderRadius: "12px",
                  background: "#268581",
                  fontFamily: "Segoe UI",
                  fontWeight: 600,
                }}
              >
                {passwordFields ? "Save" : "Verify"}
              </Button>
            </div>
          )}
        </div>
      </Grid>

      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default LoginPage;
