import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
    background: "#FFF",
    boxShadow:
      "0px 6.400000095367432px 14.399999618530273px 0px rgba(0, 0, 0, 0.13), 0px 1.2000000476837158px 3.5999999046325684px 0px rgba(0, 0, 0, 0.10)",
    padding: theme.spacingArea.large,
  },
}));

const BoxContainer = ({ children, style }) => {
  const classes = useStyles();
  return (
    <Stack className={classes.root} style={{ ...style }}>
      {children}
    </Stack>
  );
};

export default BoxContainer;
