import React from "react";
import Typography from "../../../../../../components/Text/Typography";
import { DataContext } from "../AEVForm";
import { Grid, Stack } from "@mui/material";
import { Combobox, Field, Input, Option } from "@fluentui/react-components";
import { Tabs, Tab } from "@mui/material";
import LocalTrip from "./Tabs/LocalTrip";
import Outstation from "./Tabs/OutStation";
import LongDistance from "./Tabs/LongDistance";
import APIRequest from "../../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../../config/ConfigAPIURL";
import { Dropdown } from "@fluentui/react";

const DetailsPage = () => {
  const { classes, theme, userForm, setUserForm } =
    React.useContext(DataContext);
  const tabLabels = ["Local Trip", "Long Distance", "Outstation"];

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [places, setPlaces] = React.useState([]);
  const [placeId, setPlaceId] = React.useState("");

  const handleAddressChange = async (keyword) => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getLocation}?keyword=${keyword}`
    ).then((res) => {
      setPlaces(res.data.result.predictions);
    });
  };

  const handleLocation = async () => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.getCordinates}?place_id=${placeId}`
    ).then((res) => {
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        locationDetails: {
          ...prevUserForm.locationDetails,
          location: {
            ...prevUserForm.locationDetails.location,
            type: "Point",
            coordinates: [
              res.data?.result?.result?.geometry?.location?.lng || "",
              res.data?.result?.result?.geometry?.location?.lat || "",
            ],
          },
        },
      }));
    });
  };

  React.useMemo(() => {
    
    handleAddressChange(userForm?.locationDetails?.fragmentedAddress);
  }, [userForm?.locationDetails]);

  React.useMemo(() => {
    if (placeId) {
      handleLocation();
    }
  }, [placeId]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tariffTypeOptions = ["default", "variable"];

  return (
    <Grid style={{ padding: "12px", marginTop: "12px" }}>
      <Grid
        container
        xs={12}
        lg={12}
        md={12}
        sm={12}
        xl={12}
        spacing={theme.spacingArea.xLarge}
      >
        <Grid item xs={4}>
          <Field className={classes.label} label="Tariff Type" required>
            <Combobox
              value={
                (userForm?.tariffType).charAt(0).toUpperCase() +
                (userForm?.tariffType).slice(1)
              }
              size="large"
              className={`${classes.input} input__Style`}
              style={{ textTransform: "capitalize", height: "48px" }}
              onInput={(e) => {
                setUserForm({
                  ...userForm,
                  tariffType: e.target.value,
                });
              }}
              onOptionSelect={(e, data) => {
                setUserForm({
                  ...userForm,
                  tariffType: data?.optionValue,
                });
              }}
            >
              {tariffTypeOptions.map((option) => (
                <Option
                  key={option}
                  value={option}
                  style={{ textTransform: "capitalize" }}
                >
                  {option}
                </Option>
              ))}
            </Combobox>
          </Field>
        </Grid>

        <Grid item xs={4}>
          <Field className={classes.label} label="Location" required>
            <Combobox
              style={{ height: "48px" }}
              onInput={(event) => {
                setUserForm({
                  ...userForm,
                  locationDetails: {
                    ...userForm.locationDetails,
                    fragmentedAddress: event.target.value,
                  },
                });
              }}
              value={userForm?.locationDetails?.fragmentedAddress || ""}
              className={`${classes.input} input__Style`}
              onOptionSelect={(event, value) => {
                setUserForm((prevUserForm) => ({
                  ...prevUserForm,
                  locationDetails: {
                    ...prevUserForm.locationDetails,

                    fragmentedAddress: value?.optionValue,
                  },
                }));
              }}
            >
              {places.map((option) => (
                <Option
                  key={option}
                  onClick={() => setPlaceId(option?.place_id)}
                >
                  {option?.description}
                </Option>
              ))}
            </Combobox>
          </Field>
        </Grid>
        {userForm?.tariffType === "variable" && (
          <Grid item xs={4}>
            <Field className={classes.label} label="Radius (Km)" required>
              <Input
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                style={{ height: "48px" }}
                type="number"
                value={userForm?.locationDetails?.radius || ""}
                onChange={(e) => {
                  setUserForm((prevUserForm) => ({
                    ...prevUserForm,
                    locationDetails: {
                      ...prevUserForm.locationDetails,
                      radius: e.target.value,
                    },
                  }));
                }}
              />
            </Field>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          lg={12}
          md={12}
          sm={12}
          xl={12}
          spacing={theme.spacingArea.xLarge}
        >
          {/* <Grid item xs={4}>
            <Field
              className={classes.label}
              label="Extra time fare( per min)"
              required
            >
              <Input
                size="large"
                className={`${classes.input} input__Style`}
                placeholder=""
                style={{ height: "48px" }}
                value={userForm?.extraTime?.pricePerMinute || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  const isValidInput = /^\d{0,4}(\.\d{0,2})?$/.test(inputValue);

                  if (isValidInput) {
                    setUserForm((prevUserForm) => ({
                      ...prevUserForm,
                      extraTime: {
                        ...prevUserForm.extraTime,
                        pricePerMinute: inputValue,
                      },
                    }));
                  }
                }}
              />
            </Field>
          </Grid> */}
          <Grid item xs={4}>
            <Field
              className={classes.label}
              label="Night Fare ( per Day)"
              required
            >
              <Input
                size="large"
                style={{ height: "48px" }}
                className={`${classes.input} input__Style`}
                placeholder=""
                value={userForm?.nightFare?.price || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValidInput = /^\d{0,3}$/.test(inputValue);

                  if (isValidInput) {
                    setUserForm((prevUserForm) => ({
                      ...prevUserForm,
                      nightFare: {
                        ...prevUserForm.nightFare,
                        price: inputValue,
                      },
                    }));
                  }
                }}
              />
            </Field>
          </Grid>
          {selectedTab === 2 && (
            <Grid item xs={4}>
              <Field
                className={classes.label}
                label="Food Allowance  (per Day)"
                required
              >
                <Input
                  size="large"
                  style={{ height: "48px" }}
                  className={`${classes.input} input__Style`}
                  placeholder=""
                  value={userForm?.foodAllowance || ""}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const validatedValue = enteredValue
                      .replace(/\D/g, "")
                      .slice(0, 4);

                    setUserForm({
                      ...userForm,
                      foodAllowance: validatedValue,
                    });
                  }}
                />
              </Field>
            </Grid>
          )}
          <div
            style={{
              background: "#F8F8F8",
              marginTop: "40px",
              width: "100%",
              marginLeft: "12px",
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabLabels.map((label, index) => (
                <Tab
                  key={label}
                  label={capitalizeFirstLetter(label)}
                  style={{
                    textTransform: "none",
                    fontWeight: selectedTab === index ? 600 : 400,
                  }}
                />
              ))}
            </Tabs>
          </div>

          {selectedTab === 0 && (
            <LocalTrip
              setUserForm={setUserForm}
              userForm={userForm}
              selectedTab={selectedTab}
            />
          )}

          {selectedTab === 1 && (
            <LongDistance setUserForm={setUserForm} userForm={userForm} />
          )}

          {selectedTab === 2 && (
            <Outstation setUserForm={setUserForm} userForm={userForm} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsPage;
