import React from "react";

const useDownloadCSV = (columns, items) => {

  const filterVisbleColumn = (columns, viewColumn) => {
    // filters the column ids
    const visibleColumnIds = viewColumn
      ?.filter((value) => value?.visibility)
      ?.map((value) => value?.columnId);
    // checks whether the column passed is viewable or not
    const updatedColumns = columns?.filter((value) =>
      visibleColumnIds?.includes(value?.columnId)
    );
    // which all columns are visible that will be returned
    return updatedColumns;
  };

  const downloadCsv = (viewColumn) => {
    const allColumns = filterVisbleColumn(columns, viewColumn);
    // only visible columns will be returned and start processing

    const headers = allColumns?.map((value) => value?.fieldName);

    const data = items?.map((value) => {
      const singleRow = {};

      columns?.map((item) => {
        const columnId = item?.fieldName;
        singleRow[columnId] = item?.renderCell(value)?.props?.children;
      });

      return singleRow;
    });

    return { data, headers };
  };
  return { downloadCsv };
};

export default useDownloadCSV;
