import React, { lazy, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
// import Prototype from "./prototype";
import Users from "../admin/Users/index";
import Bookings from "./Bookings";
import Roles from "../admin/Roles/index";
import Plan from "../admin/Plans/index";
import Tarrif from "./Tarrif";
import Dashboard from "./Dashboard/Index";
import Coupons from "./Coupons/index"

// import Overview from "./overview";
// const User = React.lazy(() => {
// 	import("./user"); IKARD00160
// });
// const Overview = React.lazy(() => {
//   import("./overview");
// });
// const Leads = React.lazy(() => {
//   import("./leads/index.js");
// });
// const Settings = React.lazy(() => {
// 	import("./setting");
// });

function AdminContentRouting(props) {
  return (
    <Switch>
      <Route
        exact
        path={["/admin"]}
        render={() => <Redirect to="/admin/dashboard" />}
      />
      <Route exact path={["/admin/users"]} component={Users} />
      <Route exact path={["/admin/bookings"]} component={Bookings} />
      <Route exact path={["/admin/tariff"]} component={Tarrif} />
      <Route exact path={["/admin/security/role"]} component={Roles} />
      <Route exact path={["/admin/plan"]} component={Plan} />
      <Route exact path={["/admin/dashboard"]} component={Dashboard} />
      <Route exact path={["/admin/coupons"]} component={Coupons} />

      <Route
        render={() => <h3 style={{ marginTop: "60px" }}>Coming soon.</h3>}
      />
    </Switch>
  );
}
export default withTranslation("translations")(AdminContentRouting);
